"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./buildingDialogFields"
import Dropzone from "react-dropzone"

import {Grid, Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocomplete from "../../../global/ui/xsInput/xsAutocomplete"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../../global/ui/xsButton/xsButton"

import BudovyBuildingStore from "./budovyBuildingStore"
import GlobalStore from "../../../global/store/GlobalStore"

import api from "../../../global/actions/api"

function BuildingDialog(props) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			BudovyBuildingStore.generateDataAndSave(form, close)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (BudovyBuildingStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			BudovyBuildingStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (attachmentIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			BudovyBuildingStore.dropedFiles[attachmentIndex],
			BudovyBuildingStore.dropedFiles[attachmentIndex].name
		)
		GlobalStore.setNotificationMessage("Súbor bol úspešne stiahnutý", "success")
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		if (isSafe(BudovyBuildingStore.dropedFiles[attachmentIndex]._id)) {
			BudovyBuildingStore.deleteLoadedAttachemnt(BudovyBuildingStore.dropedFiles[attachmentIndex]._id, attachmentIndex)
		} else {
			BudovyBuildingStore.dropedFiles.splice(attachmentIndex, 1)
		}
	}

	const close = () => {
		BudovyBuildingStore.closeBuildingDialog(form)
	}

	return (
		<Dialog open={BudovyBuildingStore.isOpenBuldingDialog} onEscapeKeyDown={() => close()} scroll="body" fullWidth>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="h6">{props.title}</Typography>
					</Grid>
					<i className="fal fa-times fa-lg dialog_close_icon pointer" onClick={() => close()}></i>
				</Grid>
			</DialogTitle>
			<DialogContent style={{paddingTop: "0px", paddingBottom: "10px"}}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsInput field={form.$("name")} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("relation")} items={GlobalStore.CL["EnergeticObject.SpecificRelType"]} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("category")} items={GlobalStore.CL["objectCategory"]} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("owner")} items={GlobalStore.CL["objectOwnership"]} />
					</Grid>
					<Grid item xs={6}>
						<XsAutocomplete
							field={form.$("city")}
							maxWidthMenu="500"
							minLengthForSearch="3"
							api={api.getExtendCLCity}
							inputRenderer={(obj) => `${obj.name_ext} - ${obj.county_name}`}
							saveValue={(obj) => obj.code}
							postAction={(obj) => {
								const codeLength = obj.code.length

								const cityCode = obj.code.slice(codeLength - 6, codeLength)

								form.$("cadastral_city_code").value = cityCode
								BudovyBuildingStore.getCadastralArea(cityCode)
							}}
							onClear={() => {
								form.$("cadastral_domain").value = ""
								form.$("cadastral_city_code").value = ""
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("cadastral_domain")}
							disabled={isEmpty(form.$("city").value) || BudovyBuildingStore.cadastralCityData.length === 0}
							items={BudovyBuildingStore.cadastralCityData}
							onChange={(val) => {
								const area = BudovyBuildingStore.cadastralCityData.find((x) => x._id === val)

								if (area) {
									form.$("cadastral_domain_code").value = area.code
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("registration_number")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("parcel_number")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("street")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("house_number")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("system_id")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("tag")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={form.$("note")} />
					</Grid>

					<Grid item xs={12}>
						<Dropzone
							onDrop={(files) => {
								handleDrop(files, 5)
							}}
							id="dropzone"
							// disabled={isFormDisabled()}
							accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
						>
							{isSafe(BudovyBuildingStore.dropedFiles) && BudovyBuildingStore.dropedFiles.length ? (
								BudovyBuildingStore.dropedFiles.map((file, index) => (
									<div key={index} className={"dropedFile"}>
										<i
											className="fal fa-file icon"
											onClick={(e) => {
												e.stopPropagation()
												handleDropzonePreview(index)
											}}
										/>
										<i
											className="far fa-trash-alt deleteIcon"
											onClick={(e) => {
												e.stopPropagation()
												// if (!isFormDisabled()) {
												GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
													fileIndex: index
												})
												// }
											}}
										/>
										<br />
										<div
											className={"mac_ellipsis"}
											data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
											data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
										/>
									</div>
								))
							) : (
								<div
									className={"dropzonePlaceholder"}
								>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
							)}
						</Dropzone>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2">
							Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
						</Typography>
					</Grid>
					<Grid item xs={12} container justify="flex-end" spacing={1}>
						<Grid item>
							<XsButton
								// className={cancelBtnClassName}
								variant="text"
								icon={<i className="fal fa-times fa-lg"></i>}
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => close()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								// className={cancelBtnClassName}
								variant="text"
								icon={<i className="fal fa-save fa-lg"></i>}
								text={<FormattedMessage id="Common.label.save" />}
								onClick={form.onSubmit}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
		</Dialog>
	)
}

export default injectIntl(observer(BuildingDialog))
