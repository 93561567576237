import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"
import {getFilters} from "../../../../../global/helpers/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeEmployeeContractsStore {
	@observable employeeContractsData = []
	@observable companyOrgunits = []

	@observable isOpenEmployeeContractDialog = false

	employeeContractFormRef = null

	@action loadContracts(person_id) {
		const req = getFilters([`employee_id=${person_id}`])

		api
			.loadContracts(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.employeeContractsData = res.rows
				} else {
					this.employeeContractsData = []
				}
			})
	}

	@action loadCompanyOrgunits(company_id) {
		const req = getFilters([`company_id=${company_id}`])

		GlobalStore.loading(true)

		api
			.loadOrgUnits(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.companyOrgunits = res.rows
				} else {
					this.companyOrgunits = []
				}

				GlobalStore.loading(false)
			})
	}

	@action openEmployeeContractDialog(person_id, row) {
		if (isSafe(row) && isSafe(this.employeeContractFormRef)) {
			this.employeeContractFormRef.$("_id").value = row.employment_contract_id
			this.employeeContractFormRef.$("orgunit_id").value = row.org_unit_id
			this.employeeContractFormRef.$("contract_percentage").value = row.capacity
			this.employeeContractFormRef.$("contract_from").value = row.validity.from
			this.employeeContractFormRef.$("contract_to").value = row.validity.to
		}

		this.employeeContractFormRef.$("person_id").value = person_id

		this.isOpenEmployeeContractDialog = true
	}

	@action closeEmployeeContractDialog() {
		this.isOpenEmployeeContractDialog = false

		this.employeeContractFormRef.reset()
	}

	@action saveEmployeeContract(form) {
		const values = form.values()

		const req = {
			_id: isNotEmpty(values._id) ? values._id : "",
			_type: "Entity.Data.EmploymentContract",
			org_unit: {
				_type: "Entity.Data.OrgUnit",
				_id: values.orgunit_id
			},
			capacity: +values.contract_percentage / 100,
			validity: {
				_ref: false,
				from: values.contract_from,
				to: values.contract_to
			},
			employee: {
				_type: "Entity.Data.Employee",
				_id: values.person_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveContract(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Kontrakt úspešne uložený", "success")
					this.loadContracts(values.person_id)
					this.closeEmployeeContractDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní kontraktu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeEmployeeContract(contract_id, person_id) {
		GlobalStore.loading(true)

		api
			.removeContract(contract_id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Úväzok úspešne odstránený", "success")
				this.loadContracts(person_id)
				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeEmployeeContractsStore()
export default singleton
