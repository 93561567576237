"use strict"

import React, {useEffect} from "react"
import {inject, observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container, Grid} from "@material-ui/core"

import RmeeDrawer from "../rmeeDrawer"
import RmeeCompanyTabs from "./rmeeCompanyTabs"
import RmeeCompanyForm from "./rmeeCompanyForm"

import GlobalStore from "../../../global/store/GlobalStore"

function RmeeCompanyDetail(props) {
	useEffect(() => {
		GlobalStore.main_menu_selected = "rmee"
	}, [])

	const company_id = new URLSearchParams(props.routing.location.search).get("id")

	return (
		<Grid container className="max_height">
			<Grid item>
				<RmeeDrawer />
			</Grid>
			<Grid item xs className="max_height">
				<Container maxWidth={false} className="max_height overflow_y">
					<Grid container direction="column">
						<RmeeCompanyForm company_id={company_id} />
						<RmeeCompanyTabs company_id={company_id} />
					</Grid>
				</Container>
			</Grid>
		</Grid>
	)
}

export default inject("routing")(injectIntl(observer(RmeeCompanyDetail)))
