"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import classnames from "classnames"
import {useTable, usePagination, useFlexLayout} from "react-table"

import {injectIntl} from "react-intl"

import {Grid, Box, Table, TableBody, TableHead, TableRow, TableCell, MenuItem, TextField} from "@material-ui/core"

import XsIconButton from "../xsButton/xsIconButton"
import XsLoading from "../xsLoading/xsLoading"

function RTable({columns, data, fetchData, pageCount: controlledPageCount, rowCount, onRowClick, loading}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// Get the state from the instance
		state: {pageIndex, pageSize}
	} = useTable(
		{
			columns,
			data,
			initialState: {pageIndex: 0}, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount
		},
		usePagination,
		useFlexLayout
	)

	// Listen for changes in pagination and use the state to fetch our new data
	React.useEffect(() => {
		fetchData({pageIndex, pageSize})
	}, [fetchData, pageIndex, pageSize])

	const isRowClick = isSafe(onRowClick) ? true : false

	// Render the UI for your table
	return (
		<React.Fragment>
			<Table {...getTableProps()} className="r-table" size="small">
				<TableHead>
					{headerGroups.map((headerGroup, i) => (
						<TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, idx) => (
								<TableCell key={`${i}_${idx}`} {...column.getHeaderProps()}>
									{column.render("Header")}
									<span>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<i className="fal fa-long-arrow-down"></i>
											) : (
												<i className="fal fa-long-arrow-up"></i>
											)
										) : (
											""
										)}
									</span>
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{loading ? (
						<TableRow>
							<TableCell colSpan="10000">
								<XsLoading />
							</TableCell>
						</TableRow>
					) : (
						page.map((row, i) => {
							prepareRow(row)
							return (
								<TableRow {...row.getRowProps()} key={i}>
									{row.cells.map((cell, idx) => {
										const cellClick = isRowClick && isNotSafe(cell.column.clickable)
										return (
											<TableCell
												key={`${i}_${idx}`}
												{...cell.getCellProps()}
												onClick={() => (cellClick ? onRowClick(row.original) : null)}
												className={classnames("td", {pointer: cellClick})}
											>
												{cell.render("Cell")}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})
					)}
					{/* <TableRow>
						{loading ? (
							// Use our custom loading state to show a loading indicator
							<TableCell colSpan="10000">Loading...</TableCell>
						) : (
							<TableCell colSpan="10000">
								Showing {page.length} of ~{controlledPageCount * pageSize} results
							</TableCell>
						)}
					</TableRow> */}
				</TableBody>
			</Table>
			{/* 
		  Pagination can be built however you'd like. 
		  This is just a very basic UI implementation:
		*/}
			<Box pt={1}>
				<Grid container spacing={2} alignItems="center" justify="flex-end">
					{/* <Grid item>Počet záznamov na stranu:</Grid> */}
					<Grid item>Rows per page:</Grid>
					<Grid item>
						<TextField
							size="small"
							variant="outlined"
							select
							value={pageSize}
							onChange={(e) => setPageSize(Number(e.target.value))}
						>
							<MenuItem key={5} value={5}>
								5
							</MenuItem>
							<MenuItem key={10} value={10}>
								10
							</MenuItem>
							<MenuItem key={20} value={20}>
								20
							</MenuItem>
							<MenuItem key={50} value={50}>
								50
							</MenuItem>
						</TextField>
					</Grid>
					<Grid item>
						<strong>
							{pageIndex + 1} - {pageOptions.length} of {rowCount}
						</strong>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-double-left" />}
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-left" />}
							onClick={() => previousPage()}
							disabled={!canPreviousPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-right" />}
							onClick={() => nextPage()}
							disabled={!canNextPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-double-right" />}
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}
							color="default"
						/>
					</Grid>
				</Grid>
			</Box>
		</React.Fragment>
	)
}

function XsServerTable({columns, onRowClick, api}) {
	const cols = React.useMemo(() => columns, [])

	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [rowCount, setRowCount] = useState(0)
	const fetchIdRef = React.useRef(0)

	const fetchData = React.useCallback(({pageSize, pageIndex}) => {
		delayedCallback(500, () => {
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current

			// Set the loading state
			setLoading(true)

			if (fetchId === fetchIdRef.current) {
				const startRow = pageSize * pageIndex

				const req = {
					row_count_show: pageSize,
					row_offset: startRow + 1
				}

				api(req)
					.call()
					.then((res) => {
						setData(res.rows)
						setPageCount(Math.ceil(res.row_count_full / pageSize))
						setRowCount(res.row_count_full)
						setLoading(false)
					})
			}
		})
	}, [])

	return (
		<RTable
			columns={cols}
			data={data}
			fetchData={fetchData}
			loading={loading}
			pageCount={pageCount}
			rowCount={rowCount}
			onRowClick={isSafe(onRowClick) ? (row) => onRowClick(row) : null}
		/>
	)
}

export default injectIntl(observer(XsServerTable))
