import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				name: {
					label: <FormattedMessage id="Common.label.name" />
				},
				ico: {
					label: <FormattedMessage id="Common.label.ico" />
				},
				subject_type: {
					label: <FormattedMessage id="Common.label.typeOfSubject" />
				},
				region: {
					label: <FormattedMessage id="Common.label.region" />
				},
				county: {
					label: <FormattedMessage id="Common.label.county" />
				},
				city: {
					label: <FormattedMessage id="Common.label.city" />
				},
				street: {
					label: <FormattedMessage id="Common.label.street" />
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />
				},
				psc: {
					label: <FormattedMessage id="Common.label.psc" />
				},
				note: {
					label: <FormattedMessage id="Common.label.note" />
				},
				firstname: {
					label: <FormattedMessage id="Common.label.firstname" />
				},
				lastname: {
					label: <FormattedMessage id="Common.label.surname" />
				},
				phone: {
					label: <FormattedMessage id="Common.label.phone" />
				},
				mobile: {
					label: <FormattedMessage id="Common.label.mobile" />
				},
				email: {
					label: <FormattedMessage id="Common.label.email" />
				}
			}
		}
	}
}
