"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box} from "@material-ui/core"

function ActivityContainer() {
	return <Box p={3}>Activity</Box>
}

export default injectIntl(observer(ActivityContainer))
