"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import classnames from "classnames"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import RmeeOrgunitEmployeesStore from "./rmeeOrgunitContactsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeOrgunitContactsTable(props) {
	const columns = [
		{
			Header: <FormattedMessage id="Common.label.type" />,
			width: 100,
			accessor: "contact_type.description",
			sortType: "basic",
			Cell: (obj) => {
				const row = obj.row.original

				return <span className={classnames({bold: row.primary})}>{obj.value}</span>
			}
		},
		{
			Header: <FormattedMessage id="Common.label.mobile" />,
			width: 100,
			accessor: "mobile",
			sortType: "basic"
		},
		{
			Header: <FormattedMessage id="Common.label.email" />,
			width: 100,
			accessor: "email",
			sortType: "basic"
		},
		{
			Header: <FormattedMessage id="Common.label.phone" />,
			width: 100,
			accessor: "phone",
			sortType: "basic"
		},
		{
			Header: <FormattedMessage id="Common.label.web" />,
			width: 100,
			accessor: "web_page",
			sortType: "basic"
		},
		{
			Header: <FormattedMessage id="Common.label.description" />,
			width: 100,
			accessor: "description",
			sortType: "basic"
		},
		{
			clickable: false,
			width: 10,
			accessor: "_id",
			Cell: (obj) => {
				return (
					<Grid container justify="flex-end">
						<Grid item>
							<i
								className="fal fa-trash-alt fa-lg pointer"
								onClick={() => GlobalStore.openConfirmationDialog("removeOrgunitContact", {contact_id: obj.value})}
							></i>
						</Grid>
					</Grid>
				)
			}
		}
	]

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={RmeeOrgunitEmployeesStore.orgunitContactsData}
				onRowClick={(row) => RmeeOrgunitEmployeesStore.openOrgunitContactDialog(props.orgunit_id, row)}
			/>
			<XsConfirmationDialog
				name="removeOrgunitContact"
				text="Naozaj chcete odstrániť kontakt?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					RmeeOrgunitEmployeesStore.removeOrgunitContact(GlobalStore.confParams.contact_id, props.orgunit_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RmeeOrgunitContactsTable))
