"use strict"

import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import {Grid} from "@material-ui/core"

import RmeeEmployeeContractsStore from "./rmeeEmployeeContractsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeEmployeeContactsTable(props) {
	const columns = [
		{
			Header: <FormattedMessage id="Common.label.department" />,
			width: 100,
			accessor: "org_unit_name_ext",
			sortType: "basic"
		},
		{
			Header: <FormattedMessage id="Common.label.contract" />,
			width: 100,
			accessor: "capacity",
			sortType: "basic",
			Cell: (obj) => {
				return <span>{obj.value + " %"}</span>
			}
		},
		{
			Header: <FormattedMessage id="Common.label.validityFrom" />,
			width: 100,
			accessor: "validity.from",
			sortType: "basic",
			Cell: (obj) => {
				return <span>{isSafe(obj.value) ? moment(obj.value).format("DD.MM.YYYY") : ""}</span>
			}
		},
		{
			Header: <FormattedMessage id="Common.label.validityTo" />,
			width: 100,
			accessor: "validity.to",
			sortType: "basic",
			Cell: (obj) => {
				return <span>{isSafe(obj.value) ? moment(obj.value).format("DD.MM.YYYY") : ""}</span>
			}
		},
		{
			clickable: false,
			width: 10,
			accessor: "employment_contract_id",
			Cell: (obj) => {
				return (
					<Grid container justify="flex-end">
						<Grid item>
							<i
								className="fal fa-trash-alt fa-lg pointer"
								onClick={() => GlobalStore.openConfirmationDialog("removeEmployeeContract", {contract_id: obj.value})}
							></i>
						</Grid>
					</Grid>
				)
			}
		}
	]

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={RmeeEmployeeContractsStore.employeeContractsData}
				onRowClick={(row) => RmeeEmployeeContractsStore.openEmployeeContractDialog(props.person_id, row)}
			/>
			<XsConfirmationDialog
				name="removeEmployeeContract"
				text="Naozaj chcete odstrániť úväzok?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					RmeeEmployeeContractsStore.removeEmployeeContract(GlobalStore.confParams.contract_id, props.person_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RmeeEmployeeContactsTable))
