import {createMuiTheme} from "@material-ui/core/styles"

/* eslint-disable */
// const blueInfo = "#39B0DD"
const blueLight = "#1e4e9d"
// const blueDark = "#1e4e9d"
const greenSuccess = "#00A787"
// const greenLight = "#F1F8E9"
// const greenDark = "#33691E"
// const amberWarning = "#FFB900"
// const amberLight = "#FFF8E1"
// const amberDark = "#FF8F00"
const redDanger = "#C6184E"
// const redLight = "#FCE4EC"
// const redDark = "#c10b25"
// const greyLight = "#E5E7EA"
// const greyDefault = "#78849E"
// const greyDark = "#60606F"
// const greyOutline = "#E0E5EF"
const white = "#f0f0f0"
const snowWhite = "#FFFFFF"
// const black = "#22232C"
/* eslint-enable */

export const muiTheme = createMuiTheme({
	palette: {
		primary: {
			main: blueLight
		},
		secondary: {
			main: redDanger
		},
		success: {
			main: greenSuccess
		}
	},
	overrides: {
		MuiDialog: {
			paper: {
				borderRadius: "5px",
				overflowY: "initial"
			}
		},
		MuiDialogTitle: {
			root: {
				borderTopLeftRadius: "5px",
				borderTopRightRadius: "5px"
			}
		},
		MuiDialogContent: {
			root: {
				overflowY: "initial",
				padding: "24px"
			}
		},
		MuiDialogActions: {
			root: {
				padding: "8px 24px",
				borderBottomLeftRadius: "5px",
				borderBottomRightRadius: "5px"
			}
		},
		MuiTableCell: {
			head: {
				color: "rgba(0, 0, 0, 0.56)",
				fontWeight: 400
			}
		},
		MuiTabs: {
			root: {
				backgroundColor: white
			}
		},
		MuiTab: {
			root: {
				"&.xs-active": {
					backgroundColor: snowWhite
				}
			}
		}
	}
})

export default muiTheme
