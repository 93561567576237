import {observable, action} from "mobx"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"

import {getUserId} from "../../../../../global/helpers/actions"

import BudovyBuildingDetailStore from "../budovyBuildingDetailStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../../../global/actions/api"

class BuildingConstructionStore {
	@observable params = undefined

	@action getParamTypes(setForm, hooks) {
		const req = {
			type_id: "C",
			category_id: BudovyBuildingDetailStore.buildingDTO.category._id,
			user_id: getUserId()
		}

		api
			.getParamTypes(req)
			.call()
			.then((res) => {
				if (res && res.parameters) {
					let fields = {}
					let paramValues = BudovyBuildingDetailStore.buildingDTO.parameters

					res.parameters.forEach((param) => {
						const val = paramValues.find((x) => x.parameter_fact_id == param.parameter_fact_id)

						fields[param.parameter_fact_id] = {
							label: " ",
							rules: param.required && "required"
						}
						fields[`${param.parameter_fact_id}_desc`] = {
							label: " "
						}

						if (val) {
							fields[param.parameter_fact_id]["value"] = val.value
							fields[`${param.parameter_fact_id}_desc`]["value"] = val.description
						}
					})

					this.params = res.parameters
					setForm(new MobxReactForm({fields: fields}, {plugins: {dvr: dvr(validatorjs)}, hooks}))
				}
			})
	}

	@action setDefaultValue(form) {
		let paramValues = BudovyBuildingDetailStore.buildingDTO.parameters

		this.params.forEach((param) => {
			const val = paramValues.find((x) => x.parameter_fact_id == param.parameter_fact_id)

			form.$(param.parameter_fact_id).value = val ? val.value : ""
			form.$(`${param.parameter_fact_id}_desc`).value = val ? val.description : ""
		})
	}

	@action saveParameters(form, setEditMode) {
		const values = form.values()

		let req = {_id: BudovyBuildingDetailStore.buildingDTO._id, parameters: []}

		this.params.forEach((param) => {
			if (values[param.parameter_fact_id] || values[`${param.parameter_fact_id}_desc`]) {
				req.parameters.push({
					description: values[`${param.parameter_fact_id}_desc`],
					parameter_fact_id: param.parameter_fact_id,
					type_id: "C",
					value: values[param.parameter_fact_id]
				})
			} else {
				req.parameters.push({
					description: "",
					parameter_fact_id: param.parameter_fact_id,
					type_id: "C",
					value: ""
				})
			}
		})

		api
			.saveBuilding(req)
			.call()
			.then((res) => {
				if (res && res._id) {
					setEditMode(false)
					GlobalStore.setNotificationMessage("Záznamy úspešné upravené!", "success")
					BudovyBuildingDetailStore.getBuildingById(res._id)
				}
			})
	}
}

var singleton = new BuildingConstructionStore()
export default singleton
