import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeOrgunitBanksStore {
	@observable orgunitBanksData = []
	@observable banksData = []

	@observable isOpenOrgunitBankDialog = false

	orgunitBankFormRef = null

	@action loadBankAccounts(orgunit_id) {
		api
			.loadOrgunitBankAccounts(orgunit_id)
			.call()
			.then((res) => {
				if (isSafe(res) && res.length) {
					this.orgunitBanksData = res
				} else {
					this.orgunitBanksData = []
				}
			})
	}

	@action loadBanks() {
		api
			.loadBanks()
			.call()
			.then((res) => {
				if (isSafe(res) && res.length) {
					this.banksData = res
				} else {
					this.banksData = []
				}
			})
	}

	@action openOrgunitBankDialog(orgunit_id, row) {
		if (isSafe(row) && isSafe(this.orgunitBankFormRef)) {
			this.orgunitBankFormRef.$("_id").value = row._id
			this.orgunitBankFormRef.$("bank").value = isSafe(row.treasurer) ? row.treasurer._id : null
			this.orgunitBankFormRef.$("type").value = isSafe(row.type) ? row.type._id : null
			this.orgunitBankFormRef.$("prefix").value = isSafe(row.prefix) ? row.prefix : ""
			this.orgunitBankFormRef.$("number").value = isSafe(row.number) ? row.number : ""
		}

		this.orgunitBankFormRef.$("orgunit_id").value = orgunit_id

		this.isOpenOrgunitBankDialog = true
	}

	@action closeOrgunitBankDialog() {
		this.isOpenOrgunitBankDialog = false

		this.orgunitBankFormRef.reset()
	}

	@action saveOrgunitBankAccout(form) {
		const values = form.values()

		let req = {
			_id: isNotEmpty(form.$("_id").value) ? form.$("_id").value : null,
			active: true,
			_ref: false,
			_type: "Fin.Data.BankAccount",
			treasurer: values.bank,
			type: values.type,
			prefix: values.prefix,
			number: values.number,
			primary: values.primary,
			specific_rel: {
				_type: "Entity.Data.Client",
				_id: values.orgunit_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveOrgunitBankAccout(values.orgunit_id, req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Bankový účet úspešne uložený", "success")
					this.loadBankAccounts(values.orgunit_id)
					this.closeOrgunitBankDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní bankového účtu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeOrgunitBankaccount(account_id, orgunit_id) {
		const req = {
			active: false,
			_id: account_id,
			specific_rel: {_id: orgunit_id, _type: "Entity.Data.Client"},
			_ref: false
		}

		GlobalStore.loading(true)

		api
			.saveOrgunitBankAccout(orgunit_id, req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Bankový účet úspešne odstránený", "success")
					this.loadBankAccounts(orgunit_id)
				} else {
					GlobalStore.setNotificationMessage("Pri odstráňovaní bankového účtu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeOrgunitBanksStore()
export default singleton
