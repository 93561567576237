"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box} from "@material-ui/core"
import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import RmeeCompanyOrgunitDialog from "./rmeeCompanyOrgunitDialog"

import RmeeStore from "../../rmeeStore"
import RouterStore from "../../../../global/store/RouterStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.code" />,
		width: 100,
		accessor: "code_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.name" />,
		width: 200,
		accessor: "name_ext",
		sortType: "basic"
	}
]

function RmeeCompanyOrgunitsContainer(props) {
	useEffect(() => {
		RmeeStore.loadCompanyOrgunits(props.company_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item xs={12} container justify="flex-end">
						<Grid item>
							<XsButton
								text="Nová organizačná jednotka"
								onClick={() => RmeeStore.openCompanyOrgunitDialog()}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsTable
							columns={columns}
							data={RmeeStore.comapnyOrgunitsData}
							onRowClick={(row) =>
								RouterStore.push(`/companyOrgunit?companyId=${props.company_id}&id=${row.org_unit_id}`)
							}
						/>
					</Grid>
				</Grid>
			</Box>
			{RmeeStore.isOpenCompanyOrgunitDialog && <RmeeCompanyOrgunitDialog company_id={props.company_id} />}
		</Container>
	)
}

export default injectIntl(observer(RmeeCompanyOrgunitsContainer))
