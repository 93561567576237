import {observable, action} from "mobx"

import api from "../../global/actions/api"
import {getFilters} from "../../global/helpers/api"

import GlobalStore from "../../global/store/GlobalStore"

class RmeeStore {
	@observable rmeeTabId = "companies" //drawer tab
	@observable companyTabValue = "orgunits"

	@observable companiesData = []
	@observable comapnyOrgunitsData = []

	@observable isOpenCompanyDialog = false
	@observable isOpenCompanyOrgunitDialog = false
	@observable isOpenCompanyEmployeeDialog = false

	@action loadCompanies() {
		GlobalStore.loading(true)

		api
			.loadCompanies()
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.companiesData = res.rows
				} else {
					this.companiesData = []
				}

				GlobalStore.loading(false)
			})
	}

	@action loadCompany(company_id, form) {
		api
			.loadEntity(company_id)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("_id").value = res._id
					form.$("ico").value = res.identifier
					form.$("name").value = res.name
					form.$("short_name").value = res.short_name
					form.$("description").value = res.description
					form.$("dic").value = res.tax_identification_number
					form.$("icdph").value = res.vat_identification_number
					form.$("title").value = res.name
				}
			})
	}

	@action saveCompany(form, setDisabled) {
		const values = form.values()

		const req = {
			_id: isEmpty(values._id) ? "" : values._id,
			active: true,
			identifier: values.ico,
			name: values.name,
			short_name: values.short_name,
			tax_identification_number: values.dic,
			vat_identification_number: values.icdph,
			description: values.description,
			_ref: false,
			_type: "Entity.Data.Company"
		}

		GlobalStore.loading(true)

		api
			.saveEntity(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					if (isEmpty(req._id)) {
						this.closeCompanyDialog(form)
						this.loadCompanies()
					} else {
						setDisabled(true)
					}

					GlobalStore.setNotificationMessage("Spoločnosť úspešne uložená", "success")
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní spoločnosti došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action openCompanyDialog() {
		this.isOpenCompanyDialog = true
	}

	@action closeCompanyDialog(form) {
		this.isOpenCompanyDialog = false

		if (isSafe(form)) {
			form.reset()
		}
	}

	@action loadCompanyOrgunits(company_id) {
		const req = getFilters([`company_id=${company_id}`])

		GlobalStore.loading(true)

		api
			.loadOrgUnits(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.comapnyOrgunitsData = res.rows
				} else {
					this.comapnyOrgunitsData = []
				}

				GlobalStore.loading(false)
			})
	}

	@action openCompanyOrgunitDialog() {
		this.isOpenCompanyOrgunitDialog = true
	}

	@action closeCompanyOrgunitDialog(form) {
		this.isOpenCompanyOrgunitDialog = false

		if (isSafe(form)) {
			form.reset()
		}
	}

	@action saveOrgunit(form) {
		const values = form.values()

		const req = {
			_type: "Entity.Data.OrgUnit",
			_ref: false,
			active: true,
			_id: "",
			code: values.code,
			code_ext: values.code,
			name_ext: values.name,
			name: values.name,
			parent: isEmpty(values.parent_OJ) ? null : values.parent_OJ,
			entity: {
				_type: "Entity.Data.Company",
				_ref: false,
				_id: values.company_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveOrgunit(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					GlobalStore.setNotificationMessage("Organizačná jednotka úspešne uložená", "success")
					this.loadCompanyOrgunits(values.company_id)
					this.closeCompanyOrgunitDialog(form)
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní organizačnej jednotky došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeStore()
export default singleton
