import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"

class RmeeOrgunitEmployeesStore {
	@observable orgunitEmployeesData = []

	@action loadEmployees(orgunit_id) {
		api
			.loadOrgunitEmployees(orgunit_id)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.orgunitEmployeesData = res.rows
				} else {
					this.orgunitEmployeesData = []
				}
			})
	}
}

var singleton = new RmeeOrgunitEmployeesStore()
export default singleton
