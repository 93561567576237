"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {injectIntl, FormattedMessage} from "react-intl"

import {login} from "../../global/helpers/actions"
import {Grid, Paper, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

// import {isPasswordValid} from "../../global/helpers/functions"

import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"

function Login() {
	const hooks = {
		onSubmit() {
			// const {log_in, password} = form.values()
			// if (isEmpty(log_in) || isEmpty(password)) {
			// 	GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
			// 	form.invalidate()
			// } else {
			// 	if (!isPasswordValid(password)) {
			// 		GlobalStore.setNotificationMessage(
			// 			"The password must contain at least one uppercase letter, one lowercase letter and one number. Minimum length of password is 8 characters.",
			// 			"warning"
			// 		)
			// 		form.$("password").invalidate()
			// 	}
			// }
		},
		onSuccess(form) {
			const {log_in, password} = form.values()
			login(log_in, password)
		},
		onError() {
			// GlobalStore.setNotificationMessage("Heslo je povinné pole!", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					log_in: {label: <FormattedMessage id="Common.label.login" />, rules: "string"},
					password: {label: <FormattedMessage id="Common.label.psw" />, type: "password"}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	useEffect(() => {
		GlobalStore.main_menu_selected = ""
	}, [])

	return (
		<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
			<Box p={2}>
				<Grid item container direction="column" spacing={2}>
					<Grid item>
						<Typography variant="body2">
							If you are already registered, log in using your email address and password you entered during
							registration.
						</Typography>
					</Grid>
					<Grid item>
						<XsInput field={form.$("log_in")} autoFocus={true} submitAction={(e) => form.onSubmit(e)} />
					</Grid>
					<Grid item>
						<XsInput
							// regex={(val) => isPasswordValid(val)}
							type="password"
							field={form.$("password")}
							submitAction={(e) => form.onSubmit(e)}
						/>
					</Grid>
					<Grid item>
						<XsButton variant="contained" text={<FormattedMessage id="Common.btn.signIn" />} onClick={form.onSubmit} />
					</Grid>
					<Grid item>
						<Link variant="body2" className="pointer" onClick={() => (RouterStore.push("/reset"))}>
							{/* <FormattedMessage id="Common.label.forgotPsw" /> */}I forgot my password
						</Link>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							If you are not registered yet,{" "}
							<Link variant="body2" className="pointer bold" onClick={() => RouterStore.push("/register")}>
								you can register HERE!
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default injectIntl(observer(Login))
