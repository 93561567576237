import {observable, action} from "mobx"

import api from "../../../../global/actions/api"
import {getFilters} from "../../../../global/helpers/api"

import GlobalStore from "../../../../global/store/GlobalStore"

class RmeeCompanyOrgunitsContainer {
	@observable companyOrgunitsData = []
	@observable tabValue = "employees"

	@action loadOrgunit(orgunit_id, company_id, form) {
		api
			.loadOrgunit(orgunit_id)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("_id").value = res._id
					form.$("code").value = res.code_ext
					form.$("name").value = res.name_ext
					form.$("title").value = res.name_ext
					form.$("company_name").value = res.entity_name
					form.$("parent_OJ").value = isSafe(res.parent) && isSafe(res.parent._id) ? res.parent._id : null
				}
			})

		form.$("company_id").value = company_id
	}

	@action loadCompanyOrgunits(companyId) {
		const req = getFilters([`company_id=${companyId}`])

		GlobalStore.loading(true)

		api
			.loadOrgUnits(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.companyOrgunitsData = res.rows
				} else {
					this.companyOrgunitsData = []
				}

				GlobalStore.loading(false)
			})
	}

	@action saveOrgunit(form, setDisabled) {
		const values = form.values()

		const req = {
			_type: "Entity.Data.OrgUnit",
			_ref: false,
			active: true,
			_id: isNotEmpty(values._id) ? values._id : "",
			code: values.code,
			code_ext: values.code,
			name_ext: values.name,
			name: values.name,
			parent: isEmpty(values.parent_OJ) ? null : values.parent_OJ,
			entity: {
				_type: "Entity.Data.Company",
				_ref: false,
				_id: values.company_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveOrgunit(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					setDisabled(true)
					GlobalStore.setNotificationMessage("Organizačná jednotka úspešne uložená", "success")
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní organizačnej jednotky došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeCompanyOrgunitsContainer()
export default singleton
