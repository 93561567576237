"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Box, Grid} from "@material-ui/core"

import XsButton from "../../../global/ui/xsButton/xsButton"

import ListOfReports from "./listOfReports"
import ReportDialog from "./reportDialog"

import CvsReportStore from "./cvsReportStore"

function ReportsContainer() {
	return (
		<Box p={3}>
			<Grid container alignItems="center">
				<Grid item>
					<FormattedMessage id="Common.label.reportList" />
				</Grid>
				<Grid item container xs spacing={2} justify="flex-end">
					<Grid item>
						<XsButton
							icon={<i className="fal fa-file-excel fa-lg"></i>}
							variant="outlined"
							text={<FormattedMessage id="Common.label.exportToXLS" />}
							onClick={() => {
								CvsReportStore.exportReports()
							}}
						/>
					</Grid>
					<Grid item>
						<XsButton
							icon={<i className="fal fa-plus fa-lg"></i>}
							// variant="text"
							text={<FormattedMessage id="Common.label.addReport" />}
							onClick={() => {
								CvsReportStore.openReportDialog()
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<ListOfReports />
			<ReportDialog title={<FormattedMessage id="Common.label.addReportWithEnergonosic" />} />
		</Box>
	)
}

export default injectIntl(observer(ReportsContainer))
