import {observable, action} from "mobx"

import {getFilters} from "../../../global/helpers/api"
import {getCompanyId} from "../../../global/helpers/actions"

import api from "../../../global/actions/api"

import GlobalStore from "../../../global/store/GlobalStore"

class BudovyBuildingStore {
	@observable budovyTabValue = ""

	@observable isOpenBuldingDialog = false

	@observable buildingsData = []
	@observable cadastralCityData = []

	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@action openBuildingDialog() {
		this.isOpenBuldingDialog = true
	}

	@action closeBuildingDialog() {
		this.isOpenBuldingDialog = false

		this.dropedFiles = []
	}

	@action getBuildings() {
		const req = getFilters([`company_id=${getCompanyId()}`])

		api
			.getBuildings(req)
			.call()
			.then((res) => {
				if (res && res.rows) {
					this.buildingsData = res.rows
				} else {
					this.buildingsData = []
				}
			})
	}

	@action getCadastralArea(code) {
		api
			.getCadastralCity(code)
			.call()
			.then((res) => {
				if (res && res.value) {
					this.cadastralCityData = res.value.map((city) => ({
						code: city.Code,
						code_ext: city.Code,
						name_ext: city.Name,
						city_id: city.Id,
						_id: city.Code
					}))
				} else {
					this.cadastralCityData = []
				}
			})
	}

	generateDataAndSave(form, fn) {
		if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
			let req = []
			let files = this.dropedFiles
			var saveFunction = this.saveBuilding.bind(this)
			let binaryDataArr = []

			let getdata = function(index) {
				let data = files[index]
				if (isSafe(data) && isSafe(data._id)) {
					req.push({
						_id: data._id
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, fn)
					}
				} else {
					let binaryData
					var reader = new FileReader()
					reader.readAsBinaryString(data)
					reader.onloadend = function() {
						binaryData = reader.result
						binaryDataArr.push(binaryData)
						req.push({
							content: btoa(binaryData),
							file_name: data.name
						})
						if (files.length > index + 1) {
							getdata(index + 1)
						} else {
							saveFunction(form, req, fn)
						}
					}
				}
			}
			getdata(0)
		} else {
			this.saveBuilding(form, [], fn)
		}
	}

	@action saveBuilding(form, dropzoneData, fn) {
		const values = form.values()

		const req = {
			_id: values._id ? values._id : null,
			name: values.name,
			object_type_id: "ISEE.Data.Building",
			company_relation_type_id: values.relation,
			company_id: getCompanyId(),
			category: values.category,
			ownership_type: values.owner,
			note: values.note,
			external_identifier: values.system_id,
			cadastral_city_code: values.cadastral_city_code,
			cadastral_domain_code: values.cadastral_domain_code,
			attachments: dropzoneData,
			cadastral_domain: null, // values.cadastral_domain,
			addresses: [
				{
					type_id: "L",
					city_id: values.city,
					street_plain: values.street,
					house_number: values.house_number,
					registration_number: values.registration_number,
					lot_number: values.parcel_number
				}
			],
			tags: [
				{
					name: values.tag
				}
			]
		}

		api
			.saveBuilding(req)
			.call()
			.then((res) => {
				if (res && res._id) {
					this.getBuildings()
					fn()
					GlobalStore.setNotificationMessage("Objekt úspešne uložený", "success")
				}
			})
	}

	@action removeBuilding(objectId) {
		api
			.deleteBuilding(objectId)
			.call()
			.then(() => {
				this.getBuildings()
				GlobalStore.setNotificationMessage("Objekt úspešne odstránený", "success")
			})
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachments(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}
}

var singleton = new BudovyBuildingStore()
export default singleton
