"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeEmployeeApplicationUserFields"

import {injectIntl, FormattedMessage} from "react-intl"
import {Grid, Box} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"

import RmeeEmployeeApplicationUserStore from "./rmeeEmployeeApplicationUserStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeEmployeeApplicationUserForm(props) {
	const hooks = {
		onSubmit() {},
		onSuccess() {
			RmeeEmployeeApplicationUserStore.saveApplicationUser(form, setDisabled)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		RmeeEmployeeApplicationUserStore.employeeAppUserFormRef = form

		RmeeEmployeeApplicationUserStore.loadEntityUser(props.person_id)

		return () => {
			form.reset()
		}
	}, [])

	return (
		<Grid container direction="column">
			<Box mt={4}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={2}>
						<XsInput field={form.$("login")} disabled={disabled} />
					</Grid>
					<Grid item xs={2}>
						<XsInput field={form.$("password")} disabled={disabled} />
					</Grid>
					<Grid item xs={2}>
						<XsInput field={form.$("repeat_password")} disabled={disabled} />
					</Grid>
					<Grid item xs={2}>
						<XsInput field={form.$("email")} disabled={disabled} />
					</Grid>
					<Grid item container xs={2}>
						<Grid item xs={6}>
							<XsCheckbox field={form.$("enabled")} disabled={disabled} />
						</Grid>
						<Grid item xs={6}>
							<XsCheckbox field={form.$("confirmed")} disabled={disabled} />
						</Grid>
					</Grid>
					<Grid container xs={2} spacing={1} justify="flex-end">
						{disabled ? (
							<Grid item>
								<XsButton
									icon={<i className="fal fa-pencil-alt"></i>}
									text={<FormattedMessage id="Common.btn.edit" />}
									onClick={() => setDisabled(false)}
								/>
							</Grid>
						) : (
							<React.Fragment>
								<Grid item>
									<XsButton
										// className={cancelBtnClassName}
										icon={<i className="fal fa-times"></i>}
										text={<FormattedMessage id="Common.label.cancel" />}
										onClick={() => {
											setDisabled(true)
											RmeeEmployeeApplicationUserStore.loadEntityUser(props.person_id)
										}}
									/>
								</Grid>
								<Grid item>
									<XsButton
										// className={cancelBtnClassName}
										icon={<i className="fal fa-save"></i>}
										text={<FormattedMessage id="Common.btn.save" />}
										onClick={form.onSubmit}
									/>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
}

export default injectIntl(observer(RmeeEmployeeApplicationUserForm))
