"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container, Grid} from "@material-ui/core"

import XsButton from "../../../global/ui/xsButton/xsButton"

import EtlStore from "../etlStore"

function EtlStateContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y pt-2">
			<Grid container spacing={1}>
				<Grid item>
					<XsButton text="Spustiť import dát" onClick={() => EtlStore.lunchImportData()} />
				</Grid>
				<Grid item>
					<XsButton text="Overiť stav" onClick={() => EtlStore.checkImportData()} />
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(EtlStateContainer))
