import {observable, action} from "mobx"

import BudovyBuildingStore from "../../budovyBuildingStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

// import api from "../../../../global/actions/api"

class BuildingIdentificationStore {
	@observable objName = ""

	formRef = null

	@action initForm(build) {
		if (build && this.formRef) {
			this.formRef.$("_id").value = build._id
			this.formRef.$("name").value = build.name
			this.formRef.$("relation").value = build.company_relation_type_id
			this.formRef.$("category").value = build.category ? build.category._id : undefined
			this.formRef.$("owner").value = build.ownership_type ? build.ownership_type._id : undefined
			this.formRef.$("cadastral_domain").value = build.cadastral_domain ? build.cadastral_domain._id : undefined
			this.formRef.$("cadastral_domain_code").value = +build.cadastral_domain_code
			this.formRef.$("cadastral_city_code").value = build.cadastral_city_code
			this.formRef.$("system_id").value = build.external_identifier
			this.formRef.$("note").value = build.note

			if (build.tags && build.tags.length) {
				this.formRef.$("tag").value = build.tags.map((x) => x.name).join(", ")
			}

			if (build.addresses && build.addresses.length) {
				const address = build.addresses[0]

				this.formRef.$("city").value = address.city_id
				this.formRef.$("house_number").value = address.house_number
				this.formRef.$("street").value = address.street_plain
				this.formRef.$("registration_number").value = address.registration_number
				this.formRef.$("parcel_number").value = address.lot_number
			}

			if (build.attachments && build.attachments.length) {
				let tmp = []

				build.attachments.forEach((attach) => {
					tmp.push({name: attach.file_name, _id: attach._id})
				})

				BudovyBuildingStore.dropedFiles = tmp
			}

			let name = `${build._id}`

			if (build.category && build.category._id) {
				const cat = GlobalStore.CL["objectCategory"].find((x) => x._id === build.category._id)

				if (cat) {
					name += `, ${cat.name_ext}`
				}
			}

			name += `, ${build.name}`

			this.objName = name
		}
	}
}

var singleton = new BuildingIdentificationStore()
export default singleton
