import {observable, action} from "mobx"

import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class EtlStore {
	@observable etlTabId = "state"

	@action lunchImportData() {
		api
			.lunchImportData()
			.call()
			.then((res) => {
				if (isSafe(res) && res.ok) {
					GlobalStore.setNotificationMessage("Spustenie importu dát prebehlo úspešne", "success")
				} else {
					GlobalStore.setNotificationMessage("Pri spustení importu dát nastala chyba!", "error")
				}
			})
	}

	@action checkImportData() {
		api
			.checkImportData()
			.call()
			.then((res) => {
				if (isSafe(res)) {
					if (res.status == "RESOLVED") {
						GlobalStore.setNotificationMessage("Import dát prebehol úspešne", "success")
					} else if (res.status == "INHANDLING") {
						GlobalStore.setNotificationMessage("Import dát prebieha", "warning")
					} else {
						GlobalStore.setNotificationMessage("Pri importe dát došlo ku chybe", "error")
					}
				} else {
					GlobalStore.setNotificationMessage("Pri importe dát došlo ku chybe", "error")
				}
			})
	}
}

var singleton = new EtlStore()
export default singleton
