"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeOrgunitAddressFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"

import RmeeOrgunitAddressesStore from "./rmeeOrgunitAddressesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../../../global/actions/api"

function RmeeOrgunitAddressDialog() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RmeeOrgunitAddressesStore.saveOrgunitAddress(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		RmeeOrgunitAddressesStore.orgunitAddressFormRef = form
	}, [])

	return (
		<Dialog
			open={RmeeOrgunitAddressesStore.isOpenOrgunitAddressDialog}
			onEscapeKeyDown={() => RmeeOrgunitAddressesStore.closeOrgunitAddressDialog()}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<i className="fal fa-address-card fa-2x"></i>
					</Grid>
					<Grid item>
						<Typography variant="h6">Pridať adresu</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => RmeeOrgunitAddressesStore.closeOrgunitAddressDialog()}
					></i>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("type")} items={GlobalStore.CL["addressType"]} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("region")} items={GlobalStore.CL["addressRegion"]} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("county")} items={GlobalStore.CL["addressCounty"]} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("street")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("house_number")} />
					</Grid>
					<Grid item xs={6}>
						<XsAutocomplete
							field={form.$("zip")}
							minLengthForSearch="3"
							api={api.loadZip}
							inputRenderer={(obj) => obj.name_ext}
							saveValue={(obj) => obj.code}
							freeText={true}
							freeTextField={form.$("zip_text")}
							postAction={(obj) => {
								form.$("zip_text").value = obj.name_ext
							}}
							onClear={() => {
								form.$("zip_text").value = ""
							}}
							// refreshComponent={this.state.refreshComponent}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsAutocomplete
							field={form.$("city")}
							minLengthForSearch="3"
							api={api.loadCity}
							inputRenderer={(obj) => obj.name_ext}
							saveValue={(obj) => obj.code}
							freeText={true}
							freeTextField={form.$("city_text")}
							postAction={(obj) => {
								form.$("city_text").value = obj.name_ext
							}}
							onClear={() => {
								form.$("city_text").value = ""
							}}
							// refreshComponent={this.state.refreshComponent}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("description")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={1}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => RmeeOrgunitAddressesStore.closeOrgunitAddressDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(RmeeOrgunitAddressDialog))
