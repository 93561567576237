"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Box, Grid} from "@material-ui/core"

import IdentificationForm from "./identificationForm"

import BudovyBuildingDetailStore from "../budovyBuildingDetailStore"

function IdentificationContainer({building_id}) {
	useEffect(() => {
		BudovyBuildingDetailStore.getBuildingById(building_id)
	}, [])

	return (
		<Box pr={4} pl={4} pt={3} pb={3}>
			<Grid container className="mb-4">
				<Grid item className="title">
					<Box pb={2}>Základné údaje</Box>
				</Grid>
			</Grid>
			<IdentificationForm />
		</Box>
	)
}

export default injectIntl(observer(IdentificationContainer))
