import moment from "moment"

export default {
  load() {
    return {
      fields: {
        xsInput: {
          label: "Input",
          value: "Input"
        },
        xsInputDisabled: {
          label: "Input disabled",
          value: "Input disabled"
        },
        xsInputReq: {
          label: "Input required",
          rules: "required"
        },
        xsInputEnd: {
          label: "Input",
        },
        xsInputEndReq: {
          label: "Input required",
          rules: "required"
        },
        xsInputEndDis: {
          label: "Input disabled",
          value: "Input disabled"
        },
        xsInputNumber: {
          label: "Number",
          value: 243.43
        },
        xsInputNumberDisabled: {
          label: "Number disabled",
          value: 123
        },
        xsInputNumberReq: {
          label: "Number required",
          rules: "required"
        },
        xsInputNumberEnd: {
          label: "Number",
          // value: 243.43
        },
        xsInputNumberEndDis: {
          label: "Number disabled",
          value: 243.43
        },
        xsInputNumberEndReq: {
          label: "Number required",
          rules: "required",
          // value: 243.43
        },
        searchSelect: {
          label: "Search select",
          value: "SK042"
        },
        searchSelectDisabled: {
          label: "Search select disabled",
          value: "SK042"
        },
        searchSelectReq: {
          label: "Search select required",
          rules: "required"
        },
        searchSelectMulti: {
          label: "Search select multi",
          options: {
            isMulti: true
          }
        },
        datePicker: {
          label: "Date",
          type: "date",
          value: moment()
        },
        datePickerDisabled: {
          label: "Date disabled",
          type: "date",
          value: moment()
        },
        datePickerReq: {
          label: "Date",
          rules: "required",
          type: "date"
        },
        xsAutocomplete: {
          label: "Autocomplete"
        },
        xsTextarea: {
          label: "Textarea"
        },
        xsTextareaDis: {
          label: "Textarea disabled",
          value: "Hello <b>world</b>!"
        },
        xsTextareaReq: {
          label: "Textarea required",
          rules: "required"
        },
        xsTextareaHide: {
          label: "Textarea hidden toolbar"
        },
        xsTextareaSimple: {
          label: "Simple textarea"
        },
        xsTextareaSimpleDis: {
          label: "Simple textarea disabled",
          value: "Hello world!"
        },
        xsRadioButton: {},
        xsCheckbox: {
          label: "checkbox",
          type: "checkbox"
        },
        xsCheckboxTrue: {
          label: "checkbox default true",
          type: "checkbox",
          value: true
        },
        xsCheckboxTrueDisabled: {
          label: "checkbox true disable",
          type: "checkbox",
          value: true
        },
        xsCheckboxFalseDisabled: {
          label: "checkbox false disable",
          type: "checkbox",
          value: false
        }
      }
    }
  }
}
