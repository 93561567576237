"use strict"

import React from "react" //, { useEffect } 
import { observer } from "mobx-react"
import { injectIntl } from "react-intl"

import { Grid } from "@material-ui/core"

import XsTable from "../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"

import RozvodyRecordStore from "./rozvodyReportStore"
import GlobalStore from "../../../global/store/GlobalStore"
import classnames from "classnames"
// import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"


function ListOfDistributions(props) {
  const isDisabled = RozvodyRecordStore.isDisabled
  //HTD
  let columnsHTD = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Teplonosná látka",
      width: 100,
      accessor: "heating_medium_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo tepla dodaného do verejného rozvodu [MWh]</div>,
      width: 75,
      accessor: "input_heat_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo tepla na výstupe z verejného rozvodu [MWh]</div>,
      width: 75,
      accessor: "output_heat_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Účinnosť rozvodu tepla [%]</div>,
      width: 75,
      accessor: "efficiency",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  // EDS,ETN
  let columnsEDS = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo distribuovanej elektriny v [MWh]</div>,
      width: 75,
      accessor: "transmitted_electricity_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Straty elektriny pri distribúcii [MWh]</div>,
      width: 75,
      accessor: "transmission_losses",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Vlastná spotreba elektriny pri prenose [MWh]</div>,
      width: 75,
      accessor: "energy_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Účinnosť [%]</div>,
      width: 75,
      accessor: "efficiency",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //FTS
  let columnsFTS = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo spotrebovanej energie [MWh]</div>,
      width: 75,
      accessor: "input_heat_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo prepravených pohonných látok [t]</div>,
      width: 75,
      accessor: "fuel_transmitted_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Energetická náročnosť [MWh/t]</div>,
      width: 75,
      accessor: "energy_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //GDS
  let columnsGDS = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Spotreba elektriny [MWh]</div>,
      width: 75,
      accessor: "consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Ostatná spotreba elektriny [MWh]</div>,
      width: 75,
      accessor: "other_electricity_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Spotreba plynu [MWh]</div>,
      width: 75,
      accessor: "gas_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo distribuovaného plynu v [MWh]</div>,
      width: 75,
      accessor: "transmitted_gas_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Účinnosť [%]</div>,
      width: 75,
      accessor: "efficiency",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //GTN
  let columnsGTN = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Spotreba elektriny [MWh]</div>,
      width: 75,
      accessor: "consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Spotreba plynu [MWh]</div>,
      width: 75,
      accessor: "gas_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo distribuovaného plynu v [MWh]</div>,
      width: 75,
      accessor: "transmitted_gas_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //PWS
  let columnsPWS = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo spotrebovanej energie [MWh]</div>,
      width: 75,
      accessor: "consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo predanej vody z verejného vodovodu [tis.m3]</div>,
      width: 75,
      accessor: "sold_water_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Energetická náročnosť</div>,
      width: 75,
      accessor: "transmitted_gas_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //OTS
  let columnsOTS = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo spotrebovanej energie [MWh]</div>,
      width: 75,
      accessor: "energy_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Množstvo odovzdanej ropy [ton]</div>,
      width: 75,
      accessor: "oil_transmitted_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Energetická náročnosť</div>,
      width: 75,
      accessor: "efficiency",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  //SEW
  let columnsSEW = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "distributor_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "distributor_name",
      sortType: "basic"
    },
    {
      Header: () => <div className="textRight">Množstvo spotrebovanej energie [MWh]</div>,
      width: 75,
      accessor: "energy_consumption",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Odkanalizovaný objem [tis.m3]</div>,
      width: 75,
      accessor: "sewerage_amount",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: () => <div className="textRight">Energetická náročnosť</div>,
      width: 75,
      accessor: "efficiency",
      sortType: "basic",
      Cell: (row) => <div className="textRight">{isNotEmpty(row.value) ? row.value.toLocaleString("sk-sk") : ""}</div>
    },
    {
      Header: "Poznámka k rozvodu",
      width: 75,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className={classnames("fas fa-trash-alt fa-lg", {
                  "redDanger pointer": !isDisabled,
                  greyLight: isDisabled
                })}
                onClick={() =>
                  isDisabled ? null : GlobalStore.openConfirmationDialog("removeRozvod", { record_id: obj.value })
                }
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]


  const getTableColumns = (typeName) => {
    switch (typeName) {
      case "HTD":
        return columnsHTD
      case "EDS":
        return columnsEDS
      case "ETN":
        return columnsEDS
      case "FTS":
        return columnsFTS
      case "GDS":
        return columnsGDS
      case "GTN":
        return columnsGTN
      case "PPS":
        return columnsPWS
      case "OTS":
        return columnsOTS
      case "SEW":
        return columnsSEW
      default:
        return []
    }
  }




  // useEffect(() => {
  // 	CvsReportRecordStore.getCvsRecords(props.report_id)
  // }, [])

  return (
    <React.Fragment>
      <XsTable
        columns={getTableColumns(RozvodyRecordStore.distributionType)}
        defaultSort={{ id: "custom_index", desc: false }}
        data={RozvodyRecordStore.distributionsForReport}
        colorful
        size="small"
        onRowClick={(row) => !isDisabled && RozvodyRecordStore.loadDistribution(row)}
      />
      <XsConfirmationDialog
        type="danger"
        name="removeRozvod"
        titleIcon={<i className="fas fa-exclamation-triangle"></i>}
        title="Skutočne chcete odstrániť záznam?"
        text={`Chystáte sa odstrániť záznam. Skutočne to chcete urobiť?`}
        confirmBtn={{
          icon: <i className="fal fa-trash-alt fa-lg" />,
          text: "Common.btn.delete"
        }}
        cancelBtn={{
          icon: <i className="fal fa-times fa-lg" />,
          text: "Common.btn.back"
        }}
        onConfirmation={() => {
          RozvodyRecordStore.deleteRecord(GlobalStore.confParams.record_id, props.report_id)

        }}
      />
    </React.Fragment>
  )
}

export default injectIntl(observer(ListOfDistributions))
