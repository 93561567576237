"use strict"

import React from "react"
import {observer} from "mobx-react"
import moment from "moment"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box, Typography} from "@material-ui/core"
import XsServerTable from "../../../global/ui/xsTable/xsServerTable"

import api from "../../../global/actions/api"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.project" />,
		width: 100,
		accessor: "pr_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.pr_kod) ? `${row.pr_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.pr_nazov) ? row.pr_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.pr_stav) ? row.pr_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Zameranie:</span>{" "}
						{isNotEmpty(row.pr_zameranieProjektu) ? row.pr_zameranieProjektu : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dĺžka:</span>{" "}
						{isNotEmpty(row.pr_dlzkaCelkovaProjektu) ? row.pr_dlzkaCelkovaProjektu : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dĺžka hl. aktivít:</span>{" "}
						{isNotEmpty(row.pr_dlzkaCelkovaHlavnychAktivit) ? row.pr_dlzkaCelkovaHlavnychAktivit : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Číslo zmluvy:</span>{" "}
						{isNotEmpty(row.projekt_cisloZmluvy) ? row.projekt_cisloZmluvy : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Zazmluvnená suma:</span>{" "}
						{isNotEmpty(row.pr_sumaZazmluvnena) ? `${row.pr_sumaZazmluvnena} €` : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Dátumy",
		width: 100,
		accessor: "pr_datumZaciatkuRealizacie",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.pr_datumZaciatkuRealizacie)
				? rowA.original.pr_datumZaciatkuRealizacie
				: ""
			const send_date_time_B = isNotEmpty(rowB.original.pr_datumZaciatkuRealizacie)
				? rowB.original.pr_datumZaciatkuRealizacie
				: ""
			if (send_date_time_A > send_date_time_B) return -1
			if (send_date_time_B > send_date_time_A) return 1
			return 0
		},
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">Začatie hlav. aktivít:</span>{" "}
						{isNotEmpty(row.pr_datumZaciatkuHlavnychAktivit)
							? moment(row.pr_datumZaciatkuHlavnychAktivit).format("DD.MM.YYYY")
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Koniec hlav. aktivít:</span>{" "}
						{isNotEmpty(row.pr_datumKoncaHlavnychAktivit)
							? moment(row.pr_datumKoncaHlavnychAktivit).format("DD.MM.YYYY")
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Začiatok realizácie:</span>{" "}
						{isNotEmpty(row.pr_datumZaciatkuRealizacie)
							? moment(row.pr_datumZaciatkuRealizacie).format("DD.MM.YYYY")
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Koniec realizácie:</span>{" "}
						{isNotEmpty(row.pr_datumKoncaRealizacie) ? moment(row.pr_datumKoncaRealizacie).format("DD.MM.YYYY") : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Platnosť zmluvy:</span>{" "}
						{isNotEmpty(row.pr_datumPlatnostiZmluvy) ? moment(row.pr_datumPlatnostiZmluvy).format("DD.MM.YYYY") : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Účinnosť zmluvy:</span>{" "}
						{isNotEmpty(row.pr_datumUcinnostiZmluvy) ? moment(row.pr_datumUcinnostiZmluvy).format("DD.MM.YYYY") : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Mer. ukazovateľ",
		width: 100,
		accessor: "meratUkaz_hodnotaCielovaCelkova",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">Skut. stav:</span>{" "}
						{isNotEmpty(row.meratUkaz_aktualnySkutocnyStav) ? row.meratUkaz_aktualnySkutocnyStav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Stav muži:</span>{" "}
						{isNotEmpty(row.meratUkaz_aktualnySkutocnyStavMuzi) ? row.meratUkaz_aktualnySkutocnyStavMuzi : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Stav ženy:</span>{" "}
						{isNotEmpty(row.meratUkaz_aktualnySkutocnyStavZeny) ? row.meratUkaz_aktualnySkutocnyStavZeny : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Posl. meranie:</span>{" "}
						{isNotEmpty(row.meratUkaz_datumPoslednehoMerania)
							? moment(row.meratUkaz_datumPoslednehoMerania).format("DD.MM.YYYY")
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Celk. hodnota:</span>{" "}
						{isNotEmpty(row.meratUkaz_hodnotaCielovaCelkova) ? row.meratUkaz_hodnotaCielovaCelkova : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Príjimateľ",
		width: 100,
		accessor: "prijimatel_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.ico" />:
						</span>{" "}
						{isNotEmpty(row.prijimatel_ico) ? `${row.prijimatel_ico}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.prijimatel_nazov) ? row.prijimatel_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Obec:</span> {isNotEmpty(row.prijimatel_obecNazov) ? row.prijimatel_obecNazov : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Proj. ukazovateľ",
		width: 100,
		accessor: "projUkaz_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.projUkaz_kod) ? `${row.projUkaz_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.projUkaz_nazov) ? row.projUkaz_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Definícia:</span>{" "}
						{isNotEmpty(row.projUkaz_definicia) ? htmlToPlain(row.projUkaz_definicia).shortTo(100) : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Merná jednotka:</span>{" "}
						{isNotEmpty(row.projUkaz_mernaJednotka) ? row.projUkaz_mernaJednotka : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Typ výpočtu:</span>{" "}
						{isNotEmpty(row.projUkaz_typVypoctu) ? row.projUkaz_typVypoctu : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Schválená ZONFP",
		width: 100,
		accessor: "szonfp_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.szonfp_kod) ? `${row.szonfp_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.szonfp_nazov) ? htmlToPlain(row.szonfp_nazov) : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dát. schválenia:</span>{" "}
						{isNotEmpty(row.szonfp_datumSchvalenia) ? moment(row.szonfp_datumSchvalenia).format("DD.MM.YYYY") : "-"}
					</Grid>

					<Grid item>
						<span className="bold">Schvál. celk. suma:</span>{" "}
						{isNotEmpty(row.szonfp_sumaSchvalenaCelkova) ? `${row.szonfp_sumaSchvalenaCelkova} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schvál. NFP suma:</span>{" "}
						{isNotEmpty(row.szonfp_sumaSchvalenaNFP) ? `${row.szonfp_sumaSchvalenaNFP} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schvál. vlast. zdroje:</span>{" "}
						{isNotEmpty(row.szonfp_sumaSchvalenaVlastnychZdrojov)
							? `${row.szonfp_sumaSchvalenaVlastnychZdrojov} €`
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schvál. per. spolufinancovania:</span>{" "}
						{isNotEmpty(row.szonfp_percentoSchvaleneSpolufinancovania)
							? `${row.szonfp_percentoSchvaleneSpolufinancovania} %`
							: "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhl. výzva",
		width: 100,
		accessor: "vyhlVyzva_stav",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_kod) ? `${row.vyhlVyzva_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.kindOf" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_druh) ? row.vyhlVyzva_druh : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_stav) ? row.vyhlVyzva_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_typ) ? row.vyhlVyzva_typ : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dát. vyhl.:</span>{" "}
						{isNotEmpty(row.vyhlVyzva_datumVyhlasenia)
							? moment(row.vyhlVyzva_datumVyhlasenia).format("DD.MM.YYYY")
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dát. uzat.:</span>{" "}
						{isNotEmpty(row.vyhlVyzva_datumUzavretia) ? moment(row.vyhlVyzva_datumUzavretia).format("DD.MM.YYYY") : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Alokácia SK:</span>{" "}
						{isNotEmpty(row.vyhlVyzva_alokaciaSR) ? `${row.vyhlVyzva_alokaciaSR} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Alokácia EÚ:</span>{" "}
						{isNotEmpty(row.vyhlVyzva_alokaciaEU) ? `${row.vyhlVyzva_alokaciaEU} €` : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhlasovateľ",
		width: 100,
		accessor: "vyhlasovatel_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.ico" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_ico) ? `${row.vyhlasovatel_ico}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_nazov) ? row.vyhlasovatel_nazov : "-"}
					</Grid>
				</Grid>
			)
		}
	}
]

function EtlMeasurableIndicatorsContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y">
			<Grid container>
				<Grid item xs={12}>
					<Box pt={2} pb={2}>
						<Typography variant="h5">Merateľné ukazovatele</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<XsServerTable api={api.loadMeasurableIndicators} columns={columns} />
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(EtlMeasurableIndicatorsContainer))
