"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RmeeEmployeeContactsTable from "./rmeeEmployeeContactsTable"
import RmeeEmployeeContactDialog from "./rmeeEmployeeContactDialog"

import RmeeEmployeeContactsStore from "./rmeeEmployeeContactsStore"

function RmeeEmployeeContactsContainer(props) {
	useEffect(() => {
		RmeeEmployeeContactsStore.loadContacts(props.person_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item container xs={12} justify="flex-end">
						<Grid>
							<XsButton
								text="Pridať kontakt"
								onClick={() => RmeeEmployeeContactsStore.openEmployeeContactDialog(props.person_id)}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<RmeeEmployeeContactsTable person_id={props.person_id} />
					</Grid>
				</Grid>
			</Box>
			<RmeeEmployeeContactDialog />
		</Container>
	)
}

export default injectIntl(observer(RmeeEmployeeContactsContainer))
