export const codeListsConfig = [
	{
		uri: "/cl/Entity.CL.Title",
		name: "titles"
	},
	{
		uri: "/cl/Entity.CL.Gender",
		name: "genders"
	},
	{
		uri: "/cl/Entity.CL.EmployeeCategory",
		name: "employeeCategories"
	},
	{
		uri: "/cl/Entity.CL.AddressType",
		name: "addressType"
	},
	{
		uri: "/cl/Entity.CL.Country",
		name: "countries"
	},
	{
		uri: "/cl/Entity.CL.Address.Region",
		name: "addressRegion"
	},
	{
		uri: "/entity/cl/county",
		name: "addressCounty"
	},
	{
		uri: "/cl/Entity.CL.ContactType",
		name: "contactTypes"
	},
	{
		uri: "/cl/Fin.CL.BankAccountType",
		name: "bankAccountType"
	},
	{
		uri: "/cl/Entity.CL.OrgUnitParamType",
		name: "orgUnitParamType"
	},
	{
		uri: "/cl/ISEE.CL.CVS.ReportStatus",
		name: "reportStatus"
	},
	{
		uri: "/cl/ISEE.CL.Data.Unit",
		name: "unit"
	},
	// {
	// 	// uri: "/cl/ISEE.CL.Energy.Medium",
	// 	uri: "/rmee/isee/cl/energy/medium",
	// 	name: "medium"
	// },
	{
		uri: "/cl/ISEE.CL.OES.ConsumerGroup",
		name: "consumerGroup"
	},
	{
		uri: "/cl/ISEE.CL.Distribution.HeatingMedium",
		name: "heatingMedium"
	},
	{
		uri: "/cl/ISEE.CL.Distribution.Type",
		name: "distributionType"
	},
	{
		uri: "/cl/ISEE.CL.EnergeticObject.Category",
		name: "objectCategory"
	},
	{
		uri: "/cl/ISEE.CL.OwnershipType",
		name: "objectOwnership"
	},
	{
		uri: "/cl/Entity.CL.Address.City",
		name: "city"
	},
	{
		uri: "/cl/ISEE.CL.LegislativeDuty",
		name: "legislativeDuty"
	},
	{
		uri: "/cl/ISEE.CL.Building.HeatingSystem",
		name: "Building.HeatingSystem"
	},
	{
		uri: "/cl/ISEE.CL.Building.Material",
		name: "Building.Material"
	},
	{
		uri: "/cl/ISEE.CL.Building.ConstructionSystem",
		name: "Building.ConstructionSystem"
	},
	{
		uri: "/cl/ISEE.CL.EnergeticObject.Review",
		name: "EnergeticObject.Review"
	},
	{
		uri: "/cl/ISEE.CL.EnergeticObject.SpecificRelType",
		name: "EnergeticObject.SpecificRelType"
	}
]
