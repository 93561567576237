"use strict"

import React, {useState, useEffect} from "react"
import {inject, observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import Dropzone from "react-dropzone"

import fields from "./identificationFormFields"

import {Grid, Typography} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import {downloadAttachment} from "../../../../../global/helpers/actions"
import api from "../../../../../global/actions/api"

import BuildingIdentificationStore from "./buildingIdentificationStore"
import BudovyBuildingStore from "../../budovyBuildingStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import RouterStore from "../../../../../global/store/RouterStore"
import classnames from "classnames"

function IdentificationForm() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			BudovyBuildingStore.generateDataAndSave(form, closeForm)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		BuildingIdentificationStore.formRef = form

		return () => {
			BudovyBuildingStore.dropedFiles = []
		}
	}, [])

	const [disabled, setDisabled] = useState(true)

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (BudovyBuildingStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			BudovyBuildingStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (file) => {
		downloadAttachment("ISEE.Data.Building", form.$("_id").value, file._id)
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		BudovyBuildingStore.dropedFiles.splice(attachmentIndex, 1)
	}

	const closeForm = () => {
		RouterStore.push("/budovy")
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={3}>
				<XsInput field={form.$("name")} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsSearchSelect field={form.$("category")} items={GlobalStore.CL["objectCategory"]} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsSearchSelect field={form.$("owner")} items={GlobalStore.CL["objectOwnership"]} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsSearchSelect
					field={form.$("relation")}
					items={GlobalStore.CL["EnergeticObject.SpecificRelType"]}
					disabled={disabled}
				/>
			</Grid>
			<Grid item xs={3}>
				<XsAutocomplete
					field={form.$("city")}
					disabled={disabled}
					minLengthForSearch="3"
					api={api.getExtendCLCity}
					inputRenderer={(obj) => `${obj.name_ext} - ${obj.county_name}`}
					saveValue={(obj) => obj.code}
					postAction={(obj) => {
						const codeLength = obj.code.length

						const cityCode = obj.code.slice(codeLength - 6, codeLength)

						form.$("cadastral_city_code").value = cityCode
						BudovyBuildingStore.getCadastralArea(cityCode)
					}}
					onClear={() => {
						form.$("cadastral_domain").value = ""
						form.$("cadastral_city_code").value = ""
					}}
				/>
			</Grid>
			<Grid item xs={3}>
				<XsSearchSelect
					field={form.$("cadastral_domain_code")}
					disabled={isEmpty(form.$("city").value) || BudovyBuildingStore.cadastralCityData.length === 0 || disabled}
					items={BudovyBuildingStore.cadastralCityData}
					onChange={(val) => {
						const area = BudovyBuildingStore.cadastralCityData.find((x) => x._id === val)

						if (area) {
							form.$("cadastral_domain").value = area.city_id
						}
					}}
				/>
			</Grid>
			<Grid item xs={3}>
				<XsInput field={form.$("registration_number")} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsInput field={form.$("parcel_number")} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsInput field={form.$("street")} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsInput field={form.$("house_number")} disabled={disabled} />
			</Grid>
			<Grid item xs={6}>
				<XsInput field={form.$("tag")} disabled={disabled} />
			</Grid>
			<Grid item xs={3}>
				<XsInput field={form.$("system_id")} disabled={disabled} />
			</Grid>
			<Grid item xs={6}>
				<XsInput field={form.$("note")} disabled={disabled} />
			</Grid>
			<Grid item xs={12}>
				<Dropzone
					onDrop={(files) => {
						handleDrop(files, 5)
					}}
					id="dropzone"
					disabled={disabled}
					accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
				>
					{isSafe(BudovyBuildingStore.dropedFiles) && BudovyBuildingStore.dropedFiles.length ? (
						BudovyBuildingStore.dropedFiles.map((file, index) => (
							<div key={index} className={"dropedFile"}>
								<i
									className="fal fa-file icon"
									onClick={(e) => {
										e.stopPropagation()
										handleDropzonePreview(file)
									}}
								/>
								<i
									className={classnames("far fa-trash-alt deleteIcon", {pointer: !disabled})}
									onClick={(e) => {
										if (!disabled) {
											e.stopPropagation()
											GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
												fileIndex: index
											})
										}
									}}
								/>
								<br />
								<div
									className={"mac_ellipsis"}
									data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
									data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
								/>
							</div>
						))
					) : (
						<div
							className={"dropzonePlaceholder"}
						>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
					)}
				</Dropzone>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">
					Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
				</Typography>
			</Grid>
			<Grid item container xs={12} spacing={2} justify="flex-end">
				{disabled ? (
					<Grid item>
						<XsButton variant="outlined" text="Upraviť základné údaje" onClick={() => setDisabled(false)} />
					</Grid>
				) : (
					<React.Fragment>
						<Grid item>
							<XsButton variant="outlined" text="Zrušiť" onClick={() => setDisabled(true)} />
						</Grid>
						<Grid item>
							<XsButton text="Uložiť" onClick={form.onSubmit} />
						</Grid>
					</React.Fragment>
				)}
			</Grid>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
		</Grid>
	)
}

export default inject("routing")(injectIntl(observer(IdentificationForm)))
