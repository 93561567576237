"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl, FormattedMessage} from "react-intl"
import {Grid, Typography, Box} from "@material-ui/core"
import XsTable from "../../../global/ui/xsTable/xsTable"
import XsButton from "../../../global/ui/xsButton/xsButton"

import RouterStore from "../../../global/store/RouterStore"
import RmeeStore from "../rmeeStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.ico" />,
		width: 125,
		accessor: "entity.identifier",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.companyName" />,
		width: 200,
		accessor: "entity.name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.shortName" />,
		width: 125,
		accessor: "entity.short_name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.dic" />,
		width: 100,
		accessor: "entity.tax_identification_number",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.icdph" />,
		width: 100,
		accessor: "entity.vat_identification_number",
		sortType: "basic"
	}
]

function RmeeCompaniesTable() {
	useEffect(() => {
		RmeeStore.loadCompanies()
	}, [])

	return (
		<Grid container direction="column">
			<Grid item>
				<Box pt={2} pb={2}>
					<Typography variant="h5">Spoločnosti</Typography>
				</Box>
			</Grid>
			<Grid item container justify="flex-end">
				<Grid item>
					<XsButton
						text="Nová spoločnosť"
						onClick={() => RmeeStore.openCompanyDialog()}
						icon={<i className="fal fa-plus"></i>}
					/>
				</Grid>
			</Grid>
			<Grid item>
				<XsTable
					columns={columns}
					data={RmeeStore.companiesData}
					onRowClick={(row) => RouterStore.push(`/company?id=${row.entity._id}`)}
				/>
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(RmeeCompaniesTable))
