"use strict"

import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { injectIntl } from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import { Box, Grid } from "@material-ui/core"
import XsButton from "../../../global/ui/xsButton/xsButton"
import XsTable from "../../../global/ui/xsTable/xsTable"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"
import RegistryStore from "./registryStore"
// import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"
import RegistryDialog from "./registryDialog"



function RegistryContainer() {

  const hooks = {
    onSubmit() { },
    onSuccess() { },
    onError() { }
  }

  const [form] = useState(
    new MobxReactForm(
      { fields: { distributor: { label: "Činnosť" } } },
      { plugins: { dvr: dvr(validatorjs) }, hooks }
    ))

  useEffect(() => {
    RegistryStore.loadListData(form)
  }, [])


  const columns = [
    {
      Header: "Poradové číslo",
      width: 25,
      accessor: "custom_index",
      sortType: "basic"
    },
    {
      Header: "ID rozvodu",
      width: 100,
      accessor: "specific_rel_id",
      sortType: "basic"
    },
    {
      Header: "Činnosť",
      width: 100,
      accessor: "distribution_type_id",
      sortType: "basic"
    },
    {
      Header: "Názov",
      width: 100,
      accessor: "name",
      sortType: "basic"
    },
    {
      Header: "Poznámka k rozvodu",
      width: 100,
      accessor: "note",
      sortType: "basic"
    },
    {
      clickable: false,
      width: 10,
      accessor: "_id",
      Cell: (obj) => {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <i
                className="fas fa-trash-alt fa-lg pointer redDanger"
                onClick={() => GlobalStore.openConfirmationDialog("removeRegistry", { _id: obj.value })}
              ></i>
            </Grid>
          </Grid>
        )
      }
    }
  ]


  return (
    <Box p={3}>
      <Grid container className="mb-5" spacing={1}>
        <Grid item xs={2}>
          Register rozvodov
        </Grid>
        <Grid item xs={4}>
          <XsSearchSelect
            field={form.$("distributor")}
            items={GlobalStore.CL["distributionType"]}
            onChange={() => {
              RegistryStore.loadListData(form)
            }}
            onClear={() => {
              RegistryStore.loadListData(form)
            }}
          />
        </Grid>
        <Grid item>
          <XsButton
            icon={<i className="fal fa-export fa-lg"></i>}
            text={"Import zoznamu"}
            onClick={() => {
            }}
          />
        </Grid>
        <Grid item>
          <XsButton
            icon={<i className="fal fa-export fa-lg"></i>}
            text={"Export zoznamu"}
            onClick={() => {
            }}
          />
        </Grid>
        <Grid item>
          <XsButton
            icon={<i className="fal fa-plus fa-lg"></i>}
            text={"Upraviť zoznam"}
            onClick={() => {
            }}
          />
        </Grid>
        <Grid item>
          <XsButton
            icon={<i className="fal fa-plus fa-lg"></i>}
            text={"Pridať záznam"}
            onClick={() => {
              RegistryStore.dialogOpen()
            }}
          />
        </Grid>
      </Grid>

      <XsTable
        columns={columns}
        defaultSort={{ id: "custom_index", desc: false }}
        data={RegistryStore.listData}
        colorful
        size="small"
        // onRowClick={(row) => RouterStore.push(`/rozvody_report?year=${row.year}&id=${row.report_id}`)}
      />
      <XsConfirmationDialog
        type="danger"
        name="removeRegistry"
        text="Naozaj chcete odstrániť záznam?"
        confirmBtn={{
          icon: <i className="fal fa-trash-alt fa-lg" />,
          text: "Common.btn.yes"
        }}
        cancelBtn={{
          icon: <i className="fal fa-times fa-lg" />,
          text: "Common.btn.no"
        }}
        onConfirmation={() => {
          RegistryStore.deleteRegistry(GlobalStore.confParams._id, form)
        }}
      />
      <RegistryDialog />
    </Box>
  )
}

export default injectIntl(observer(RegistryContainer))
