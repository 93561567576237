import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				orgunit_id: {},
				bank: {
					label: <FormattedMessage id="Common.label.bank" />,
					rules: "string|required"
				},
				type: {
					label: <FormattedMessage id="Common.label.type" />,
					rules: "string|required"
				},
				prefix: {
					label: <FormattedMessage id="Common.label.prefix" />,
					rules: "string"
				},
				number: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "string|required"
				},
				primary: {
					type: "checkbox",
					label: <FormattedMessage id="Common.label.primary" />,
					value: false
				}
			}
		}
	}
}
