"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeOrgunitParamFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import RmeeOrgunitParamsStore from "./rmeeOrgunitParamsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeOrgunitParamDialog() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RmeeOrgunitParamsStore.saveOrgunitParam(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		RmeeOrgunitParamsStore.orgunitParamFormRef = form
	}, [])

	return (
		<Dialog
			open={RmeeOrgunitParamsStore.isOpenOrgunitParamDialog}
			onEscapeKeyDown={() => RmeeOrgunitParamsStore.closeOrgunitParamDialog()}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<i className="fal fa-user-cog fa-2x"></i>
					</Grid>
					<Grid item>
						<Typography variant="h6">Pridať parameter</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => RmeeOrgunitParamsStore.closeOrgunitParamDialog()}
					></i>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsSearchSelect field={form.$("param_type")} items={GlobalStore.CL["orgUnitParamType"]} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={form.$("value")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={1}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => RmeeOrgunitParamsStore.closeOrgunitParamDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(RmeeOrgunitParamDialog))
