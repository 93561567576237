import {observable, action} from "mobx"

import {getFilters} from "../../../../global/helpers/api"

import GlobalStore from "../../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"

import api from "../../../../global/actions/api"
import {exportToExcel, isReportDisabled, getCompanyId} from "../../../../global/helpers/actions"

class CvsRecordStore {
	@observable isOpenRecordDialog = false

	@observable recordsData = []

	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@observable existMediumIds = []

	@observable graphData = []
	@observable totalCost = 0

	formRef = null

	@action openRecordDialog(report_id, year) {
		this.formRef.$("report_id").value = report_id
		this.formRef.$("year").value = +year

		this.isOpenRecordDialog = true
	}

	@action closeRecordDialog(form) {
		form.reset()
		this.dropedFiles = []
		this.graphData = []
		this.isOpenRecordDialog = false
	}

	@observable isDisabled = false

	@action getReportById(report_id) {
		api
			.getCVSReport(report_id)
			.call()
			.then((res) => {
				if (res && res.status_id) {
					this.isDisabled = isReportDisabled(res.status_id)
				} else {
					this.isDisabled = false
				}
			})
	}

	@action getCvsRecords(report_id) {
		const req = getFilters([`report_id=${report_id}`, `company_id=${getCompanyId()}`])

		api
			.getCvsRecords(req)
			.call()
			.then((records) => {
				if (isSafe(records) && isSafe(records.rows) && records.rows.length) {
					let tempExist = []

					records.rows.forEach((x) => {
						tempExist.push(x.medium_id)
					})

					this.existMediumIds = tempExist

					const recordsLength = records.rows.length

					this.tempConsumptionInKWH = 0
					this.consumptionInKWH = 0

					//Vyratanie celkovej sumy
					let totalCost = 0
					records.rows.forEach((row) => {
						totalCost += parseInt(row.costs)
					})
					this.totalCost = totalCost

					this.recordsData = records.rows.map((row, idx) => ({
						kwh: "Spotreba [kWh]",
						consumptionKWH: this.getConsumptionInKWH(row, recordsLength, idx + 1),
						...row
					}))
				} else {
					this.recordsData = []
					this.existMediumIds = []
				}
			})
	}

	tempConsumptionInKWH = 0
	@observable consumptionInKWH = 0

	getConsumptionInKWH = (row, recordsLength, idx) => {
		if (row.medium_id == "kWh") {
			this.tempConsumptionInKWH += row.consumption

			if (recordsLength === idx) {
				this.consumptionInKWH = this.tempConsumptionInKWH
			}

			return row.consumption
		} else {
			const medium = RegistersCacheStore.mediumCVS.find((x) => x._id == row.medium_id)

			if (medium) {
				const unit = medium.units.find((unit) => unit._id == row.unit_id)

				if (unit && unit.conversion_rate && unit.conversion_rate.middle) {
					const consInKWH = Math.round(row.consumption * unit.conversion_rate.middle)

					this.tempConsumptionInKWH += consInKWH

					if (recordsLength === idx) {
						this.consumptionInKWH = this.tempConsumptionInKWH
					}

					return consInKWH
				}
			}
		}
	}

	@action exportCvsRecords(report_id) {
		GlobalStore.loading(true, "Exportujem...")
		let filters = getFilters([`report_id=${report_id}`])

		let req = {
			uri: "/isee/cvs/records",
			filters: filters.filters
		}
		exportToExcel(req)
	}

	@action getHistoricalData(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			medium_id: values.medium_id
		}

		api
			.getRecordHistory(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("consumption_in_unit_of_measure_history").value = res.consumption
					form.$("costs_history").value = res.costs
					form.$("unit_history").value = res.unit_id
				}
			})
	}

	@action getRecordById(record_id, year) {
		api
			.getCvsRecordById(record_id)
			.call()
			.then((record) => {
				if (isSafe(record) && isNotEmpty(record.record_id)) {
					this.openRecordDialog(record.report_id, year)

					this.formRef.$("_id").value = record.record_id
					this.formRef.$("medium_id").value = record.medium_id
					this.formRef.$("unit_id").value = record.unit_id
					this.formRef.$("note").value = record.note
					this.formRef.$("costs").value = record.costs
					this.formRef.$("consumption_in_unit_of_measure").value = record.consumption

					const medium = RegistersCacheStore.mediumCVS.find((x) => x._id == record.medium_id)

					this.formRef.$("limited_price").value = `${getPriceFormat(medium.price_limit.from)} - ${getPriceFormat(
						medium.price_limit.to
					)}`

					this.formRef.$("limit_from").value = medium.price_limit.from
					this.formRef.$("limit_to").value = medium.price_limit.to

					const calcPrice = (record.costs / record.consumption).toFixed(5)

					if (calcPrice >= this.formRef.$("limit_from").value && calcPrice <= this.formRef.$("limit_to").value) {
						this.formRef.$("calculated_price").value = `${calcPrice} - v rozsahu`
						this.formRef.$("inLimit").value = true
					} else {
						if (calcPrice >= this.formRef.$("limit_from").value) {
							this.formRef.$("calculated_price").value = `${calcPrice} - nad rozsahom`
						} else {
							this.formRef.$("calculated_price").value = `${calcPrice} - pod rozsahom`
						}
						this.formRef.$("inLimit").value = false
					}

					const req = {
						company_id: getCompanyId(),
						year: year,
						medium_id: record.medium_id
					}

					this.getGraphData(record.medium_id)

					api
						.getRecordHistory(req)
						.call()
						.then((res) => {
							if (isSafe(res)) {
								this.formRef.$("consumption_in_unit_of_measure_history").value = res.consumption
								this.formRef.$("costs_history").value = res.costs
								this.formRef.$("unit_history").value = res.unit_id
							}
						})
				}

				api
					.getAttachments("ISEE.Data.CVSRecord", record_id)
					.call()
					.then((attachments) => {
						if (isSafe(attachments) && attachments.length) {
							attachments.forEach(async (att) => {
								let res = await api.getAttachmentContent(att.api_url).call()
								res.blob().then((blob) => {
									let tmp = new File([blob], att.file_name, {
										type: att.content_type
									})

									tmp._id = att._id

									tmp.api_url = att.api_url
									this.dropedFiles.push(tmp)
								})
							})
						}
					})
			})
	}

	@action saveRecord(form) {
		const values = form.values()

		const req = {
			report_id: values.report_id,
			medium_id: values.medium_id,
			consumption: values.consumption_in_unit_of_measure,
			costs: values.costs,
			unit_id: values.unit_id,
			note: values.note
		}

		if (isNotEmpty(values._id)) {
			req["record_id"] = values._id
		}

		api
			.saveCVSRecord(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					if (isNotEmpty(req.record_id)) {
						GlobalStore.setNotificationMessage("Energonosič úspešne upravený", "success")
					} else {
						GlobalStore.setNotificationMessage("Energonosič úspešne pridaný", "success")
					}

					if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
						let attachReq = []
						let binaryDataArr = []
						let files = this.dropedFiles
						const closeDialog = this.closeRecordDialog.bind(this)
						const refreshList = this.getCvsRecords.bind(this)

						let getData = function(index) {
							let data = files[index]
							let reader = new FileReader()

							reader.readAsBinaryString(data)
							reader.onloadend = function() {
								binaryDataArr.push(reader.result)

								attachReq.push({
									_id: isNotEmpty(data._id) ? data._id : null,
									_ref: false,
									_type: "SIEA.Data.Attachment",
									active: true,
									rel_class_name: "ISEE.Data.CVSRecord",
									rel_object_id: res._id,
									content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
									type: "General",
									file_name: data.name,
									size: data.size
								})

								if (files.length > index + 1) getData(index + 1)
								else {
									api
										.saveAttachments(attachReq)
										.call()
										.then(async (attachs) => {
											if (isSafe(attachs) && attachs.length) {
												let error = false

												for (let key = 0; key < Object.keys(attachs).length; key++) {
													if (isSafe(files[key])) {
														files[key]._id = attachs[key]._id
													}

													await api
														.saveAttachmentContent(attachs[key]._id, binaryDataArr[+key])
														.call()
														.then((content) => {
															if (isNotSafe(content) || (isSafe(content) && content._id)) {
																error = true
															}
														})
												}

												if (error) {
													GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
												} else {
													if (isEmpty(req.record_id)) {
														GlobalStore.setNotificationMessage("Prílohy úspešne uložené", "success")
													}
													closeDialog(form)
													refreshList(values.report_id)
												}
											} else {
												GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
											}
										})
								}
							}
						}
						getData(0)
					} else {
						this.closeRecordDialog(form)
						this.getCvsRecords(values.report_id)
					}
				} else {
					GlobalStore.setNotificationMessage("Pri pridávaní energonosiča došlo ku chybe", "error")
				}
			})
	}

	@action deleteRecord(record_id, report_id) {
		api
			.deleteCVSRecord(record_id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Energonosič úspešne odstránený", "success")
				this.getCvsRecords(report_id)
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachments(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action sendReport(reportId) {
		const req = {
			_id: reportId
		}

		api
			.sendCvsReport(reportId, req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report odoslany", "success")
				GlobalStore.openConfirmationDialog("sendCVSReportSuccess")
			})
	}

	@action getGraphData(medium_id) {
		const req = {
			company_id: getCompanyId(),
			medium_id: medium_id
		}

		api
			.energyConsumptionGraph(req)
			.call()
			.then((res) => {
				this.graphData = res
			})
	}
}

var singleton = new CvsRecordStore()
export default singleton
