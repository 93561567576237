"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./reportDialogFields"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../global/ui/xsButton/xsButton"

import {getCompanyId} from "../../../global/helpers/actions"

import RozvodyReportStore from "./rozvodyReportStore"
import GlobalStore from "../../../global/store/GlobalStore"
import XsAutocomplete from "../../../global/ui/xsInput/xsAutocomplete"
import api from "../../../global/actions/api"

function ReportDialog(props) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RozvodyReportStore.saveReport(form, props.report_id)
		},
		onError(form) {
			logger(form.erros())
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		RozvodyReportStore.formRef = form

		if (props.isUpdating == true && isNotEmpty(props.report_type)) {
			form.$("distribution_type").value = props.report_type
			form.$("year").value = props.year
			handleValidation()
		}
	}, [])

	const [years, setYears] = useState([])

	useEffect(() => {
		const actualYear = moment().year() - 1
		let tempYears = []

		for (let i = 0; i < 5; i++) {
			const year = actualYear - i

			tempYears.push({
				_id: year,
				name: year,
				name_ext: year,
				code: year,
				code_ext: year
			})
		}

		setYears(tempYears)
	}, [])

	const handleValidation = () => {
		const type = form.$("distribution_type").value

		const fieldNames = [
			"transmitted_electricity_amount",
			"transmission_losses",
			"fuel_transmitted_amount",
			"transmitted_gas_amount",
			"other_electricity_consumption",
			"consumption",
			"heating_medium_id",
			"name",
			"input_heat_amount",
			"output_heat_amount",
			"oil_transmitted_amount",
			"sold_water_amount",
			"sewerage_amount",
			"energy_consumed"
		]

		fieldNames.forEach((name) => {
			form.$(name).set("rules", "")
		})

		if (isNotEmpty(form.$("custom_name").value)) {
			form.$("name").set("rules", "")
		} else {
			form.$("name").set("rules", "required")
		}

		let reqFields

		switch (type) {
			case "EDS":
				reqFields = ["transmitted_electricity_amount", "transmission_losses", "energy_consumption"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				form.$("transmitted_gas_amount").set("label", "Vlastná spotreba elektriny pri distribúcii [MWh]")
				break
			case "ETN":
				reqFields = ["transmitted_electricity_amount", "transmission_losses", "energy_consumption"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				form.$("transmitted_electricity_amount").set("label", "Množstvo prenesenej elektriny v [MWh]")
				form.$("transmission_losses").set("label", "Straty elektriny pri prenose [MWh]")
				break
			case "FTS":
				reqFields = ["fuel_transmitted_amount"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			case "GDS":
				reqFields = ["transmitted_gas_amount", "other_electricity_consumption", "consumption", "gas_consumption"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				form.$("transmitted_gas_amount").set("label", "Množstvo distribuovaného plynu [MWh]")
				break
			case "GTN":
				reqFields = ["transmitted_gas_amount", "consumption", "consumption", "gas_consumption"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			case "HTD":
				reqFields = ["heating_medium_id", "name", "input_heat_amount", "output_heat_amount"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			case "OTS":
				reqFields = ["oil_transmitted_amount"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			case "PPS":
				reqFields = ["sold_water_amount", "consumptionPPS"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			case "SEW":
				reqFields = ["sewerage_amount", "energy_consumed"]
				reqFields.forEach((name) => {
					form.$(name).set("rules", "required")
				})
				break
			default:
				break
			// code block
		}
	}

	return (
		<Dialog
			open={RozvodyReportStore.isOpenReportDialog}
			onEscapeKeyDown={() => RozvodyReportStore.closeReportDialog(form)}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="h6">{props.title}</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => RozvodyReportStore.closeReportDialog(form)}
					></i>
					{/* <Grid item>
						<Typography variant="h6">
							Pridať záznam
						</Typography>
					</Grid> */}
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					{props.isUpdating == false && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsSearchSelect
									field={form.$("year")}
									items={isSafe(years) ? years.filter((x) => !RozvodyReportStore.existYears.includes(x._id)) : []}
								/>
							</Grid>
							<Grid item xs={12}>
								<XsSearchSelect
									field={form.$("distribution_type")}
									items={GlobalStore.CL["distributionType"]}
									onChange={() => handleValidation()}
									required
								/>
							</Grid>
						</React.Fragment>
					)}
					{isNotEmpty(form.$("distribution_type").value) && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsAutocomplete
									field={form.$("name")}
									minLengthForSearch="3"
									showTopRows={true}
									api={api.distributionReportName}
									inputRenderer={(obj) => obj.name}
									saveValue={(obj) => obj._id}
									freeText={true}
									freeTextField={form.$("custom_name")}
									filterValue="distributor_id"
									postAction={() => {
										form.$("custom_name").value = ""
										handleValidation()
									}}
									onClear={() => {
										form.$("custom_name").value = ""
									}}
									addSpecialFilter={() => [
										{
											associated_column: "company_id",
											predicate: "=",
											values: [
												{
													id_value: getCompanyId()
												}
											]
										},
										{
											associated_column: "distribution_type_id",
											predicate: "=",
											values: [
												{
													id_value: form.$("distribution_type").value
												}
											]
										}
									]}
								/>
							</Grid>
						</React.Fragment>
					)}

					{["EDS", "ETN"].includes(form.$("distribution_type").value) && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsInput
									field={form.$("transmitted_electricity_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("transmitted_electricity_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("input_heat_amount_history").value) ? `(${form.$("input_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput
									field={form.$("transmission_losses")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("transmission_losses").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("input_heat_amount_history").value) ? `(${form.$("input_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput
									field={form.$("energy_consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("energy_consumption").value = val.replace(",", ".")
										}
									}} //energy_consumed
									// endText={
									// 	isNotEmpty(form.$("input_heat_amount_history").value) ? `(${form.$("input_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "HTD" && (
						<React.Fragment>
							<Grid item xs={6}>
								<XsSearchSelect field={form.$("heating_medium_id")} items={GlobalStore.CL["heatingMedium"]} />
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("input_heat_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("input_heat_amount").value = val.replace(",", ".")
										}
									}}
									endText={
										isNotEmpty(form.$("input_heat_amount_history").value)
											? `(${form.$("input_heat_amount_history").value})`
											: ""
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("output_heat_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("output_heat_amount").value = val.replace(",", ".")
										}
									}}
									endText={
										isNotEmpty(form.$("output_heat_amount_history").value)
											? `(${form.$("output_heat_amount_history").value})`
											: ""
									}
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "FTS" && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsInput
									field={form.$("fuel_transmitted_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("fuel_transmitted_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "GDS" && (
						<React.Fragment>
							<Grid item xs={6}>
								<XsInput
									field={form.$("transmitted_gas_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("transmitted_gas_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("other_electricity_consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("other_electricity_consumption").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("consumption").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("gas_consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("gas_consumption").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "GTN" && (
						<React.Fragment>
							<Grid item xs={6}>
								<XsInput
									field={form.$("transmitted_gas_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("transmitted_gas_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={6}>
								<XsInput
									field={form.$("consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("consumption").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput
									field={form.$("gas_consumption")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("gas_consumption").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "OTS" && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsInput
									field={form.$("oil_transmitted_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("oil_transmitted_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "PPS" && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsInput
									field={form.$("sold_water_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("sold_water_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput
									field={form.$("consumptionPPS")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("consumptionPPS").value = val.replace(",", ".")
										}
									}}
								/>
							</Grid>
						</React.Fragment>
					)}
					{form.$("distribution_type").value == "SEW" && (
						<React.Fragment>
							<Grid item xs={12}>
								<XsInput
									field={form.$("sewerage_amount")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("sewerage_amount").value = val.replace(",", ".")
										}
									}}
									// endText={
									// 	isNotEmpty(form.$("output_heat_amount_history").value) ? `(${form.$("output_heat_amount_history").value})` : ""
									// }
								/>
							</Grid>
							<Grid item xs={12}>
								<XsInput
									field={form.$("energy_consumed")}
									onChange={(val) => {
										if (val.includes(",")) {
											form.$("energy_consumed").value = val.replace(",", ".")
										}
									}}
								/>
							</Grid>
						</React.Fragment>
					)}
					<Grid item xs={12}>
						<XsInput field={form.$("note")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={1}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => RozvodyReportStore.closeReportDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(ReportDialog))
