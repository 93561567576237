import React from "react"
import { observer } from "mobx-react"
import { FormattedMessage } from "react-intl"

import { Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography } from "@material-ui/core"

import "./xsDialog.less"

import XsButton from "../xsButton/xsButton"

import GlobalStore from "../../store/GlobalStore"
import renderHTML from "react-render-html"

export default observer(
	({ name, title, titleIcon, subTitle, text, type, onConfirmation, confirmBtn, cancelBtn, onCancel, hideCancelButton = false }) => {
		const handleClose = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleDecline = () => {
			if (isSafe(onCancel) && typeof onCancel === "function") {
				onCancel()
			}
			GlobalStore.closeConfirmationDialog()
		}

		const handleConfirm = (e) => {
			if (isSafe(onConfirmation) && typeof onConfirmation === "function") {
				onConfirmation(e)
			}
			GlobalStore.closeConfirmationDialog()
		}

		let cancelBtnClassName = ""
		let confirmBtnClassName = ""

		if (isSafe(cancelBtn) && isNotEmpty(cancelBtn.className)) {
			cancelBtnClassName = cancelBtn.className
		}

		if (isSafe(confirmBtn) && isNotEmpty(confirmBtn.className)) {
			confirmBtnClassName = confirmBtn.className
			logger("className", confirmBtnClassName)
		}

		const dialog =
			name === GlobalStore.confirmationDialogOpened ? (
				<Dialog
					open={true}
					onEscapeKeyDown={() => handleClose()}
					maxWidth="md"
					className={`xs-confirmation-dialog ${name}`}
				>
					<DialogTitle className={isNotEmpty(type) ? `xs-title xs-title-${type}` : "xs-title"}>
						<Grid container direction="column">
							{isSafe(titleIcon) && (
								<Grid item>{titleIcon}</Grid>
							)}
							{isSafe(title) ? (
								<Grid item>
									<Typography variant="h6">{title}</Typography>
								</Grid>
							) : (
								<Grid item>
									<Typography variant="h6">
										<FormattedMessage id="Common.label.warning" />
									</Typography>
								</Grid>
							)}
							{isSafe(subTitle) && (
								<Typography variant="body2">
									<FormattedMessage id={subTitle} />
								</Typography>
							)}
						</Grid>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Typography variant="body2">{renderHTML(text)}</Typography>
					</DialogContent>
					<DialogActions className="xs-footer">
						<Grid container spacing={1} justify="flex-end">
							{hideCancelButton == false &&
								<React.Fragment>
									{isSafe(cancelBtn) && isSafe(cancelBtn.text) ? (
										<Grid item>
											<XsButton
												variant={isNotEmpty(cancelBtnClassName) ? "contained" : "text"}
												className={cancelBtnClassName}
												icon={isSafe(cancelBtn.icon) ? cancelBtn.icon : null}
												text={<FormattedMessage id={cancelBtn.text} />}
												onClick={() => handleDecline()}
											/>
										</Grid>
									) : (
										<Grid item>
											<XsButton
												variant="text"
												icon={<i className="fal fa-times" />}
												text={<FormattedMessage id="Common.btn.no" />}
												onClick={() => handleDecline()}
											/>
										</Grid>
									)}
								</React.Fragment>}
							{isSafe(confirmBtn) && isSafe(confirmBtn.text) ? (
								<Grid item>
									<XsButton
										variant={isNotEmpty(confirmBtnClassName) ? "contained" : "text"}
										className={confirmBtnClassName}
										icon={isSafe(confirmBtn.icon) ? confirmBtn.icon : null}
										text={<FormattedMessage id={confirmBtn.text} />}
										onClick={(e) => handleConfirm(e)}
									/>
								</Grid>
							) : (
								<Grid item>
									<XsButton
										variant="text"
										icon={<i className="fal fa-check" />}
										text={<FormattedMessage id="Common.btn.yes" />}
										onClick={() => handleConfirm()}
									/>
								</Grid>
							)}
						</Grid>
					</DialogActions>
				</Dialog>
			) : null

		return dialog
	}
)
