import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import {getCompanyId} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"
import api from "../../../global/actions/api"

class RegistryStore {
	@observable listData = []
	@observable isDialogOpen = false

	@action loadListData(form) {
		const formVals = isSafe(form) ? form.values() : {}
		let req = getFilters([`company_id=${getCompanyId()}`])
		if (isNotEmpty(formVals.distributor)) {
			req.filters.push({
				associated_column: "distribution_type_id",
				predicate: "=",
				values: [
					{
						id_value: formVals.distributor
					}
				]
			})
		}
		api
			.distributionReportName(req)
			.call()
			.then((response) => {
				response.rows.forEach((row, index) => {
					response.rows[index]["custom_index"] = index + 1
				})
				this.listData = response.rows
			})
	}

	@action dialogClose(form) {
		this.isDialogOpen = false
		if (isSafe(form)) {
			form.reset()
		}
	}

	@action dialogOpen() {
		this.isDialogOpen = true
	}

	@action addRegistry(form) {
		let req = {
			orgunit_id: "1",
			distribution_type_id: form.$("distribution_type").value,
			name: form.$("name").value
		}

		api
			.saveDistributionReportName(req)
			.call()
			.then(() => {
				this.dialogClose(form)
				GlobalStore.setNotificationMessage("Záznam úspešne uložený", "success")
				this.loadListData(form)
			})
	}

	@action deleteRegistry(id, form) {
		api
			.deleteDistributionReportName(id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Záznam úspešne odstránený", "success")
				this.loadListData(form)
			})
	}
}

var singleton = new RegistryStore()
export default singleton
