import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				company_id: {},
				company_name: {},
				title_before: {
					label: <FormattedMessage id="Common.label.titleBefore" />
				},
				first_name: {
					label: <FormattedMessage id="Common.label.firstname" />,
					rules: "required"
				},
				last_name: {
					label: <FormattedMessage id="Common.label.surname" />,
					rules: "required"
				},
				title_after: {
					label: <FormattedMessage id="Common.label.titleAfter" />
				},
				birth_name: {
					label: <FormattedMessage id="Common.label.birthname" />
				},
				identifier: {
					//definujeme len staticke props ako su label, placeholder, ktore sa nemenia za behu...
					label: <FormattedMessage id="Common.label.identificationNumber" />,
					rules: "required|between:9,10"
				},
				birth_date: {
					label: <FormattedMessage id="Common.label.dateOfBirth" />,
					type: "date"
				},
				gender: {
					label: <FormattedMessage id="Common.label.gender" />,
					rules: "required"
				},
				employee_category: {
					label: <FormattedMessage id="Common.label.category" />,
					rules: "required"
				},
				personal_number: {
					label: <FormattedMessage id="Common.label.personalNumber" />
				},
				card_number: {
					label: <FormattedMessage id="Common.label.cardNumber" />,
					rules: "required"
				},
				arrival_date: {
					label: <FormattedMessage id="Common.label.arrivalDate" />,
					type: "date"
				},
				departure_date: {
					label: <FormattedMessage id="Common.label.departureDate" />,
					type: "date"
				}
			}
		}
	}
}
