"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import XsTable from "../../../../../global/ui/xsTable/xsTable"

import RmeeOrgunitEmployeesStore from "./rmeeOrgunitEmployeesStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.personalNumber" />,
		width: 100,
		accessor: "personal_number",
		sortType: "basic"
	},
	{
		Header: "Meno a priezvisko",
		width: 100,
		accessor: "full_name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.mobile" />,
		width: 100,
		accessor: "mobil",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.email" />,
		width: 100,
		accessor: "email",
		sortType: "basic"
	}
]

function RmeeOrgunitEmployeesTable() {
	return (
		<XsTable
			columns={columns}
			data={RmeeOrgunitEmployeesStore.orgunitEmployeesData}
			// onRowClick={(row) => RouterStore.push(`company?id=${row.entity._id}`)}
		/>
	)
}

export default injectIntl(observer(RmeeOrgunitEmployeesTable))
