"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Box, Grid} from "@material-ui/core"

import XsButton from "../../../global/ui/xsButton/xsButton"

import ListOfBuildings from "./listOfBuildings"
import BuildingDialog from "./buildingDialog"

import BudovyBuildingStore from "./budovyBuildingStore"

function BuildingsContainer() {
	return (
		<Box p={3}>
			<Grid container alignItems="center">
				<Grid item>
					<FormattedMessage id="Common.label.listOfEnergeticsObject" />
				</Grid>
				<Grid item container xs spacing={2} justify="flex-end">
					{/* <Grid item>
						<XsButton
							icon={<i className="fal fa-file-excel fa-lg"></i>}
							variant="outlined"
							text={<FormattedMessage id="Common.label.exportToXLS" />}
							onClick={() => {
								BudovyBuildingStore.exportReports()
							}}
						/>
					</Grid> */}
					<Grid item>
						<XsButton
							icon={<i className="fal fa-plus fa-lg"></i>}
							// variant="text"
							text={<FormattedMessage id="Common.label.addObject" />}
							onClick={() => {
								BudovyBuildingStore.openBuildingDialog()
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<ListOfBuildings />
			<BuildingDialog title={<FormattedMessage id="Common.label.addEnergeticObject" />} />
		</Box>
	)
}

export default injectIntl(observer(BuildingsContainer))
