import React from "react"
import {observer} from "mobx-react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import "./xsInput.less"
import {getFieldIdentifier} from "../../helpers/actions"

@observer
export default class XsNumberInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isValid: true
		}

		this.showValue = ""
		this._isMounted = false
	}

	componentDidMount() {
		this._isMounted = true
		if (isSafe(this.props.field) && this.props.disabled !== true) {
			this.handleValidation(this.props.field)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	inputRef = undefined

	handleValidation = (field) => {
		field.validate().then(({isValid}) => {
			if (isNotEmpty(field.value) && +this.props.min > +field.value) {
				isValid = false
			}
			if (this.state.isValid !== isValid) {
				if (this._isMounted) {
					this.setState({
						isValid: isValid
					})
				}
			}
		})
	}

	handleOnChange = (val, field, onChange) => {
		//Vymazem vsetky znaky okrem cisel, bodky a ciarky nasledne sa ciarka replacuje bodkou
		if (isNotEmpty(val)) {
			const regex =
				isNotEmpty(this.props.step) && this.props.step.toString().includes(".") ? /([^0-9\.,])/g : /([^0-9])/g
			val = val.replace(regex, "")
			val = val.replace(",", ".")
		}

		//EMA-9078 Ak sa nastavi inputu step tak sa neda zadat hodnota s vacsim poctom cisel za desatinou ciarkov ako ma step
		if (this.props.step != "any") {
			let stepDecimalLength =
				isSafe(this.props.step.toString().split(".")) && isSafe(this.props.step.toString().split(".")[1])
					? this.props.step.toString().split(".")[1].length
					: 0
			let decimals = val.split(".")[1]
			if (isSafe(decimals) && decimals.length > stepDecimalLength) {
				let tmpNumber = +val
				val = tmpNumber.toFixed(stepDecimalLength).toString()
			}
		}

		field.set(val)
		field.sync(field.get("value"))
		if (isSafe(onChange) && typeof onChange === "function") {
			onChange(field.get("value"))
		}
		this.handleValidation(field)
	}

	focus = () => {
		this.inputRef.focus()
	}

	render() {
		const {
			field,
			labelStyle,
			white,
			placeholder,
			validatingText,
			inputClassName,
			submitAction,
			multiple = false,
			shrink = true,
			autoFocus = false,
			clearText,
			disabled,
			onChange,
			min,
			step,
			onBlur,
			maxLength,
			hiddenLabel
		} = this.props

		const bindedField = field.bind()

		//V inpute je vzdy zobrazena ciarka aj ked vo value je bodka
		let value = isNotEmpty(field.get("value")) ? field.get("value").toString() : ""
		this.showValue = isNotEmpty(value) ? value.replace(".", ",") : value

		const labelJSX =
			isSafe(field.get("rules")) && field.get("rules").includes("required") ? (
				<span className="xs-input-label xs-required">{bindedField.label} *</span>
			) : (
				<span className="xs-optional">{bindedField.label}</span>
			)

		let inputClassNames = inputClassName ? inputClassName : ""

		if (white) {
			inputClassNames += " xs-white"
		}

		inputClassNames = getFieldIdentifier(field, inputClassNames)

		return (
			<TextField
				error={!this.state.isValid}
				InputLabelProps={{
					style: labelStyle,
					shrink: shrink
				}}
				fullWidth
				// variant="text"
				size="small"
				autoComplete="off"
				InputProps={{
					classes: {
						root: inputClassNames
					},
					inputRef: (input) => (this.inputRef = input),
					endAdornment: isSafe(clearText) ? (
						<InputAdornment position="end" variant="filled" className={"xs-clear-text pointer"}>
							<i className="fal fa-times" onClick={() => this.handleOnChange("", field)} />
						</InputAdornment>
					) : isNotEmpty(this.props.endText) ? (
						<InputAdornment position="end" variant="filled" className={"input-end-text"}>
							{this.props.endText}
						</InputAdornment>
					) : null
				}}
				{...field.bind({
					type: "text",
					value: this.showValue,
					placeholder,
					validatingText,
					disabled,
					onChange: this.handleValidation(field),
					onBlur: () => (typeof onBlur === "function" ? onBlur(field) : null)
				})}
				//isSafe(min)? min : ""
				inputProps={{
					min: isSafe(min) ? min : null,
					step: isSafe(step) ? step : "any",
					tabIndex: isSafe(this.props.tabindex) ? this.props.tabindex : "",
					maxLength: isSafe(maxLength) ? maxLength : null,
					style: {textAlign: "right"}
				}}
				label={hiddenLabel ? "" : labelJSX}
				multiline={multiple}
				autoFocus={autoFocus || field.autoFocus}
				//Vybral som onChange z field.bind pretoze sa to odpalovalo pri onChange ale aj ked sa renderoval input
				onChange={(event) => this.handleOnChange(event.target.value, field, onChange)}
				helperText={field && field.$error ? <span className="error-text">{field.$error}</span> : validatingText}
				onKeyPress={(event) => {
					if (
						(isSafe(submitAction) && typeof submitAction === "function" && event.which === 13) ||
						event.keyCode === 13
					) {
						submitAction(event)
					}
				}}
			/>
		)
	}
}
