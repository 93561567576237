"use strict"

import React, {useEffect} from "react"
import {inject, observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container, Grid} from "@material-ui/core"

import RmeeDrawer from "../../rmeeDrawer"
import RmeeCompanyOrgunitForm from "./rmeeCompanyOrgunitForm"
import RmeeCompanyOrgunitTabs from "./rmeeCompanyOrgunitTabs"

import GlobalStore from "../../../../global/store/GlobalStore"

function RmeeCompanyOrgunitDetail(props) {
	useEffect(() => {
		GlobalStore.main_menu_selected = "rmee"
	}, [])

	const orgunit_id = new URLSearchParams(props.routing.location.search).get("id")
	const company_id = new URLSearchParams(props.routing.location.search).get("companyId")

	return (
		<Grid container className="max_height">
			<Grid item>
				<RmeeDrawer />
			</Grid>
			<Grid item xs className="max_height">
				<Container maxWidth={false} className="max_height overflow_y">
					<RmeeCompanyOrgunitForm orgunit_id={orgunit_id} company_id={company_id} />
					<RmeeCompanyOrgunitTabs orgunit_id={orgunit_id} company_id={company_id} />
				</Container>
			</Grid>
		</Grid>
	)
}

export default inject("routing")(injectIntl(observer(RmeeCompanyOrgunitDetail)))
