"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import classnames from "classnames"

import {Box, Grid} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import BudovyBuildingDetailStore from "../budovyBuildingDetailStore"
import BuildingConstructionStore from "./buildingConstructionStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function ConstructionContainer() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			BuildingConstructionStore.saveParameters(form, setEditMode)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form, setForm] = useState(undefined)
	const [editMode, setEditMode] = useState(false)

	useEffect(() => {
		BuildingConstructionStore.getParamTypes(setForm, hooks)
	}, [])

	const getFormComponent = (param) => {
		const isDisabledDesc = !editMode || isEmpty(form.$(param.parameter_fact_id).value)

		return (
			<React.Fragment>
				<Grid item xs={4} className={classnames("bold")}>
					{param.required ? <span className="red">{`${param.name}*`}</span> : param.name}
				</Grid>
				<Grid item xs={2}>
					{getComponentByType(param)}
				</Grid>
				<Grid item xs={1}>
					&nbsp;
				</Grid>
				<Grid item xs={5}>
					<XsInput
						field={form.$(`${param.parameter_fact_id}_desc`)}
						inputClassName="mt-0"
						hiddenLabel
						disabled={isDisabledDesc}
					/>
				</Grid>
			</React.Fragment>
		)
	}

	const getComponentByType = (param) => {
		const fieldName = param.parameter_fact_id
		const dataType = param.data_type

		const isDisabled = !editMode

		switch (dataType) {
			case "SIEA.DT.String":
			case "SIEA.DT.Text":
				return (
					<XsInput
						field={form.$(fieldName)}
						onChange={(val) => {
							if (isEmpty(val)) {
								form.$(`${param.parameter_fact_id}_desc`).value = ""
							}
						}}
						rootClassName="mt-0"
						inputClassName="textRight"
						hiddenLabel
						disabled={isDisabled}
					/>
				)
			case "SIEA.DT.Integer":
				return (
					<XsNumberInput
						field={form.$(fieldName)}
						onChange={(val) => {
							if (isEmpty(val)) {
								form.$(`${param.parameter_fact_id}_desc`).value = ""
							}
						}}
						step={1}
						inputClassName="mt-0"
						hiddenLabel
						disabled={isDisabled}
					/>
				)
			case "SIEA.DT.Numeric":
				return (
					<XsNumberInput
						field={form.$(fieldName)}
						onChange={(val) => {
							if (isEmpty(val)) {
								form.$(`${param.parameter_fact_id}_desc`).value = ""
							}
						}}
						step={0.01}
						inputClassName="mt-0"
						hiddenLabel
						disabled={isDisabled}
					/>
				)
		}

		if (dataType.includes("ISEE.CL.")) {
			const clName = dataType.replace("ISEE.CL.", "")
			return (
				<XsSearchSelect
					field={form.$(fieldName)}
					items={GlobalStore.CL[clName]}
					inputClassName="mt-0 textRight"
					hiddenLabel
					disabled={isDisabled}
				/>
			)
		}

		return ""
	}

	const getCategoryName = () => {
		if (BudovyBuildingDetailStore.buildingDTO && BudovyBuildingDetailStore.buildingDTO.category) {
			const cat = GlobalStore.CL["objectCategory"].find(
				(x) => x._id === BudovyBuildingDetailStore.buildingDTO.category._id
			)

			if (cat) {
				return `(${cat.name_ext})`
			}
		}

		return ""
	}

	return (
		<Box pr={4} pl={4} pt={3} pb={3}>
			<Grid container alignItems="center" className="mb-5">
				<Grid item className="title">
					Stavebno - technické údaje <span className="bold">{getCategoryName()}</span>
				</Grid>
				<Grid item xs container spacing={2} justify="flex-end">
					<Grid item>
						<XsButton
							icon={<i className="fas fa-share-square fa-lg"></i>}
							variant="outlined"
							text={<FormattedMessage id="Common.label.exportData" />}
							onClick={() => {
								logger("export udajov")
							}}
						/>
					</Grid>
					<Grid item>
						<XsButton
							icon={<i className="far fa-plus fa-lg"></i>}
							disabled={editMode}
							text={<FormattedMessage id="Common.label.editRecords" />}
							onClick={() => setEditMode(!editMode)}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid container className="greyDefault pb-1">
				<Grid item xs={4}>
					Údaj
				</Grid>
				<Grid item xs={2} className="textRight">
					Hodnota
				</Grid>
				<Grid item xs={1}>
					&nbsp;
				</Grid>
				<Grid item xs={5}>
					Poznámka k záznamu
				</Grid>
			</Grid>
			{form && BuildingConstructionStore.params && (
				<Grid container direction="column">
					{BuildingConstructionStore.params.map((param, idx) => {
						return (
							<Grid item container key={idx} alignItems="center" className={classnames({bgWhite: idx % 2 === 0})}>
								{getFormComponent(param)}
							</Grid>
						)
					})}
				</Grid>
			)}
			{editMode && (
				<Grid container justify="flex-end" spacing={2} className="mt-4">
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							color="secondary"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => {
								BuildingConstructionStore.setDefaultValue(form)
								setEditMode(false)
							}}
						/>
					</Grid>
					<Grid item>
						<XsButton
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={(e) => form.onSubmit(e)}
						/>
					</Grid>
				</Grid>
			)}
		</Box>
	)
}

export default injectIntl(observer(ConstructionContainer))
