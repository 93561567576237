import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				person_id: {},
				orgunit_id: {
					label: <FormattedMessage id="Common.label.department" />,
					rules: "string|required"
				},
				contract_percentage: {
					label: <FormattedMessage id="Common.label.contract" />,
					rules: "required"
				},
				contract_from: {
					label: <FormattedMessage id="Common.label.validityFrom" />,
					rules: "required",
					type: "date"
				},
				contract_to: {
					label: <FormattedMessage id="Common.label.validityTo" />,
					type: "date"
				}
			}
		}
	}
}
