"use strict"

import React, {useState, useEffect} from "react"
// import classnames from "classnames"
import {inject, observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import Dropzone from "react-dropzone"

import {Box, Grid, Paper, Typography /*Checkbox, Tab, Tabs*/} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsButton from "../../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"
import XsBreadcrumbs from "../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"

// import ListOfRecords from "./listOfRecords"
import RecordDialog from "./reportDialog"

import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"
import RozvodyReportStore from "./rozvodyReportStore"
// import InfoStore from "../../../global/store/InfoStore"
import ListOfDistributions from "./listOfDistributions"

function ReportContainer(props) {
	const report_id = new URLSearchParams(props.routing.location.search).get("id")
	const year = new URLSearchParams(props.routing.location.search).get("year")
	const isDisabled = RozvodyReportStore.isDisabled

	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RozvodyReportStore.saveReportNote(report_id, form)
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{fields: {note: {label: "Poznámka"}, year: {label: "Rok", value: year}, note_id: {}}},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	// const [delRegion, setDelRegion] = useState("")

	useEffect(() => {
		RozvodyReportStore.resetValues()
		// RozvodyReportStore.getAttachments(report_id)
		RozvodyReportStore.getRecords(report_id)
		RozvodyReportStore.getRecordInfo(report_id, form)

		return () => {
			RozvodyReportStore.resetValues()
		}
	}, [])

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (RozvodyReportStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			RozvodyReportStore.saveAttachmentOnDrop(file, report_id)
			// RozvodyReportStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (attachmentIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			RozvodyReportStore.dropedFiles[attachmentIndex],
			RozvodyReportStore.dropedFiles[attachmentIndex].name
		)
		GlobalStore.setNotificationMessage("Súbor bol úspešne stiahnutý", "success")
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		if (isSafe(RozvodyReportStore.dropedFiles[attachmentIndex]._id)) {
			RozvodyReportStore.deleteLoadedAttachemnt(RozvodyReportStore.dropedFiles[attachmentIndex]._id, attachmentIndex)
		} else {
			RozvodyReportStore.dropedFiles.splice(attachmentIndex, 1)
		}
	}

	// const handleChange = (event, value) => {
	// 	RozvodyReportStore.regionTabValue = value
	// }

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="oes_report_container">
			<Box pb={2}>
				<XsBreadcrumbs
					links={[
						{
							label: "ISEE",
							action: () => {
								RouterStore.push(`uvod`)
							}
						},
						{
							label: "Rozvody",
							action: () => {
								RouterStore.push(`rozvody`)
							}
						},
						{
							label: "Reporty",
							action: () => {
								RouterStore.push(`rozvody`)
							}
						}
						// {label: "Report za rok 2020", action: () => RouterStore.push(`company?id=${props.company_id}`)}
					]}
					text={`Report za rok ${year}`}
				/>
			</Box>
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>Zoznam reportov rozvodných a distribučných spoločností</Box>
				</Grid>
			</Grid>
			<Paper className="cvs_paper">
				<Box p={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item className="title">
							Report za rok {year} ({RozvodyReportStore.distributionTypeName})
						</Grid>
					</Grid>
					<Grid container spacing={2} alignItems="center" className="pt-3">
						<Grid item container xs={12} justify="flex-end" spacing={1}>
							<Grid item>
								<XsButton
									// variant="text"
									variant="outlined"
									icon={<i className="fal fa-times fa-lg"></i>}
									text="Exportovať údaje"
									onClick={() => RozvodyReportStore.exportCvsRecords(report_id)}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-plus fa-lg"></i>}
									disabled={isDisabled}
									text="Pridať záznam"
									onClick={() => RozvodyReportStore.openReportDialog()}
								/>
							</Grid>
						</Grid>
						<ListOfDistributions report_id={report_id} />
						<Grid item xs={12}>
							<Box pt={3}>Prílohy</Box>
						</Grid>
						<Grid item xs={12}>
							<Dropzone
								disabled={isDisabled}
								onDrop={(files) => {
									handleDrop(files, 5)
								}}
								id="dropzone"
								// disabled={isFormDisabled()}
								accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
							>
								{isSafe(RozvodyReportStore.dropedFiles) && RozvodyReportStore.dropedFiles.length ? (
									RozvodyReportStore.dropedFiles.map((file, index) => (
										<div key={index} className={"dropedFile"}>
											<i
												className="fal fa-file icon"
												onClick={(e) => {
													e.stopPropagation()
													handleDropzonePreview(index)
												}}
											/>
											<i
												className="far fa-trash-alt deleteIcon"
												onClick={(e) => {
													e.stopPropagation()
													// if (!isFormDisabled()) {
													if (isDisabled == false) {
														GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
															fileIndex: index
														})
													}
													// }
												}}
											/>
											<br />
											<div
												className={"mac_ellipsis"}
												data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
												data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
											/>
										</div>
									))
								) : (
									<div
										className={"dropzonePlaceholder"}
									>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
								)}
							</Dropzone>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
							</Typography>
						</Grid>
					</Grid>
					<Grid item container className="mt-2">
						<Grid item xs container alignItems="center" spacing={4}>
							<Grid item xs={8}>
								<XsInput field={form.$("note")} disabled={isDisabled} />
							</Grid>
							<Grid item xs>
								<XsButton
									icon={<i className="fal fa-paper-plane fa-lg"></i>}
									disabled={isDisabled}
									variant="outlined"
									text="Uložiť poznámku"
									onClick={() => form.submit()}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={2} justify="flex-end">
							<XsButton
								icon={<i className="fal fa-paper-plane fa-lg"></i>}
								text="Odoslať report"
								disabled={isDisabled}
								onClick={() => {
									GlobalStore.openConfirmationDialog("sendRozvodReport")
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
			<XsConfirmationDialog
				type="info"
				name="sendRozvodReport"
				title="Upraviť alebo odoslať report"
				text={`Odosielate report za subjekt:<br/>
				<b>Chcete si údaje ešte raz skontrolovať a prípadne upraviť?</b>`}
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				confirmBtn={{
					icon: <i className="fal fa-check fa-lg" />,
					text: "Common.btn.send"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.check"
				}}
				onConfirmation={() => {
					setTimeout(() => {
						RozvodyReportStore.sendReport(report_id)
					})
				}}
			/>
			<XsConfirmationDialog
				type="success"
				name="saveRozvodReportSuccessful"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				title="Ďakujeme za zaslanie reportu"
				text={`K Vami zaslanému reportu sme Vám vygenerovali potvrdenie, ktoré nájdete v schránke správ.`}
				hideCancelButton={true}
				confirmBtn={{
					icon: <i className="fal fa-check fa-lg" />,
					text: "Zavrieť"
				}}
			/>
			{/* <XsConfirmationDialog
				type="warning"
				name="removeReport"
				text="Naozaj chcete odstrániť report?"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
				}}
			/> */}
			{RozvodyReportStore.isOpenReportDialog == true && (
				<RecordDialog
					title={"Pridať záznam"}
					report_id={report_id}
					year={year}
					isUpdating={true}
					report_type={RozvodyReportStore.distributionType}
				/>
			)}
		</Box>
	)
}

export default inject("routing")(injectIntl(observer(ReportContainer)))
