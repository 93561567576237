import React, { Component } from "react"
import { Switch, Route, withRouter } from "react-router-dom"

import { inject, observer } from "mobx-react"

import XsSnackbar from "./global/ui/xsSnackbar/xsSnackbar"
import XsHeader from "./global/ui/xsHeader/xsHeader"
import XsFooter from "./global/ui/xsFooter/xsFooter"
import XsLoading from "./global/ui/xsLoading/xsLoading"

import LoginContainer from "./modules/login/loginContainer"
import ResetPasssword from "./modules/login/resetPassword"
import XsInfoDialog from "./global/ui/xsDialog/xsInfoDialog"
import XsErrorDialog from "./global/ui/xsDialog/xsErrorDialog"

import WelcomePage from "./modules/welcome/welcomePage"
import EtlContainer from "./modules/etl/etlContainer"
import RmeeContainer from "./modules/rmee/rmeeContainer"
import RmeeCompanyOrgunitDetail from "./modules/rmee/companies/orgunits/rmeeCompanyOrgunitDetail"
import RmeeCompanyDetail from "./modules/rmee/companies/rmeeCompanyDetail"
import RmeeCompanyEmployeeDetail from "./modules/rmee/companies/employees/rmeeCompanyEmployeeDetail"
import CvsContainer from "./modules/cvs/cvsContainer"
import CvsReportContainer from "./modules/cvs/reports/report/reportContainer"
import OesContainer from "./modules/oes/oesContainer"
import OesReportContainer from "./modules/oes/reports/report/reportContainer"
import RozvodyContainer from "./modules/rozvody/rozvodyContainer"
import RozvodyReportContainer from "./modules/rozvody/reports/reportContainer"
import BudovyContainer from "./modules/budovy/budovyContainer"
import BuildingContainer from "./modules/budovy/buildings/building/buildingContainer"
import RegisterContainer from "./modules/login/registerContainer"
import Register from "./modules/login/register"
import InfoStore from "./global/store/InfoStore"
import ErrorStore from "./global/store/ErrorStore"
// import RouterStore from "./global/store/RouterStore"
import GlobalStore from "./global/store/GlobalStore"
import RegistersCacheStore from "./global/store/RegistersCacheStore"

import PrototypesContainer from "./modules/componentsPrototypes/prototypesContainer"

import { getUser } from "./global/helpers/actions"

import "./global/styles/global.less"

@inject("routing")
@withRouter
@observer
export default class App extends Component {
	componentDidMount() {
		// const user = getUser()
		// if (
		// 	isEmpty(user) &&
		// 	this.props.routing.location.pathname != "/" &&
		// 	this.props.routing.location.pathname != "/components"
		// ) {
		// 	RouterStore.push("/components")
		// }
	}

	render() {
		const user = getUser()

		if (this.props.routing.location.pathname != "/" && this.props.routing.location.pathname != "/components") {
			RegistersCacheStore.checkRegisters(this.props.routing.location.pathname)
		}

		return (
			<div id="app">
				{isSafe(user) ? (
					<React.Fragment>
						{RegistersCacheStore.registersLoaded && (
							<Switch>
								<Route
									exact
									path="/"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<PrototypesContainer {...props} />
											</main>
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/components"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<PrototypesContainer {...props} />
											</main>
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/login"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<LoginContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/reset"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<ResetPasssword {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/register"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<Register {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/registerFinish"
									render={(props) => (
										<React.Fragment>
											<main className="main_container">
												<RegisterContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/uvod"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<WelcomePage {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/cvs_report"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<CvsReportContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/cvs"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<CvsContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/oes_report"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<OesReportContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/oes"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<OesContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/rozvody"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<RozvodyContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/rozvody_report"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<RozvodyReportContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/rmee"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<RmeeContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/company"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<RmeeCompanyDetail {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/companyOrgunit"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<RmeeCompanyOrgunitDetail {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/companyEmployee"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<RmeeCompanyEmployeeDetail {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/etl"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container">
												<EtlContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/budovy"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<BudovyContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
								<Route
									exact
									path="/building"
									render={(props) => (
										<React.Fragment>
											<XsHeader />
											<main className="main_container bgWhite">
												<BuildingContainer {...props} />
											</main>
											<XsFooter />
										</React.Fragment>
									)}
								/>
							</Switch>
						)}
					</React.Fragment>
				) : (
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<PrototypesContainer {...props} />
									</main>
									{/* <XsFooter /> */}
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/components"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<PrototypesContainer {...props} />
									</main>
									{/* <XsFooter /> */}
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/login"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<LoginContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/reset"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<ResetPasssword {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/register"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<Register {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/registerFinish"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<RegisterContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
					</Switch>
				)}
				<XsSnackbar />
				{GlobalStore.isLoading && <XsLoading overlay />}
				{ErrorStore.isOpen && <XsErrorDialog />}
				{InfoStore.isOpen && <XsInfoDialog />}
			</div>
		)
	}
}
