import {observable, action} from "mobx"

import {getFilters} from "../../../global/helpers/api"

import {exportToExcel, getCompanyId} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"
import RouterStore from "../../../global/store/RouterStore"

import api from "../../../global/actions/api"

class CvsReportStore {
	@observable isOpenReportDialog = false

	@observable reportsData = []

	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@observable existYears = []

	@observable graphData = []

	@action openReportDialog() {
		this.isOpenReportDialog = true
	}

	@action closeReportDialog(form) {
		form.reset()
		this.graphData = []
		this.isOpenReportDialog = false
	}

	@action getCvsReports() {
		const req = getFilters([`company_id=${getCompanyId()}`])

		api
			.getCvsReports(req)
			.call()
			.then((reports) => {
				if (isSafe(reports) && isSafe(reports.rows) && reports.rows.length) {
					let tempExist = []

					reports.rows.forEach((x) => {
						tempExist.push(x.year)
					})

					this.existYears = tempExist

					this.reportsData = reports.rows
				} else {
					this.reportsData = []
					this.existYers = []
				}
			})
	}

	@action getHistoricalData(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			medium_id: values.medium_id
		}

		api
			.getRecordHistory(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("consumption_in_unit_of_measure_history").value = res.consumption
					form.$("costs_history").value = res.costs
					form.$("unit_history").value = res.unit_id
				}
			})
	}

	@action saveReport(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			medium_id: values.medium_id,
			consumption: values.consumption_in_unit_of_measure,
			costs: values.costs,
			unit_id: values.unit_id,
			note: values.note
		}

		api
			.saveCVSReport(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Report úspešne pridaný", "success")

					if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
						let attachReq = []
						let binaryDataArr = []
						let files = this.dropedFiles
						const closeDialog = this.closeReportDialog.bind(this)

						let getData = function(index) {
							let data = files[index]
							let reader = new FileReader()

							reader.readAsBinaryString(data)
							reader.onloadend = function() {
								binaryDataArr.push(reader.result)

								attachReq.push({
									_id: isNotEmpty(data._id) ? data._id : null,
									_ref: false,
									_type: "SIEA.Data.Attachment",
									active: true,
									rel_class_name: "ISEE.Data.CVSRecord",
									rel_object_id: res.record_id,
									content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
									type: "General",
									file_name: data.name,
									size: data.size
								})

								if (files.length > index + 1) getData(index + 1)
								else {
									api
										.saveAttachments(attachReq)
										.call()
										.then(async (attachs) => {
											if (isSafe(attachs) && attachs.length) {
												let error = false

												for (let key = 0; key < Object.keys(attachs).length; key++) {
													if (isSafe(files[key])) {
														files[key]._id = attachs[key]._id
													}

													await api
														.saveAttachmentContent(attachs[key]._id, binaryDataArr[+key])
														.call()
														.then((content) => {
															if (isNotSafe(content) || (isSafe(content) && content._id)) {
																error = true
															}
														})
												}

												if (error) {
													GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
												} else {
													GlobalStore.setNotificationMessage("Prílohy úspešne uložené", "success")
													closeDialog(form)
												}
											} else {
												GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
											}
										})
								}
							}
						}
						getData(0)
					} else {
						this.closeReportDialog(form)
					}

					// this.getCvsReports()
					RouterStore.push(`/cvs_report?year=${values.year}&id=${res._id}`)
				} else {
					GlobalStore.setNotificationMessage("Pri pridávaní reportu došlo ku chybe", "error")
				}
			})
	}

	@action deleteReport(report_id) {
		api
			.deleteCVSReport(report_id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report úspešne odstránený", "success")
				this.getCvsReports()
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action getGraphData(medium_id) {
		const req = {
			company_id: getCompanyId(),
			medium_id: medium_id
		}

		api
			.energyConsumptionGraph(req)
			.call()
			.then((res) => {
				this.graphData = res
			})
	}

	@action exportReports() {
		let filters = getFilters([`company_id=${getCompanyId}`])

		let req = {
			uri: "/isee/cvs/reports",
			filters: filters.filters,
			columns: [
				{
					Header: "Rok",
					accessor: "year"
				},
				{
					Header: "Počet palív",
					accessor: "record_count"
				},
				{
					Header: "Celková spotreba [kWh]",
					accessor: "sum_consumption"
				},
				{
					Header: "Celkové náklady [EUR]",
					accessor: "sum_costs"
				},
				{
					Header: "Stav reportu",
					accessor: "status_id"
				},
				{
					Header: "Počet príloh",
					accessor: "attachment_count"
				}
			]
		}
		exportToExcel(req)
	}
}

var singleton = new CvsReportStore()
export default singleton
