"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import RmeeOrgunitParamsStore from "./rmeeOrgunitParamsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.typeOfParam" />,
		width: 200,
		accessor: "param_type",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.value" />,
		width: 200,
		accessor: "value",
		sortType: "basic"
	},
	{
		clickable: false,
		width: 50,
		accessor: "_id",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className="fal fa-trash-alt fa-lg pointer"
							onClick={() => GlobalStore.openConfirmationDialog("removeOrgunitParam", {param_id: obj.value})}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

function RmeeOrgunitParamsTable(props) {
	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={RmeeOrgunitParamsStore.orgunitParamsData}
				onRowClick={(row) => RmeeOrgunitParamsStore.openOrgunitParamDialog(props.orgunit_id, row)}
			/>
			<XsConfirmationDialog
				name="removeOrgunitParam"
				text="Naozaj chcete odstrániť parameter?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					RmeeOrgunitParamsStore.removeOrgunitParam(GlobalStore.confParams.param_id, props.orgunit_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RmeeOrgunitParamsTable))
