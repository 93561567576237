import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				report_id: {},
				year: {},
				medium_id: {
					label: <FormattedMessage id="Common.label.energonosic" />,
					rules: "required"
				},
				curr_medium_id: {},
				consumer_group_id: {
					label: "Skupina odberateľov",
					rules: "required"
				},
				region_id: {
					label: "Kraj",
					rules: "required"
				},
				curr_region_id: {},
				quantity_sold: {
					label: "Predané množstvo [MWh]",
					rules: "required|numeric"
				},
				quantity_sold_history: {},
				consumer_count: {
					label: "Počet odberateľov",
					rules: "required|integer"
				},
				consumer_count_history: {},
				note: {
					label: <FormattedMessage id="Common.label.noteEnergonosic" />
				}
			}
		}
	}
}
