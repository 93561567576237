"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./identificationFields"

import {injectIntl} from "react-intl"
import {Box, Grid} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"

import CvsIdentificationStore from "./cvsIdentificationStore"

function IdentificationContainer() {
	const hooks = {
		onSubmit() {},
		onSuccess() {},
		onError() {}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	const [disabled] = useState(true)

	useEffect(() => {
		CvsIdentificationStore.getCompanyInfo(form)

		return () => {
			form.reset()
		}
	}, [])

	return (
		<Box p={3}>
			<Grid container>
				<Grid item xs={12} md={9}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box pt={2}>Údaje o spoločnosti</Box>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsInput field={form.$("name")} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsInput field={form.$("ico")} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsSearchSelect field={form.$("subject_type")} items={[]} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsSearchSelect field={form.$("region")} items={[]} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsSearchSelect field={form.$("county")} items={[]} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsSearchSelect field={form.$("city")} items={[]} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsInput field={form.$("street")} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsInput field={form.$("number")} disabled={disabled} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsInput field={form.$("psc")} disabled={disabled} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={form.$("note")} disabled={disabled} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={3}>
					<Grid container>
						<Grid item md={1}></Grid>
						<Grid item xs={12} md={11} container spacing={2}>
							<Grid item xs={12}>
								<Box pt={2}>Primárny kontakt</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={12}>
								<XsInput field={form.$("firstname")} disabled={disabled} />
							</Grid>
							<Grid item xs={12} sm={6} md={12}>
								<XsInput field={form.$("lastname")} disabled={disabled} />
							</Grid>
							<Grid item xs={12} sm={6} md={12}>
								<XsInput field={form.$("phone")} disabled={disabled} />
							</Grid>
							<Grid item xs={12} sm={6} md={12}>
								<XsInput field={form.$("mobile")} disabled={disabled} />
							</Grid>
							<Grid item xs={12} sm={6} md={12}>
								<XsInput field={form.$("email")} disabled={disabled} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

export default injectIntl(observer(IdentificationContainer))
