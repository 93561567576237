import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"
import ErrorStore from "../../store/ErrorStore"

import "./xsDialog.less"

export default injectIntl(
	observer(() => {
		return (
			<Dialog open={ErrorStore.isOpen} onEscapeKeyDown={() => ErrorStore.close()} className="xs-error-dialog">
				<DialogTitle className="xs-title bgRedDanger snowWhite">
					<Grid container>
						{/* <div className="xs-header-icon">
							<i className="fal fa-exclamation-circle fa-2x"></i>
						</div> */}
						<Grid item>
							<Typography variant="h6">
								<FormattedMessage id="Application.errorText" />
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className="xs-content">
					{isSafe(ErrorStore.response) &&
						isSafe(ErrorStore.response.errors) &&
						ErrorStore.response.errors.length > 0 &&
						ErrorStore.response.errors.map((error, idx) => {
							return (
								<Grid container key={idx} spacing={2}>
									{ErrorStore.incorectLoginOrPassword ? (
										<Grid item>
											<Typography variant="body2">Incorrect Login or Password</Typography>
										</Grid>
									) : (
										<React.Fragment>
											<Grid item>
												<Typography variant="body2">
													{isNotEmpty(error.description)
														? error.description
														: "An error has occurred during operation. Please check your internet connection, required fields and constraints."}
												</Typography>
											</Grid>
											{/* <Grid item>
												<Typography variant="body2">
													If it doesn{"`"}t resolve this error, try to log out and log in again.
												</Typography>
											</Grid> */}
										</React.Fragment>
									)}
								</Grid>
							)
							// const customErrorMsg = error.code ? props.intl.formatMessage({id: "Application.error." + error.code}) : ""
							// if (isSafe(error.description) && isNotEmpty(error.description)) {
							// 	return (
							// 		<div key={idx}>
							// 			<div className="xs-error-description">
							// 				{customErrorMsg && <div className="translated-error">{customErrorMsg}</div>}
							// 				{error.description}
							// 			</div>
							// 			{isSafe(ErrorStore.response.summary) && isNotEmpty(ErrorStore.response.summary) && (
							// 				<div>({ErrorStore.response.summary})</div>
							// 			)}
							// 			<br />
							// 		</div>
							// 	)
							// } else {
							// 	if (isSafe(ErrorStore.response.summary) && isNotEmpty(ErrorStore.response.summary)) {
							// 		return (
							// 			<div key={idx}>
							// 				{customErrorMsg && <div className="translated-error">{customErrorMsg}</div>}(
							// 				{ErrorStore.response.summary})
							// 				<br />
							// 			</div>
							// 		)
							// 	}
							// }
						})}
					{/* {isSafe(ErrorStore.additionalInfo) && (
						<div className="greyDefault">
							<div
								onClick={() => {
									ErrorStore.showMoreInfo = !ErrorStore.showMoreInfo
								}}
								className="pointer"
							>
								Rozšírené <i className={`fal fa-chevron-${ErrorStore.showMoreInfo ? "up" : "down"}`}></i>
							</div>
							{ErrorStore.showMoreInfo && (
								<div>
									{ErrorStore.additionalInfo.path && <div>URI: {ErrorStore.additionalInfo.path}</div>}
									{ErrorStore.additionalInfo.options && <div>REQUEST: {ErrorStore.additionalInfo.options.body}</div>}
									{ErrorStore.additionalInfo.options && <div>METHOD: {ErrorStore.additionalInfo.options.method}</div>}
								</div>
							)}
						</div>
					)} */}
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						variant="text"
						icon={<i className="fal fa-times" />}
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => ErrorStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	})
)
