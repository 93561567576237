"use strict"

import React, { useState } from "react"
import { observer } from "mobx-react"
import { injectIntl, FormattedMessage } from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import { Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography } from "@material-ui/core"
import XsButton from "../../../global/ui/xsButton/xsButton"
import RegistryStore from "./registryStore"
import GlobalStore from "../../../global/store/GlobalStore"
// import XsAutocomplete from "../../../global/ui/xsInput/xsAutocomplete"
// import api from "../../../global/actions/api"
import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"

function RegistryDialog(props) {
  const hooks = {
    onSubmit() { },
    onSuccess(form) {
      RegistryStore.addRegistry(form)
    },
    onError() {
      GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
    }
  }

  const [form] = useState(
    new MobxReactForm(
      {
        fields: {
          name: { label: "Názov" },
          distribution_type: {
            label: "Činnosť"
          },
        }
      },
      { plugins: { dvr: dvr(validatorjs) }, hooks }
    ))

  return (
    <Dialog
      open={RegistryStore.isDialogOpen}
      onEscapeKeyDown={() => RegistryStore.dialogClose(form)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="dialog_header">
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h6">{props.title}</Typography>
          </Grid>
          <i
            className="fal fa-times fa-lg dialog_close_icon pointer"
            onClick={() => RegistryStore.dialogClose(form)}
          ></i>
          {/* <Grid item>
						<Typography variant="h6">
							<FormattedMessage id="Common.label.warning" />
						</Typography>
					</Grid> */}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <XsSearchSelect
              field={form.$("distribution_type")}
              items={GlobalStore.CL["distributionType"]}
            />
          </Grid>
          <Grid item xs={12}>
            <XsInput field={form.$("name")} />
            {/* <XsAutocomplete
              field={form.$("name")}
              minLengthForSearch="3"
              showTopRows={true}
              api={api.distributionReportName}
              inputRenderer={(obj) => obj.name}
              saveValue={(obj) => obj._id}
              freeText={true}
              freeTextField={form.$("custom_name")}
              postAction={() => {
                form.$("custom_name").value = ""
              }}
              onClear={() => {
                form.$("custom_name").value = ""
              }}
              addSpecialFilter={() => [
                {
                  associated_column: "company_id",
                  predicate: "=",
                  values: [
                    {
                      id_value: "1"
                    }
                  ]
                }
              ]}
            /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <XsButton
              // className={cancelBtnClassName}
              variant="text"
              icon={<i className="fal fa-times fa-lg"></i>}
              text={<FormattedMessage id="Common.label.cancel" />}
              onClick={() => RegistryStore.dialogClose(form)}
            />
          </Grid>
          <Grid item>
            <XsButton
              // className={cancelBtnClassName}
              variant="text"
              icon={<i className="fal fa-save fa-lg"></i>}
              text={<FormattedMessage id="Common.label.save" />}
              onClick={form.onSubmit}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default injectIntl(observer(RegistryDialog))
