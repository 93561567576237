"use strict"
import React, {useState} from "react"
import MobxReactForm from "mobx-react-form"
import dvr from "mobx-react-form/lib/validators/DVR"
import validatorjs from "validatorjs"
import fields from "./fields"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import {Container, Grid, Typography, Box} from "@material-ui/core"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsTable from "../../global/ui/xsTable/xsTable"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import XsSimpleTextArea from "../../global/ui/xsTextArea/xsSimpleTextArea"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsChipForm from "../../global/ui/xsChipForm/xsChipForm"
import GlobalStore from "../../global/store/GlobalStore"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import AddIcon from "@material-ui/icons/Add"
import XsLoading from "../../global/ui/xsLoading/xsLoading"
import XsRadioButtonGroup from "../../global/ui/xsRadioButtonGroup/xsRadioButtonGroup"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import api from "../../global/actions/api"

const region = [
	{
		code: "SKZZZ",
		code_ext: "SKZZZ",
		description: "Zahraničie",
		item_order: 1,
		name_ext: "Zahraničie",
		parent_code: "",
		search_column: "skzzz zahranicie",
		_id: "SKZZZ"
	},
	{
		code: "SK010",
		code_ext: "SK010",
		description: "Bratislavský kraj",
		item_order: 2,
		name_ext: "Bratislavský kraj",
		parent_code: "",
		search_column: "sk010 bratislavsky kraj",
		_id: "SK010"
	},
	{
		code: "SK021",
		code_ext: "SK021",
		description: "Trnavský kraj",
		item_order: 3,
		name_ext: "Trnavský kraj",
		parent_code: "",
		search_column: "sk021 trnavsky kraj",
		_id: "SK021"
	},
	{
		code: "SK022",
		code_ext: "SK022",
		description: "Trenčiansky kraj",
		item_order: 4,
		name_ext: "Trenčiansky kraj",
		parent_code: "",
		search_column: "sk022 trenciansky kraj",
		_id: "SK022"
	},
	{
		code: "SK023",
		code_ext: "SK023",
		description: "Nitriansky kraj",
		item_order: 5,
		name_ext: "Nitriansky kraj",
		parent_code: "",
		search_column: "sk023 nitriansky kraj",
		_id: "SK023"
	},
	{
		code: "SK031",
		code_ext: "SK031",
		description: "Žilinský kraj",
		item_order: 6,
		name_ext: "Žilinský kraj",
		parent_code: "",
		search_column: "sk031 zilinsky kraj",
		_id: "SK031"
	},
	{
		code: "SK032",
		code_ext: "SK032",
		description: "Banskobystrický kraj",
		item_order: 7,
		name_ext: "Banskobystrický kraj",
		parent_code: "",
		search_column: "sk032 banskobystricky kraj",
		_id: "SK032"
	},
	{
		code: "SK041",
		code_ext: "SK041",
		description: "Prešovský kraj",
		item_order: 8,
		name_ext: "Prešovský kraj",
		parent_code: "",
		search_column: "sk041 presovsky kraj",
		_id: "SK041"
	},
	{
		code: "SK042",
		code_ext: "SK042",
		description: "Košický kraj",
		item_order: 9,
		name_ext: "Košický kraj",
		parent_code: "",
		search_column: "sk042 kosicky kraj",
		_id: "SK042"
	}
]

const columns = [
	{
		Header: "Kód",
		width: 100,
		accessor: "code",
		sortType: "basic"
	},
	{
		Header: "Názov",
		width: 150,
		accessor: "name_ext",
		sortType: "basic"
	},
	{
		Header: "Popis",
		width: 100,
		accessor: "description",
		sortType: "basic"
	}
]

function PrototypesContainer() {
	const hooks = {
		onSubmit() {},
		onSuccess() {},
		onError() {}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const [showLoading, setShowLoading] = useState(false)

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Box mt={3} mb={1}>
					<Typography variant="h6">Input (Text)</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInput")} />
					</Grid>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInputDisabled")} disabled />
					</Grid>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInputReq")} required />
					</Grid>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInputEnd")} endText={"kWh"} />
					</Grid>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInputEndDis")} endText={"kWh"} disabled />
					</Grid>
					<Grid item xs={4}>
						<XsInput field={form.$("xsInputEndReq")} endText={"kWh"} required />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Input (Number)</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumber")} />
					</Grid>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumberDisabled")} disabled step={0.01} />
					</Grid>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumberReq")} required step={0.01} />
					</Grid>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumberEnd")} endText={"kWh"} step={0.01} />
					</Grid>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumberEndDis")} disabled endText={"kWh"} step={0.01} />
					</Grid>
					<Grid item xs={4}>
						<XsNumberInput field={form.$("xsInputNumberEndReq")} required endText={"kWh"} step={0.01} />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Search select</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<XsSearchSelect field={form.$("searchSelect")} items={region} />
					</Grid>
					<Grid item xs={4}>
						<XsSearchSelect field={form.$("searchSelectDisabled")} disabled items={region} />
					</Grid>
					<Grid item xs={4}>
						<XsSearchSelect field={form.$("searchSelectReq")} required items={region} />
					</Grid>
					<Grid item xs={4}>
						<XsSearchSelect field={form.$("searchSelectMulti")} items={region} />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Date picker</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<XsDateTimePicker field={form.$("datePicker")} />
					</Grid>
					<Grid item xs={4}>
						<XsDateTimePicker field={form.$("datePickerDisabled")} disabled />
					</Grid>
					<Grid item xs={4}>
						<XsDateTimePicker field={form.$("datePickerReq")} required />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Autocomplete</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<XsAutocomplete
							field={form.$("xsAutocomplete")}
							minLengthForSearch="3"
							api={api.autocompleteFakeData}
							inputRenderer={(obj) => obj.CodeExt + " " + obj.NameExt}
							saveValue={(obj) => obj.Code}
							// modalConfig={(textValue, clickHandler) => {
							// 	return {
							// 		columnDefs: {
							// 			code_ext: {
							// 				title: "CodeExt",
							// 				type: "string",
							// 				dbName: "CodeExt",
							// 				design: {
							// 					width: "150px",
							// 					header: {
							// 						className: "xs-autoCompleteModalColumnHeader"
							// 					}
							// 				}
							// 			},
							// 			name_ext: {
							// 				title: "NameExt",
							// 				type: "string",
							// 				dbName: "NameExt",
							// 				design: {
							// 					header: {
							// 						className: "xs-autoCompleteModalColumnHeader"
							// 					}
							// 				},
							// 				filter: {
							// 					gridWidth: 3,
							// 					defaultValue: textValue,
							// 					renderElement: "input"
							// 				},
							// 				sortable: true
							// 			}
							// 		},
							// 		options: {
							// 			// selectRow: true,
							// 			showCursor: true,
							// 			onRowClick: (dataRow) => {
							// 				clickHandler(dataRow)
							// 			}
							// 		}
							// 	}
							// }}
						/>
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">TextArea</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsTextArea field={form.$("xsTextarea")} />
					</Grid>

					<Grid item xs={6}>
						<XsTextArea field={form.$("xsTextareaDis")} disabled />
					</Grid>

					<Grid item xs={6}>
						<XsTextArea field={form.$("xsTextareaReq")} required />
					</Grid>

					<Grid item xs={6}>
						<XsTextArea field={form.$("xsTextareaHide")} hideToolbar />
					</Grid>

					<Grid item xs={6} className={"mt-2"}>
						<XsSimpleTextArea field={form.$("xsTextareaSimple")} rows={13} />
					</Grid>
					<Grid item xs={6} className={"mt-2"}>
						<XsSimpleTextArea field={form.$("xsTextareaSimpleDis")} rows={13} disabled />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Chipform</Typography>
				</Box>
				<Grid container spacing={2}>
					<XsChipForm
						configuration={{
							header: {
								chipsRenderer: () => null,
								addButtonRenderer: (clickHandler) => (
									<XsIconButton
										className="xs-default xs-outline"
										icon={<AddIcon />}
										onClick={clickHandler}
										id={"invoiceItem"}
									/>
								)
							},
							columns: [
								{
									name: "itemName",
									label: "Položka",
									width: 4,
									validationRule: "required|string",
									renderer: (field) => <XsInput field={field} required />
								},
								{
									name: "itemQuantity",
									label: "Počet",
									width: 2,
									renderer: (field) => <XsNumberInput step={1} field={field} />
								},
								{
									name: "itemUnitPrice",
									label: "Jednotka",
									width: 2,
									validationRule: "numeric",
									renderer: (field) => <XsSearchSelect field={field} items={region} />
								},
								{
									name: "itemPrice",
									label: "Cena",
									width: 2,
									validationRule: "required|numeric",
									renderer: (field) => <XsNumberInput endText={"€"} step={0.01} field={field} />
								}
							],
							options: {
								formOnly: true,
								isModalMode: true,
								showInCenter: true,
								maxWidth: true,
								onFormOpen: () => {},
								onFormClose: () => {},
								onFormSave: () => {}
							},
							data: {}
						}}
					/>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Snackbar</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item>
						<XsButton
							id="succSnackbar"
							className="xs-success"
							text="Success snackbar"
							onClick={() => GlobalStore.setNotificationMessage("Success!", "success")}
						/>
					</Grid>
					<Grid item>
						<XsButton
							id="warSnackbar"
							className="xs-warning"
							text="Warning snackbar"
							onClick={() => GlobalStore.setNotificationMessage("Warning!", "warning")}
						/>
					</Grid>
					<Grid item>
						<XsButton
							id="errorSnackbar"
							className="xs-danger"
							text="Error snackbar"
							onClick={() => GlobalStore.setNotificationMessage("Error!", "error")}
						/>
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Radio buttons</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<div className="xs-radio-buttons">
							<XsRadioButtonGroup
								name="types"
								field={form.$("xsRadioButton")}
								radioButtons={[
									{
										value: "first",
										label: "Radio button 1"
									},
									{
										value: "second",
										label: "Radio button 2",
										style: {marginTop: "13px"}
									}
								]}
							/>
						</div>
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Checkbox</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item>
						<XsCheckbox field={form.$("xsCheckbox")} />
					</Grid>
					<Grid item>
						<XsCheckbox field={form.$("xsCheckboxTrue")} />
					</Grid>
					<Grid item>
						<XsCheckbox field={form.$("xsCheckboxTrueDisabled")} disabled />
					</Grid>
					<Grid item>
						<XsCheckbox field={form.$("xsCheckboxFalseDisabled")} disabled />
					</Grid>
				</Grid>
				<Box mt={3} mb={1}>
					<Typography variant="h6">Table small</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsTable columns={columns} data={region} size="small" />
					</Grid>
				</Grid>
				<Box mt={3} mb={1}>
					<Typography variant="h6">Table colorful small</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsTable columns={columns} data={region} colorful size="small" />
					</Grid>
				</Grid>
				<Box mt={3} mb={1}>
					<Typography variant="h6">Table</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsTable columns={columns} data={region} />
					</Grid>
				</Grid>
				<Box mt={3} mb={1}>
					<Typography variant="h6">Table colorful</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsTable columns={columns} data={region} colorful />
					</Grid>
				</Grid>
				<Box mt={3} mb={1}>
					<Typography variant="h6">Loading</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsLoading overlay={false} />
					</Grid>
				</Grid>

				<Box mt={3} mb={1}>
					<Typography variant="h6">Loading fullscreen</Typography>
				</Box>
				<Grid container spacing={2}>
					<XsButton
						id="succSnackbar"
						className="xs-success"
						text={"Click me!"}
						onClick={() => {
							setShowLoading(true)
							setTimeout(() => {
								setShowLoading(false)
							}, 1000)
						}}
					/>
					<Grid item xs={12}>
						{showLoading && <XsLoading overlay={true} />}
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(PrototypesContainer))
