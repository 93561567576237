"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RmeeEmployeeContractsTable from "./rmeeEmployeeContractsTable"
import RmeeEmployeeContractDialog from "./rmeeEmployeeContractDialog"

import RmeeEmployeeContractsStore from "./rmeeEmployeeContractsStore"

function RmeeEmployeeContractsContainer(props) {
	useEffect(() => {
		RmeeEmployeeContractsStore.loadContracts(props.person_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item container xs={12} justify="flex-end">
						<Grid>
							<XsButton
								text="Pridať úväzok"
								onClick={() => RmeeEmployeeContractsStore.openEmployeeContractDialog(props.person_id)}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<RmeeEmployeeContractsTable person_id={props.person_id} />
					</Grid>
				</Grid>
			</Box>
			<RmeeEmployeeContractDialog company_id={props.company_id} />
		</Container>
	)
}

export default injectIntl(observer(RmeeEmployeeContractsContainer))
