"use strict"

import React, {useEffect} from "react"
import classnames from "classnames"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import moment from "moment"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import BuildingCertificatesStore from "./buildingCertificatesStore"
// import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const columns = [
	{
		Header: "Typ posudku / certifikátu",
		width: 100,
		accessor: "type_name_ext"
	},
	{
		Header: "Číslo / Kód / ID",
		width: 100,
		accessor: "identifier"
	},
	{
		Header: "Dátum vystavenia",
		width: 100,
		accessor: "issue_date",
		Cell: (row) => <div>{row.value ? moment(row.value).format("DD.MM.YYYY") : ""}</div>
	},
	{
		Header: "Názov",
		width: 100,
		accessor: "name"
	},
	{
		Header: () => <div className="textRight">Celková podl. plocha [m2]</div>,
		width: 100,
		accessor: "floor_area",
		Cell: (row) => (
			<Grid container alignItems="center" justify="flex-end">
				<Grid item>{row.value}</Grid>
			</Grid>
		)
	},
	{
		Header: "Poznámka",
		width: 100,
		accessor: "note"
	},
	{
		Header: () => <div className="textRight">Prílohy</div>,
		width: 70,
		accessor: "attachments_count",
		Cell: (row) => (
			<Grid container alignItems="center" justify="flex-end">
				<Grid item style={{paddingRight: "3px"}}>
					<i className="far fa-paperclip"></i>
				</Grid>
				<Grid item>{row.value}</Grid>
			</Grid>
		)
	},
	{
		clickable: false,
		width: 10,
		accessor: "_id",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className={classnames("fas fa-trash-alt fa-lg redDanger pointer")}
							onClick={() => GlobalStore.openConfirmationDialog("removeEnergeticObject", {object_id: obj.value})}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

function ListOfCertificates({building_id}) {
	useEffect(() => {
		BuildingCertificatesStore.getEnergeticObjects(building_id)
	}, [])

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={BuildingCertificatesStore.energeticObjectData}
				colorful
				size="small"
				onRowClick={(row) => BuildingCertificatesStore.openCertificateDialog(row._id)}
			/>
			<XsConfirmationDialog
				type="danger"
				name="removeEnergeticObject"
				text="Naozaj chcete odstrániť objekt?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					BuildingCertificatesStore.removeEnergeticObject(GlobalStore.confParams.object_id, building_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(ListOfCertificates))
