"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Box, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import ListOfCertificates from "./listOfCertificates"
import CertificateDialog from "./certificateDialog"

import BuildingCertificatesStore from "./buildingCertificatesStore"

function CertificatesContainer({building_id}) {
	return (
		<Box pr={4} pl={4} pt={3} pb={3}>
			<Grid container alignItems="center" className="mb-5">
				<Grid item className="title">
					Posudky / certifikáty
				</Grid>
				<Grid item xs container spacing={2} justify="flex-end">
					<Grid item>
						<XsButton
							icon={<i className="fas fa-share-square fa-lg"></i>}
							variant="outlined"
							text={<FormattedMessage id="Common.label.exportData" />}
							onClick={() => {
								logger("export udajov")
							}}
						/>
					</Grid>
					<Grid item>
						<XsButton
							icon={<i className="far fa-plus fa-lg"></i>}
							text="Pridať posudok"
							onClick={() => {
								BuildingCertificatesStore.openCertificateDialog()
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<ListOfCertificates building_id={building_id} />
			{BuildingCertificatesStore.isOpenCertificateDialog && <CertificateDialog building_id={building_id} />}
		</Box>
	)
}

export default injectIntl(observer(CertificatesContainer))
