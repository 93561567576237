import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				orgunit_id: {},
				type: {
					label: <FormattedMessage id="Common.label.type" />,
					rules: "string"
				},
				mobile: {
					label: <FormattedMessage id="Common.label.mobile" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "string"
				},
				phone: {
					label: <FormattedMessage id="Common.label.phone" />,
					placeholder: "+421999000000",
					rules: "string"
				},
				web_page: {
					label: <FormattedMessage id="Common.label.web" />,
					placeholder: "https://google.sk"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />,
					rules: "string"
				},
				primary: {
					type: "checkbox",
					label: <FormattedMessage id="Common.label.primary" />,
					value: false
				}
			}
		}
	}
}
