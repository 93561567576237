import {observable, action} from "mobx"

import {getFilters} from "../../../../global/helpers/api"

import GlobalStore from "../../../../global/store/GlobalStore"

import api from "../../../../global/actions/api"
import {exportToExcel, isReportDisabled, getCompanyId} from "../../../../global/helpers/actions"

class OesReportRecordStore {
	@observable isOpenRecordDialog = false

	@observable recordsData = {}

	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@observable checkedRegion = []
	@observable regionTabValue = ""

	formRef = null

	@action openRecordDialog(report_id, year) {
		this.isOpenRecordDialog = true
		this.formRef.$("report_id").value = report_id
		this.formRef.$("year").value = year
	}

	@action closeRecordDialog() {
		this.isOpenRecordDialog = false
		this.formRef.reset()
	}

	@action getAttachments(report_id) {
		api
			.getAttachments("ISEE.Data.OESReport", report_id)
			.call()
			.then((attachments) => {
				if (isSafe(attachments) && attachments.length) {
					attachments.forEach(async (att) => {
						let res = await api.getAttachmentContent(att.api_url).call()
						res.blob().then((blob) => {
							let tmp = new File([blob], att.file_name, {
								type: att.content_type
							})

							tmp._id = att._id

							tmp.api_url = att.api_url
							this.dropedFiles.push(tmp)
						})
					})
				}
			})
	}

	@observable existMediumInRegion = {}

	@action getRecords(report_id, region_id) {
		const req = getFilters([`report_id=${report_id}`, `company_id=${getCompanyId()}`])

		api
			.getOESRecordsRegion(req)
			.call()
			.then((regionRecords) => {
				let tempExist = {} //kvoli duplicitam energonosicov v regionoch

				if (isSafe(regionRecords) && isSafe(regionRecords.rows) && regionRecords.rows.length) {
					let temp = {}
					let checkedReg = []

					regionRecords.rows.forEach((rec) => {
						if (rec.region_id in temp) {
							temp[rec.region_id].push(rec)

							if (rec.medium_id in tempExist[rec.region_id]) {
								tempExist[rec.region_id][rec.medium_id].push(rec.consumer_group_id)
							} else {
								tempExist[rec.region_id][rec.medium_id] = []
								tempExist[rec.region_id][rec.medium_id].push(rec.consumer_group_id)
							}
						} else {
							temp[rec.region_id] = []
							checkedReg.push(rec.region_id)
							temp[rec.region_id].push(rec)

							tempExist[rec.region_id] = {}
							tempExist[rec.region_id][rec.medium_id] = []
							tempExist[rec.region_id][rec.medium_id].push(rec.consumer_group_id)
						}
					})
					this.regionTabValue = region_id ? region_id : Object.keys(temp)[0]
					this.checkedRegion = checkedReg
					this.recordsData = temp
				} else {
					this.recordsData = {}
					this.checkedRegion = []
				}

				this.existMediumInRegion = tempExist
			})
	}

	@action exportCvsRecords(report_id) {
		GlobalStore.loading(true, "Exportujem...")
		let filters = getFilters([`report_id=${report_id}`])

		let req = {
			uri: "/isee/oes/records",
			filters: filters.filters,
			columns: [
				{
					Header: "Rok reportu",
					accessor: "year"
				},
				{
					Header: "Region",
					accessor: "region_name_ext"
				},
				{
					Header: "Energonosič",
					accessor: "medium_name_ext"
				},
				{
					Header: "Skupina odberateľov",
					accessor: "consumer_group_name_ext"
				},
				{
					Header: "Predané množstvo [MWh]",
					accessor: "quantity_sold"
				},
				{
					Header: "Počet odberateľov",
					accessor: "consumer_count"
				},
				{
					Header: "Stav reportu",
					accessor: "report_status_name_ext"
				},
				{
					Header: "Poznámka",
					accessor: "note"
				}
			]
		}
		exportToExcel(req)
	}

	@action getHistoricalData(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			region_id: values.region_id,
			medium_id: values.medium_id,
			consumer_group_id: values.consumer_group_id
		}

		api
			.getOESRecordHistory(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("quantity_sold_history").value = res.quantity_sold
					form.$("consumer_count_history").value = res.consumer_count
				}
			})
	}

	@action getRecordById(record_id, year) {
		api
			.getOESRecordById(record_id)
			.call()
			.then((record) => {
				if (isSafe(record) && isNotEmpty(record.record_id)) {
					this.openRecordDialog(record.report_id, year)

					this.formRef.$("_id").value = record.record_id
					this.formRef.$("medium_id").value = record.medium_id
					this.formRef.$("curr_medium_id").value = record.medium_id
					this.formRef.$("consumer_group_id").value = record.consumer_group_id
					this.formRef.$("region_id").value = record.region_id
					this.formRef.$("curr_region_id").value = record.region_id
					this.formRef.$("quantity_sold").value = record.quantity_sold
					this.formRef.$("consumer_count").value = record.consumer_count
					this.formRef.$("note").value = record.note

					const req = {
						company_id: getCompanyId(),
						year: year,
						region_id: record.region_id,
						medium_id: record.medium_id,
						consumer_group_id: record.consumer_group_id
					}

					api
						.getOESRecordHistory(req)
						.call()
						.then((res) => {
							if (isSafe(res)) {
								this.formRef.$("quantity_sold_history").value = res.quantity_sold
								this.formRef.$("consumer_count_history").value = res.consumer_count
							}
						})
				}
			})
	}

	@action saveRecord(form) {
		const values = form.values()

		const req = {
			report_id: values.report_id,
			medium_id: values.medium_id,
			consumer_group_id: values.consumer_group_id,
			region_id: values.region_id,
			quantity_sold: values.quantity_sold,
			consumer_count: values.consumer_count,
			note: values.note
		}

		if (isNotEmpty(values._id)) {
			req["record_id"] = values._id
		}

		api
			.saveOESRecord(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					if (isNotEmpty(req.record_id)) {
						GlobalStore.setNotificationMessage("Energonosič úspešne upravený", "success")
					} else {
						GlobalStore.setNotificationMessage("Energonosič úspešne pridaný", "success")
					}

					this.getRecords(values.report_id, values.region_id)
					this.closeRecordDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri pridávaní energonosiča došlo ku chybe", "error")
				}
			})
	}

	@observable isDisabled = false

	@action getReportById(reportId, form) {
		api
			.getEOSReportById(reportId)
			.call()
			.then((res) => {
				if (res && res.status_id) {
					this.isDisabled = isReportDisabled(res.status_id)
				} else {
					this.isDisabled = false
				}

				form.$("note").value = res.note
				form.$("note_id").value = res._id
			})
	}

	@action saveEOSReportNote(form) {
		const values = form.values()

		const req = {
			_id: values.note_id,
			note: values.note
		}

		api
			.saveEOSReportNote(req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Poznámka úspešne upravená", "success")
			})
	}

	@action removeRegionRecords(report_id, region_id) {
		const req = {
			report_id: report_id,
			region_id: region_id
		}

		api
			.deleteOESRegionRecords(req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Región úspešne odstránený", "success")
				this.getRecords(report_id)
			})
	}

	@action deleteRecord(report_id, record_id, region_id) {
		logger("region_id", region_id)
		api
			.deleteOESRecord(record_id, this.regionTabValue)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Záznam úspešne odstránený", "success")
				this.getRecords(report_id, region_id)
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	@action saveAttachmentOnDrop(file, report_id) {
		let data = file
		let reader = new FileReader()

		let attachReq = []
		let binaryDataArr = []

		const setIdAndPush = (id) => {
			file._id = id
			this.dropedFiles.push(file)
		}

		reader.readAsBinaryString(data)
		reader.onloadend = function() {
			binaryDataArr.push(reader.result)

			attachReq.push({
				_id: isNotEmpty(data._id) ? data._id : null,
				_ref: false,
				_type: "SIEA.Data.Attachment",
				active: true,
				rel_class_name: "ISEE.Data.OESReport",
				rel_object_id: report_id,
				content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
				type: "General",
				file_name: data.name,
				size: data.size
			})

			api
				.saveAttachments(attachReq)
				.call()
				.then(async (attachs) => {
					if (isSafe(attachs) && attachs.length) {
						let error = false
						let attachId = attachs[0]._id

						await api
							.saveAttachmentContent(attachs[0]._id, binaryDataArr[0])
							.call()
							.then((content) => {
								if (isNotSafe(content) || (isSafe(content) && content._id)) {
									error = true
								}
							})

						if (error) {
							GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
						} else {
							if (isNotEmpty(attachId)) {
								setIdAndPush(attachId)
								GlobalStore.setNotificationMessage("Prílohy úspešne uložené", "success")
							}
						}
					} else {
						GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
					}
				})
		}
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachments(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action sendReport(reportId) {
		const req = {
			_id: reportId
		}

		api
			.sendOesReport(reportId, req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report odoslany", "success")
				GlobalStore.openConfirmationDialog("saveOesReportSuccessful")
			})
	}
}

var singleton = new OesReportRecordStore()
export default singleton
