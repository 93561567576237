import {observable, action} from "mobx"

import api from "../../../../global/actions/api"

import RmeeEmployeeCardStore from "./card/rmeeEmployeeCardStore"
import GlobalStore from "../../../../global/store/GlobalStore"

class RmeeCompanyEmployeesStore {
	@observable companyEmployeesData = []

	@observable isOpenCompanyEmployeeDialog = false

	@observable tabValue = "card"

	@action loadCompanyEmployees(company_id) {
		api
			.loadCompanyEmployees(company_id)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.companyEmployeesData = res.rows
				} else {
					this.companyEmployeesData = []
				}
			})
	}

	@action loadEmployee(person_id, company_id, form) {
		api
			.loadEntity(person_id)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					if (isSafe(form)) {
						form.$("_id").value = res._id
						form.$("identifier").value = res.identifier
						form.$("first_name").value = res.first_name
						form.$("last_name").value = res.last_name
						form.$("birth_name").value = res.birth_name
						form.$("birth_date").value = res.birth_date
						form.$("title_before").value =
							isSafe(res.title_before) && isSafe(res.title_before._id) ? res.title_before._id : null
						form.$("title_after").value =
							isSafe(res.title_after) && isSafe(res.title_after._id) ? res.title_after._id : null
						form.$("gender").value = isSafe(res.gender) && isSafe(res.gender._id) ? res.gender._id : null
					}

					if (isSafe(RmeeEmployeeCardStore.employeeCardFormRef)) {
						RmeeEmployeeCardStore.employeeCardFormRef.$("_id").value = res._id
						RmeeEmployeeCardStore.employeeCardFormRef.$("company_id").value = company_id

						if (isSafe(res.specific_rels) && res.specific_rels.length) {
							const specrel = res.specific_rels.find((x) => x._type == "Entity.Data.Employee")

							if (specrel) {
								if (isSafe(form)) {
									form.$("company_name").value = isSafe(specrel.company) ? specrel.company.name : ""
								}

								RmeeEmployeeCardStore.employeeCardFormRef.$("employee_id").value = isSafe(specrel._id)
									? specrel._id
									: null
								RmeeEmployeeCardStore.employeeCardFormRef.$("personal_number").value = isSafe(specrel.personal_id)
									? specrel.personal_id
									: null
								RmeeEmployeeCardStore.employeeCardFormRef.$("employee_category").value =
									isSafe(specrel.category) && isSafe(specrel.category._id) ? specrel.category._id : null
								RmeeEmployeeCardStore.employeeCardFormRef.$("arrival_date").value =
									isSafe(specrel.validity) && isSafe(specrel.validity.from) ? specrel.validity.from : null
								RmeeEmployeeCardStore.employeeCardFormRef.$("departure_date").value =
									isSafe(specrel.validity) && isSafe(specrel.validity.to) ? specrel.validity.to : null

								if (isSafe(specrel.identifiers) && specrel.identifiers.length) {
									const ident = specrel.identifiers[0]

									RmeeEmployeeCardStore.employeeCardFormRef.$("card_number").value = isSafe(ident.value)
										? ident.value
										: null
								}
							}
						}
					}
				}
			})

		if (isSafe(form)) {
			form.$("company_id").value = company_id
		}
	}

	@action openCompanyEmployeeDialog() {
		this.isOpenCompanyEmployeeDialog = true
	}

	@action closeCompanyEmployeeDialog(form) {
		this.isOpenCompanyEmployeeDialog = false

		if (isSafe(form)) {
			form.reset()
		}
	}

	@action saveCompanyEmployee(form, setDisabled) {
		const values = form.values()

		const req = {
			_type: "Entity.Data.Person",
			_id: isNotEmpty(values._id) ? values._id : "",
			title_before: {
				_ref: true,
				_type: "Entity.CL.Title",
				_id: isEmpty(values.title_before) ? null : values.title_before
			},
			first_name: values.first_name,
			last_name: values.last_name,
			title_after: {_ref: true, _type: "Entity.CL.Title", _id: isEmpty(values.title_after) ? null : values.title_after},
			birth_name: values.birth_name,
			identifier: values.identifier,
			birth_date: values.birth_date,
			gender: {_ref: true, _type: "Entity.CL.Gender", _id: isEmpty(values.gender) ? null : values.gender}
		}

		if (isEmpty(values._id)) {
			req["specific_rels"] = [
				{
					_type: "Entity.Data.Employee",
					_ref: false,
					active: true,
					personal_id: values.personal_number,
					validity: {_ref: false, from: values.arrival_date, to: values.departure_date},
					category: {_type: "Entity.CL.EmployeeCategory", _ref: true, _id: values.employee_category},
					identifiers: [
						{
							_type: "SIEA.Data.Object.Identifier",
							type: "PRIMARY",
							system: "SIEA",
							_ref: false,
							active: true,
							value: values.card_number
						}
					],
					company: values.company_id
				}
			]
		}

		GlobalStore.loading(true)

		api
			.saveEntity(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					if (isEmpty(req._id)) {
						this.loadCompanyEmployees(values.company_id)
						this.closeCompanyEmployeeDialog(form)
					} else {
						setDisabled(true)
					}

					GlobalStore.setNotificationMessage("Zamestnanec úspešne uložený", "success")
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní zamestnanca došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeCompanyEmployeesStore()
export default singleton
