"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RmeeOrgunitBanksTable from "./rmeeOrgunitBanksTable"
import RmeeOrgunitBankDialog from "./rmeeOrgunitBankDialog"

import RmeeOrgunitBanksStore from "./rmeeOrgunitBanksStore"

function RmeeOrgunitBanksContainer(props) {
	useEffect(() => {
		RmeeOrgunitBanksStore.loadBankAccounts(props.orgunit_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item container xs={12} justify="flex-end">
						<Grid>
							<XsButton
								text="Pridať bankový účet"
								onClick={() => RmeeOrgunitBanksStore.openOrgunitBankDialog(props.orgunit_id)}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<RmeeOrgunitBanksTable orgunit_id={props.orgunit_id} />
					</Grid>
				</Grid>
			</Box>
			<RmeeOrgunitBankDialog />
		</Container>
	)
}

export default injectIntl(observer(RmeeOrgunitBanksContainer))
