"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Grid} from "@material-ui/core"

import EtlDrawer from "./etlDrawer"
import EtlStateContainer from "./state/etlStateContainer"
import EtlAimActivityContainer from "./aimActivity/etlAimActivityContainer"
import EtlProjectsContainer from "./projects/etlProjectsContainer"
import EtlExpendituresContainer from "./expenditures/etlExpendituresContainer"
import EtlMeasurableIndicatorsContainer from "./measurableIndicators/etlMeasurableIndicatorsContainer"

import EtlStore from "./etlStore"
import GlobalStore from "../../global/store/GlobalStore"

function EtlContainer() {
	useEffect(() => {
		GlobalStore.main_menu_selected = "etl"
	}, [])

	return (
		<Grid container className="max_height" wrap="nowrap">
			<Grid item>
				<EtlDrawer />
			</Grid>
			<Grid item xs className="max_height">
				{EtlStore.etlTabId == "state" && <EtlStateContainer />}
				{EtlStore.etlTabId == "aim" && <EtlAimActivityContainer />}
				{EtlStore.etlTabId == "projects" && <EtlProjectsContainer />}
				{EtlStore.etlTabId == "expenditures" && <EtlExpendituresContainer />}
				{EtlStore.etlTabId == "indicators" && <EtlMeasurableIndicatorsContainer />}
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(EtlContainer))
