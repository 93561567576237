"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import RmeeEmployeeCardForm from "./rmeeEmployeeCardForm"

function RmeeEmployeeCardContainer(props) {
	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item xs={12}>
						<RmeeEmployeeCardForm person_id={props.person_id} company_id={props.company_id} />
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(RmeeEmployeeCardContainer))
