"use strict"

import React from "react"
import { observer } from "mobx-react"
import { injectIntl } from "react-intl"
import { Container, Grid, Typography } from "@material-ui/core"
import ForgotPassword from "./forgotPassword"
// import LoginStore from "./loginStore"
import RouterStore from "../../global/store/RouterStore"

function ResetPasssword() {
  return (
    <React.Fragment>
      <div className="base_header">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
            <Grid item>
              <img
                src="/public/images/logo_rmee.png"
                className="pointer"
                onClick={() => (RouterStore.push("/login"))}
              />
            </Grid>
            <Grid item className="header_gradient">
              <Typography variant="h3">Reset password</Typography>
            </Grid>
            <Grid item style={{ width: 160 }}></Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid
            item
            style={{
              maxWidth: "400px",
              width: "400px"
            }}
          >
            <Grid container direction="column" justify="center" style={{ height: "65vh" }}>
              <ForgotPassword />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default injectIntl(observer(ResetPasssword))
