"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RmeeOrgunitParamsTable from "./rmeeOrgunitParamsTable"
import RmeeOrgunitParamDialog from "./rmeeOrgunitParamDialog"

import RmeeOrgunitParamsStore from "./rmeeOrgunitParamsStore"

function RmeeOrgunitParamsContainer(props) {
	useEffect(() => {
		RmeeOrgunitParamsStore.loadParams(props.orgunit_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid item container xs={12} justify="flex-end">
					<Grid>
						<XsButton
							text="Pridať parameter"
							onClick={() => RmeeOrgunitParamsStore.openOrgunitParamDialog(props.orgunit_id)}
							icon={<i className="fal fa-plus"></i>}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<RmeeOrgunitParamsTable orgunit_id={props.orgunit_id} />
					</Grid>
				</Grid>
			</Box>
			<RmeeOrgunitParamDialog />
		</Container>
	)
}

export default injectIntl(observer(RmeeOrgunitParamsContainer))
