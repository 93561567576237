"use strict"

import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import { injectIntl, FormattedMessage } from "react-intl"

import { Box, Grid, Paper } from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsBreadcrumbs from "../../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import ListOfFuel from "./listOfFuel"
import RecordDialog from "./recordDialog"

import RouterStore from "../../../../global/store/RouterStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import CvsReportRecordStore from "./cvsReportRecordStore"

function ReportContainer(props) {
	const report_id = new URLSearchParams(props.routing.location.search).get("id")
	const year = new URLSearchParams(props.routing.location.search).get("year")

	useEffect(() => {
		CvsReportRecordStore.getReportById(report_id)
	}, [])

	const isDisabled = CvsReportRecordStore.isDisabled

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="cvs_report_container">
			<Box pb={2}>
				<XsBreadcrumbs
					links={[
						{
							label: "ISEE",
							action: () => {
								RouterStore.push(`uvod`)
							}
						},
						{
							label: "CVS",
							action: () => {
								RouterStore.push(`cvs`)
							}
						},
						{
							label: "Reporty",
							action: () => {
								RouterStore.push(`cvs`)
							}
						}
						// {label: "Report za rok 2020", action: () => RouterStore.push(`company?id=${props.company_id}`)}
					]}
					text={`Report za rok ${year}`}
				/>
			</Box>
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>
						Výkaz o <span className="bold">celkovej vlastnej spotrebe</span> (CVS) podľa jednotlivých energonosičov
						(palív)
					</Box>
				</Grid>
			</Grid>
			<Paper className="cvs_paper">
				<Box p={3}>
					<Grid container alignItems="center">
						<Grid item className="title">
							Report za rok {year}
						</Grid>
						<Grid item container justify="flex-end" spacing={2}>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-file-excel fa-lg"></i>}
									text={<FormattedMessage id="Common.label.exportToXLS" />}
									onClick={() => {
										CvsReportRecordStore.exportCvsRecords(report_id)
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-plus fa-lg"></i>}
									disabled={isDisabled}
									text={<FormattedMessage id="Common.label.addEnergonosic" />}
									onClick={() => {
										CvsReportRecordStore.openRecordDialog(report_id, year)
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<ListOfFuel report_id={report_id} year={year} disabled={isDisabled} />
					<Grid container justify="flex-end" className="mt-2">
						<Grid item>
							<XsButton
								icon={<i className="fal fa-share fa-lg"></i>}
								disabled={isDisabled}
								text={<FormattedMessage id="Common.label.checkAndSentReport" />}
								onClick={() => {
									GlobalStore.openConfirmationDialog("sendCVSReport")
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<RecordDialog isDisabled={isDisabled} />
			<XsConfirmationDialog
				type="info"
				name="sendCVSReport"
				text={`Odosielate report za subjekt:<br/>
				<b>Chcete si údaje ešte raz skontrolovať a prípadne upraviť?</b>`}
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				confirmBtn={{
					className: "bgBlueLight",
					text: "Odoslať"
				}}
				cancelBtn={{
					text: "Skontrolovať"
				}}
				onConfirmation={() => {
					CvsReportRecordStore.sendReport(report_id)
				}}
			/>
			<XsConfirmationDialog
				type="success"
				name="sendCVSReportSuccess"
				title="Ďakujeme za zaslanie reportu"
				text={`K Vami zaslanému reportu sme Vám vygenerovali potvrdenie, ktoré nájdete v schránke správ.`}
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				hideCancelButton
				confirmBtn={{
					text: "Zavrieť"
				}}
				onConfirmation={() => {
					CvsReportRecordStore.sendReport(report_id)
				}}
			/>
		</Box>
	)
}

export default inject("routing")(injectIntl(observer(ReportContainer)))
