"use strict"

import React from "react"
import {observer} from "mobx-react"
import moment from "moment"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box, Typography} from "@material-ui/core"
import XsServerTable from "../../../global/ui/xsTable/xsServerTable"

import api from "../../../global/actions/api"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.nameOfSubject" />,
		width: 100,
		accessor: "subjekt_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.ico" />:
						</span>{" "}
						{isNotEmpty(row.subjekt_ico) ? row.subjekt_ico : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.subjekt_nazov) ? row.subjekt_nazov : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: <FormattedMessage id="Common.label.goal" />,
		width: 100,
		accessor: "konkretnyCiel_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.konkretnyCiel_kod) ? row.konkretnyCiel_kod : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.konkretnyCiel_nazov) ? row.konkretnyCiel_nazov : ""}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: <FormattedMessage id="Common.label.activity" />,
		width: 100,
		accessor: "typAktivity_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.typAktivity_kod) ? row.typAktivity_kod : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.date" />:
						</span>{" "}
						{isNotEmpty(row.typAktivity_updatedAt) ? row.typAktivity_updatedAt : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.typAktivity_nazov) ? row.typAktivity_nazov : ""}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: <FormattedMessage id="Common.label.challengeName" />,
		width: 100,
		accessor: "vyzva_nazov",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.challengeDetail" />,
		width: 100,
		accessor: "vyzva_stav",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_kod) ? row.vyzva_kod : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.kindOf" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_druh) ? row.vyzva_druh : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_stav) ? row.vyzva_stav : ""}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: <FormattedMessage id="Common.label.challengeDates" />,
		width: 100,
		accessor: "vyzva_createdAt",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.vyzva_createdAt) ? rowA.original.vyzva_createdAt : ""
			const send_date_time_B = isNotEmpty(rowB.original.vyzva_createdAt) ? rowB.original.vyzva_createdAt : ""
			if (send_date_time_A > send_date_time_B) return -1
			if (send_date_time_B > send_date_time_A) return 1
			return 0
		},
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.creations" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_createdAt) ? moment(row.vyzva_createdAt).format("DD.MM.YYYY") : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.closures" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_datumUzavretia) ? moment(row.vyzva_datumUzavretia).format("DD.MM.YYYY") : ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.statements" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_datumVyhlasenia) ? moment(row.vyzva_datumVyhlasenia).format("DD.MM.YYYY") : ""}
					</Grid>
				</Grid>
			)
		}
	}
]

function AimActivityContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y">
			<Grid container>
				<Grid item xs={12}>
					<Box pt={2} pb={2}>
						<Typography variant="h5">Výzva cieľ aktivita</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<XsServerTable api={api.loadAimActivity} columns={columns} />
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(AimActivityContainer))
