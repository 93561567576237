"use strict"

import React, { useEffect } from "react"
import classnames from "classnames"
import { observer } from "mobx-react"

import { injectIntl } from "react-intl"
import { Box, Paper, Grid, Tab, Tabs } from "@material-ui/core"

import ReportsContainer from "./reports/reportsContainer"
import RegistryContainer from "./registry/registryContainer"

import RozvodyStore from "./rozvodyStore"
import GlobalStore from "../../global/store/GlobalStore"
import IdentificationContainer from "./identification/identificationContainer"

import "./rozvody.less"

function RozvodyContainer() {
	useEffect(() => {
		GlobalStore.main_menu_selected = "rozvody"
	}, [])

	const handleChange = (event, value) => {
		RozvodyStore.rozvodyTabValue = value
	}

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="rozvody_container">
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>
						Zoznam reportov rozvodných a distribučných spoločností
					</Box>
				</Grid>
			</Grid>
			<Paper className="rozvody_tabs">
				<Tabs
					value={RozvodyStore.rozvodyTabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						className={classnames({ "xs-active": RozvodyStore.rozvodyTabValue == "baseInfo" })}
						value="baseInfo"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-friends fa-lg"></i>
								</Grid>
								<Grid item>Identifikačné údaje</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({ "xs-active": RozvodyStore.rozvodyTabValue == "reports" })}
						value="reports"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Reporty</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({ "xs-active": RozvodyStore.rozvodyTabValue == "registry" })}
						value="registry"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-cabinet-filing fa-lg"></i>
								</Grid>
								<Grid item>Register rozvodov</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({ "xs-active": RozvodyStore.rozvodyTabValue == "dashboard" })}
						value="dashboard"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Dashboard / Grafy</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{RozvodyStore.rozvodyTabValue == "baseInfo" && <IdentificationContainer />}
				{RozvodyStore.rozvodyTabValue == "reports" && <ReportsContainer />}
				{RozvodyStore.rozvodyTabValue == "registry" && <RegistryContainer />}
				{RozvodyStore.rozvodyTabValue == "dashboard" && <div>dashboard</div>}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(RozvodyContainer))
