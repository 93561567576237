import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				year: {
					label: <FormattedMessage id="Common.label.year" />,
					rules: "required"
				},
				medium_id: {
					label: <FormattedMessage id="Common.label.energonosic" />,
					rules: "required"
				},
				unit_id: {
					label: <FormattedMessage id="Common.label.unitOfMeasure" />,
					rules: "required"
				},
				unit_history: {},
				consumption_in_unit_of_measure: {
					label: <FormattedMessage id="Common.label.consumptionInUnitOfMeasure" />,
					rules: "required|numeric"
				},
				consumption_in_unit_of_measure_history: {},
				costs: {
					label: <FormattedMessage id="Common.label.yourCostsEur" />,
					rules: "required|numeric"
				},
				costs_history: {},
				calculated_price: {
					label: <FormattedMessage id="Common.label.calculatedSpecificPrice" />,
					default: 0
				},
				limited_price: {
					label: <FormattedMessage id="Common.label.limitedSpecificPrice" />
				},
				limit_from: {},
				limit_to: {},
				inLimit: {
					type: "checkbox",
					value: false
				},
				note: {
					label: <FormattedMessage id="Common.label.noteEnergonosic" />
				}
			}
		}
	}
}
