import { FormattedMessage } from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				year: {
					label: <FormattedMessage id="Common.label.year" />,
					rules: "required"
				},
				distribution_type: {
					label: "Činnosť",
					rules: "required"
				},
				heating_medium_id: {
					label: "Teplonosná látka"
				},
				name: {
					label: "Názov"
				},
				custom_name: {},
				transmitted_electricity_amount: {
					label: "Množstvo distribuovanej elektriny v [MWh]"
				},
				transmission_losses: {
					label: "Straty elektriny pri distribúcii [MWh]"
				},
				energy_consumption: {
					label: "Vlastná spotreba elektriny pri prenose [MWh]"
				},
				fuel_transmitted_amount: {
					label: "Množstvo prepravenych pohonnych latok [lit]"
				},
				transmitted_gas_amount: {
					label: "Množstvo preneseného plynu [MWh]"
				},
				other_electricity_consumption: {
					label: "Ostatná spotreba elektriny [MWh]"
				},
				consumption: {
					label: "Spotreba elektriny [MWh]"
				},
				consumptionPPS: {
					label: "Množstvo spotrebovanej energie na verejný vodovod [MWh]"
				},
				oil_transmitted_amount: {
					label: "Množstvo odovzdanej ropy [ton]"
				},
				sold_water_amount: {
					label: "Množstvo predanej vody z verejneho vodovodu [tis.m3]"
				},
				sewerage_amount: {
					label: "Odkanalizovaný objem [tis.m3]"
				},
				input_heat_amount: {
					label: "Množstvo tepla dodaného do verejného rozvodu [MWh]",
					rules: "required|numeric"
				},
				input_heat_amount_history: {},
				output_heat_amount: {
					label: "Množstvo tepla na výstupe z verejného rozvodu [MWh]",
					rules: "required|numeric"
				},
				output_heat_amount_history: {},
				energy_consumed: {
					label: "Množstvo spotrebovanej energie [MWh]"
				},
				note: {
					label: "Poznámka"
				},
				gas_consumption: {
					label: "Spotreba plynu [MWh]"
				}
			}
		}
	}
}
