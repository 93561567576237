"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Paper, Grid, Tab, Tabs} from "@material-ui/core"

import RmeeEmployeeCardContainer from "./card/rmeeEmployeeCardContainer"
import RmeeEmployeeContactsContainer from "./contacts/rmeeEmployeeContactsContainer"
import RmeeEmployeeContractsContainer from "./contracts/rmeeEmployeeContractsContainer"
import RmeeEmployeeApplicationUserContainer from "./application/rmeeEmployeeApplicationUserContainer"

import RmeeCompanyEmployeesStore from "./rmeeCompanyEmployeesStore"

function RmeeCompanyEmployeeTabs(props) {
	const handleChange = (event, value) => {
		RmeeCompanyEmployeesStore.tabValue = value
	}

	return (
		<Box pt={4}>
			<Paper>
				<Tabs
					value={RmeeCompanyEmployeesStore.tabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						value="card"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-friends fa-lg"></i>
								</Grid>
								<Grid item>Karta zamestnanca</Grid>
							</Grid>
						}
					/>
					<Tab
						value="contacts"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-book fa-lg"></i>
								</Grid>
								<Grid item>Kontakty</Grid>
							</Grid>
						}
					/>
					<Tab
						value="contracts"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-book fa-lg"></i>
								</Grid>
								<Grid item>Úväzky</Grid>
							</Grid>
						}
					/>
					<Tab
						value="application"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-money-check-alt fa-lg"></i>
								</Grid>
								<Grid item>Používateľ aplikácie</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{RmeeCompanyEmployeesStore.tabValue == "card" && (
					<RmeeEmployeeCardContainer person_id={props.person_id} company_id={props.company_id} />
				)}
				{RmeeCompanyEmployeesStore.tabValue == "contacts" && (
					<RmeeEmployeeContactsContainer person_id={props.person_id} company_id={props.company_id} />
				)}
				{RmeeCompanyEmployeesStore.tabValue == "contracts" && (
					<RmeeEmployeeContractsContainer person_id={props.person_id} company_id={props.company_id} />
				)}
				{RmeeCompanyEmployeesStore.tabValue == "application" && (
					<RmeeEmployeeApplicationUserContainer person_id={props.person_id} company_id={props.company_id} />
				)}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(RmeeCompanyEmployeeTabs))
