"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import RmeeOrgunitAddressesStore from "./rmeeOrgunitAddressesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.typeOfAddress" />,
		width: 100,
		accessor: "type.name_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.region" />,
		width: 100,
		accessor: "country.name_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.county" />,
		width: 100,
		accessor: "county.name_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.street" />,
		width: 100,
		accessor: "street",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.number" />,
		width: 100,
		accessor: "house_number",
		sortType: "basic"
	},
	{
		Header: "Obec/Mesto",
		width: 100,
		accessor: "city_plain",
		sortType: "basic",
		Cell: (obj) => {
			const row = obj.row.original

			const city =
				isSafe(row.obj_city) && isNotEmpty(row.obj_city.name_ext)
					? row.obj_city.name_ext
					: isNotEmpty(row.city_plain)
					? row.city_plain
					: ""

			return <span>{city}</span>
		}
	},
	{
		Header: <FormattedMessage id="Common.label.psc" />,
		width: 100,
		accessor: "zip_plain",
		sortType: "basic",
		Cell: (obj) => {
			const row = obj.row.original

			const zip =
				isSafe(row.obj_zip) && isNotEmpty(row.obj_zip.name_ext)
					? row.obj_zip.name_ext
					: isNotEmpty(row.zip_plain)
					? row.zip_plain
					: ""

			return <span>{zip}</span>
		}
	},
	{
		clickable: false,
		width: 10,
		accessor: "_id",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className="fal fa-trash-alt fa-lg pointer"
							onClick={() => GlobalStore.openConfirmationDialog("removeOrgunitAddress", {address_id: obj.value})}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

function RmeeOrgunitAddressesTable(props) {
	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={RmeeOrgunitAddressesStore.orgunitAddressesData}
				onRowClick={(row) => RmeeOrgunitAddressesStore.openOrgunitAddressDialog(props.orgunit_id, row)}
			/>
			<XsConfirmationDialog
				name="removeOrgunitAddress"
				text="Naozaj chcete odstrániť adresu?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					RmeeOrgunitAddressesStore.removeOrgunitAddress(GlobalStore.confParams.address_id, props.orgunit_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RmeeOrgunitAddressesTable))
