"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Box, Grid} from "@material-ui/core"

import XsButton from "../../../global/ui/xsButton/xsButton"

import ListOfReports from "./listOfReports"
import ReportDialog from "./reportDialog"

import RozvodyReportStore from "./rozvodyReportStore"

function ReportsContainer() {
	return (
		<Box p={3}>
			<Grid container justify="space-between" alignItems="center">
				<Grid item>
					<FormattedMessage id="Common.label.reportList" />
				</Grid>
				<Grid item>
					<XsButton
						icon={<i className="fal fa-plus fa-lg"></i>}
						// variant="text"
						text={<FormattedMessage id="Common.label.addReport" />}
						onClick={() => {
							RozvodyReportStore.openReportDialog()
						}}
					/>
				</Grid>
			</Grid>
			<ListOfReports />
			<ReportDialog title={<FormattedMessage id="Common.label.addReport" />} isUpdating={false} />
		</Box>
	)
}

export default injectIntl(observer(ReportsContainer))
