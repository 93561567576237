"use strict"

import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import { injectIntl } from "react-intl"
// import ReactTooltip from "react-tooltip"
import { Container, Grid, Paper, Box, Typography } from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
// import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"

import LoginStore from "./loginStore"
import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"
import api from "../../global/actions/api"
import { isEmailValid, isPhoneNumber421Valid } from "../../global/helpers/functions"

function Register() {
	const [registrationStep, setRegistrationStep] = useState("1")
	const [titlesCL, setTitlesCL] = useState([])

	useEffect(() => {
		api.loadTitles().call().then(response => {
			setTitlesCL(response.rows)
		})
		return () => {
			setTitlesCL([])
		}
	}, [])

	const hooks = {
		onSubmit(form) {
			if (isEmailValid(form.$("email").value) == false) {
				form.$("email").invalidate("")
			}

			if (isPhoneNumber421Valid(form.$("phone").value) == false) {
				form.$("phone").invalidate("")
			}
		},
		onSuccess() {
			setRegistrationStep("2")
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					title_before: { label: "Vaše tituly pred menom", rules: "" },
					first_name: { label: "Krstné meno", rules: "required" },
					last_name: { label: "Priezvisko", rules: "required" },
					title_after: { label: "Vaše tituly za menom", rules: "" },
					email: { label: "Váš email", rules: "required" }, //email|string
					phone: { label: "Vaše tel. číslo", rules: "required", value: "+421" },
					username: { label: "Užívateľské meno ISEE", rules: "required" },
					company_primary_identifier: { label: "IČO", rules: "required|between:8,8" },
					company_primary_name: { label: "Názov spoločnosti", rules: "required" },
					company_founder_identifier: { label: "IČO", rules: "" },
					company_founder_name: { label: "Názov spoločnosti", rules: "" },
				}
			},
			{ plugins: { dvr: dvr(validatorjs) }, hooks }
		)
	)

	const hooksSecond = {
		onSubmit(formSecond) {
			logger("formSecond", formSecond)
		},
		onSuccess(formSecond) {
			logger("formSecond", formSecond)
			LoginStore.registration(form, formSecond)
		},
		onError() { }
	}

	const [formSecond] = useState(
		new MobxReactForm(
			{
				fields: {
					O: { label: "Vlastník budovy/Správca budovy", rules: "", type: "checkbox" },
					SR: { label: "Prijímateľ podpory - Environmentálny fond", rules: "", type: "checkbox" },
					CG: { label: "Ústredný orgán štátnej správy (ÚOŠS)", rules: "", type: "checkbox" },
					CGS: { label: "Organizácia v zakladateľskej alebo zriaďovateľskej pôsobnosti ÚOŠS", rules: "", type: "checkbox" },
					OG: { label: "Orgán štátnej správy, okrem ústredných orgánov štátnej správy", rules: "", type: "checkbox" },
					OGS: { label: "Organizácia v zakladateľskej alebo zriaďovateľskej pôsobnosti orgánu štátnej správy, okrem ústredných orgánov štátnej správy", rules: "", type: "checkbox" },
					C: { label: "Obec", rules: "", type: "checkbox" },
					CS: { label: "Organizácia v zakladateľskej alebo zriaďovateľskej pôsobnosti obce", rules: "", type: "checkbox" },
					HG: { label: "Vyšší územný celkok (VÚC)", rules: "", type: "checkbox" },
					HGS: { label: "Organizácia v zakladateľskej alebo zriaďovateľskej pôsobnosti VÚC", rules: "", type: "checkbox" },
					EP: { label: "Dodávateľ energie (Obchodno energetická spoločnosť)", rules: "", type: "checkbox" },
					GESP: { label: "Poskytovateľ garantovanej energetickej služby (GES)", rules: "", type: "checkbox" },
					PESP: { label: "Poskytovateľ podpornej energetickej služby (PES)", rules: "", type: "checkbox" },
					KVETO: { label: "Prevádzkovateľ zariadenia kombinovanej výroby elektriny a tepla (KVET)", rules: "", type: "checkbox" },
					ZVECHTO: { label: "Prevádzkovateľ zdroja na výrobu elektriny, tepla alebo chladu (ZVEChT)", rules: "", type: "checkbox" },
					HO: { label: "Prevádzkovateľ rozvodov tepla", rules: "", type: "checkbox" },
					EDO: { label: "Prevádzkovateľ distribučnej sústavy elektriny", rules: "", type: "checkbox" },
					GDO: { label: "Prevádzkovateľ distribučnej siete plynu", rules: "", type: "checkbox" },
					ECO: { label: "Prevádzkovateľ prenosovej sústavy elektriny", rules: "", type: "checkbox" },
					GCO: { label: "Prevádzkovateľ prepravnej siete plynu", rules: "", type: "checkbox" },
					PCO: { label: "Prevádzkovateľ prepravnej sústavy pohonných látok", rules: "", type: "checkbox" },
					OCO: { label: "Prevádzkovateľ prepravných potrubí ropy", rules: "", type: "checkbox" },
					PWPO: { label: "Prevádzkovateľ verejného vodovodu", rules: "", type: "checkbox" },
					WO: { label: "Prevádzkovateľ kanalizácie", rules: "", type: "checkbox" },
					EA: { label: "Energetický audítor, ktorý vykonáva energetický audit na území Slovenskej republiky", rules: "", type: "checkbox" },
					OC: { label: "Veľký podnik, ktorý je povinný poskytnúť údaje z energetického auditu, energetického alebo environmentálneho manažérstva", rules: "", type: "checkbox" },
					VS: { label: "Zúčastnený subjekt (Dobrovoľná dohoda)", rules: "", type: "checkbox" },
				}
			},
			{ plugins: { dvr: dvr(validatorjs) }, hooks: hooksSecond }
		)
	)


	return (
		<React.Fragment>
			<div className="base_header">
				<Container maxWidth="lg">
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<img
								src="/public/images/logo_rmee.png"
								className="pointer"
								onClick={() => RouterStore.push("/login")}
							/>
						</Grid>
						<Grid item className="header_gradient">
							{/* {getHeaderText(LoginStore.screen)} */}
							Register
						</Grid>
						<Grid item style={{ width: 100 }}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid
						item
						style={{
							// maxWidth: "400px",
							// width: "400px"
						}}
					>
						<Grid container direction="column" justify="center" className="mt-5">
							<Paper variant="elevation" elevation={2} style={{ backgroundColor: "transparent" }}>
								<Box p={2}>
									{registrationStep === "1" ?
										<React.Fragment>
											<Grid item container spacing={8}>
												<Grid item xs={12}>
													<Typography variant="h5">
														<b>Krok 1 / 2</b>
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body2">
														Pomocou tohto formulára sa môžete zaregistrovať v ISEE. Registráciou sa stanete správcom portálu ISEE pre Vami vybrané spoločnosti. Správca portálu môže následne pridávať a spravovať ostatných používateľov (poskytovateľov údajov) pre každú jemu prislúchajúcu spoločnosť.
													</Typography>
												</Grid>
												<Grid container item xs={12} md={6} className="mb-2" spacing={1}>
													<Grid item xs={12}>
														<Typography variant="h5" className="mb-2">
															<b>Údaje o Vás</b>
														</Typography>
													</Grid>
													<Grid item xs={12} >
														<XsSearchSelect field={form.$("title_before")} items={titlesCL} getOptionLabel={(row) => row.code_ext} />
													</Grid>
													<Grid item xs={12} >
														<XsInput field={form.$("first_name")} />
													</Grid>
													<Grid item xs={12} >
														<XsInput field={form.$("last_name")} />
													</Grid>
													<Grid item xs={12} >
														<XsSearchSelect field={form.$("title_after")} items={titlesCL} getOptionLabel={(row) => row.code_ext} />
													</Grid>
													<Grid item xs={12} >
														<XsInput
															field={form.$("email")}
															onBlur={() => {
																form.$("username").value = form.$("email").value
															}}
															regex={(val) => isEmailValid(val)}
														/>
													</Grid>
													<Grid item xs={12} >
														<XsInput field={form.$("phone")}
															regex={(val) => isPhoneNumber421Valid(val)}
														/>
													</Grid>
													<Grid item xs={12} >
														<XsInput field={form.$("username")} disabled={true} />
													</Grid>
												</Grid>
												<Grid container item xs={12} md={6} className="mb-2" spacing={1}>
													<Grid item xs={12}>
														<Typography variant="h5" className="mb-2">
															<b>Výber Vami spravovanej spoločností</b>
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="body2" className="mb-1">
															Pre Vami vybranú spoločnosť sa stanete primárnym používateľom verejného portálu ISEE.
														</Typography>
													</Grid>
													<Grid item container spacing={3}>
														<Grid item xs={6} >
															<XsInput field={form.$("company_primary_identifier")} />
														</Grid>
														<Grid item xs={6} >
															<XsInput field={form.$("company_primary_name")} />
														</Grid>
													</Grid>
													<Grid item xs={12} >
														<Typography variant="body2" className="mb-1 mt-2">
															Pre Vami vybranú spoločnosť sa stanete primárnym používateľom verejného portálu ISEE.
														</Typography>
													</Grid>
													<Grid item container spacing={3}>
														<Grid item xs={6}>
															<XsInput field={form.$("company_founder_identifier")} />
														</Grid>
														<Grid item xs={6} >
															<XsInput field={form.$("company_founder_name")} />
														</Grid>
													</Grid>
													<Grid item xs={12} >
														<Typography variant="body2">
															V prípade akýchkoľvek otázok nám prosím napíšte email na iseeinfo@siea.gov.sk alebo zavolajte na tel. číslo: 049/12345678
														</Typography>
													</Grid>
													<Grid item xs={12} >
														<Typography variant="body2">
															Po úspešnej registrácii Vám bude vygenerovaný registračný token (link) a bude Vám zaslaný na Vami uvedenú emailovú adresu. Prosím ďalej postupujte podľa inštrukcií v emaily.
														</Typography>
													</Grid>
												</Grid>

											</Grid>
											<Grid item container xs={12} direction="row-reverse">
												<Grid item>
													<XsButton
														text={"Ďalej"}
														onClick={form.onSubmit}
													/>
												</Grid>
											</Grid>
										</React.Fragment>
										: <React.Fragment>
											<Grid item container spacing={1}>
												<Grid item xs={12} className="mb-2">
													<Typography variant="h5">
														<b>Krok 2 / 2</b>
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body2" className="mb-1">
														V tomto kroku si vyberte prístup do jednotlivých modulov ISEE na základe Vašich zákonných povinnosti.
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="h5" className="mb-2">
														Vami spravovaná spoločnosť: <b>{form.$("company_primary_name").value}</b>
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="body2">
														<b>Podľa §24 zákona č. 321/2014 Z.z. som poskytovateľom údajov do monitorovacieho systému ako:</b>
													</Typography>
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("O")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("SR")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("CG")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("CGS")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("OG")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("OGS")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("C")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("CS")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("HG")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("HGS")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("EP")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("GESP")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("PESP")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("KVETO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("ZVECHTO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("HO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("EDO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("GDO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("ECO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("GCO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("PCO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("OCO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("PWPO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("WO")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("EA")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("OC")} />
												</Grid>
												<Grid item xs={12} md={6}>
													<XsCheckbox field={formSecond.$("VS")} />
												</Grid>
											</Grid>
											<Grid container justify="space-between">
												<Grid item>
													<XsButton
														variant="outlined"
														text={"Späť"}
														onClick={() => { setRegistrationStep("1") }}
													/>
												</Grid>
												<Grid item>
													<XsButton
														text={"Registrovať"}
														onClick={() => {
															formSecond.submit()
														}}
													/>
												</Grid>
											</Grid>
										</React.Fragment>}

									{/* <Grid item>
										<Link variant="body2" className="pointer" onClick={() => RouterStore.push("/login")}>
											Prihlásiť sa
										</Link>
									</Grid> */}
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Container>

		</React.Fragment >
	)
}

export default injectIntl(observer(Register))
