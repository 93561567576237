"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Paper, Grid, Tab, Tabs} from "@material-ui/core"

import RmeeOrgunitAddressesContainer from "./addresses/rmeeOrgunitAddressesContainer"
import RmeeOrgunitContactsContainer from "./contacts/rmeeOrgunitContactsContainer"
import RmeeOrgunitEmployeesContainer from "./employees/rmeeOrgunitEmployeesContainer"
import RmeeOrgunitBanksContainer from "./banks/rmeeOrgunitBanksContainer"
import RmeeOrgunitParamsContainer from "./params/rmeeOrgunitParamsContainer"

import RmeeCompanyOrgunitStore from "./rmeeCompanyOrgunitStore"

function RmeeCompanyOrgunitTabs(props) {
	const handleChange = (event, value) => {
		RmeeCompanyOrgunitStore.tabValue = value
	}

	return (
		<Box pt={4}>
			<Paper>
				<Tabs
					value={RmeeCompanyOrgunitStore.tabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						value="employees"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-friends fa-lg"></i>
								</Grid>
								<Grid item>Zamestnanci</Grid>
							</Grid>
						}
					/>
					<Tab
						value="addresses"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Adresy</Grid>
							</Grid>
						}
					/>
					<Tab
						value="contacts"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-book fa-lg"></i>
								</Grid>
								<Grid item>Kontakty</Grid>
							</Grid>
						}
					/>
					<Tab
						value="bank"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-money-check-alt fa-lg"></i>
								</Grid>
								<Grid item>Bankové účty</Grid>
							</Grid>
						}
					/>
					<Tab
						value="params"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-cog fa-lg"></i>
								</Grid>
								<Grid item>Parametre</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{RmeeCompanyOrgunitStore.tabValue == "employees" && (
					<RmeeOrgunitEmployeesContainer orgunit_id={props.orgunit_id} company_id={props.company_id} />
				)}
				{RmeeCompanyOrgunitStore.tabValue == "addresses" && (
					<RmeeOrgunitAddressesContainer orgunit_id={props.orgunit_id} />
				)}
				{RmeeCompanyOrgunitStore.tabValue == "contacts" && (
					<RmeeOrgunitContactsContainer orgunit_id={props.orgunit_id} />
				)}
				{RmeeCompanyOrgunitStore.tabValue == "bank" && <RmeeOrgunitBanksContainer orgunit_id={props.orgunit_id} />}
				{RmeeCompanyOrgunitStore.tabValue == "params" && <RmeeOrgunitParamsContainer orgunit_id={props.orgunit_id} />}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(RmeeCompanyOrgunitTabs))
