import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				orgunit_id: {},
				type: {
					label: <FormattedMessage id="Common.label.typeOfAddress" />,
					rules: "string|required"
				},
				region: {
					label: <FormattedMessage id="Common.label.region" />,
					rules: "string"
				},
				county: {
					label: <FormattedMessage id="Common.label.county" />,
					rules: "string"
				},
				street: {
					label: <FormattedMessage id="Common.label.street" />,
					rules: "string"
				},
				house_number: {
					label: <FormattedMessage id="Common.label.number" />,
					rules: "string"
				},
				city: {
					label: "Obec/Mesto",
					rules: "string|required"
				},
				city_text: {},
				zip: {
					label: <FormattedMessage id="Common.label.psc" />,
					rules: "string|required"
				},
				zip_text: {},
				description: {
					label: <FormattedMessage id="Common.label.description" />
				}
			}
		}
	}
}
