"use strict"

import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeCompanyEmployeeFields"

import { injectIntl, FormattedMessage } from "react-intl"
import { Grid, Typography, Box } from "@material-ui/core"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsBreadcrumbs from "../../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import RmeeCompanyEmployeesStore from "./rmeeCompanyEmployeesStore"
import RouterStore from "../../../../global/store/RouterStore"
import GlobalStore from "../../../../global/store/GlobalStore"

function RmeeCompanyEmployeeForm(props) {
	const hooks = {
		onSubmit() { },
		onSuccess(form) {
			RmeeCompanyEmployeesStore.saveCompanyEmployee(form, setDisabled)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), { plugins: { dvr: dvr(validatorjs) }, hooks }))
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		RmeeCompanyEmployeesStore.loadEmployee(props.person_id, props.company_id, form)

		return () => {
			form.reset()
		}
	}, [])

	return (
		<Grid container direction="column">
			<Grid item>
				<Box pt={2} pb={1}>
					<XsBreadcrumbs
						links={[
							{
								label: "ISEE",
								action: () => {
									RouterStore.push(`uvod`)
								}
							},
							{ label: "Spoločnosti", action: () => RouterStore.push(`rmee`) },
							{ label: form.$("company_name").value, action: () => RouterStore.push(`company?id=${props.company_id}`) }
						]}
						text={`${form.$("first_name").value} ${form.$("last_name").value}`}
					/>
				</Box>
			</Grid>
			<Grid item>
				<Box pb={2}>
					<Typography variant="h5">
						Zamestnanec - {form.$("first_name").value} {form.$("last_name").value}
					</Typography>
				</Box>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<XsInput field={form.$("identifier")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsSearchSelect
						field={form.$("title_before")}
						disabled={disabled}
						items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => obj.before) : []}
						getOptionLabel={(row) => row.code_ext}
					/>
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("first_name")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("last_name")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsSearchSelect
						field={form.$("title_after")}
						disabled={disabled}
						items={isSafe(GlobalStore.CL["titles"]) ? GlobalStore.CL["titles"].filter((obj) => !obj.before) : []}
						getOptionLabel={(row) => row.code_ext}
					/>
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("birth_name")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsDateTimePicker field={form.$("birth_date")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsSearchSelect field={form.$("gender")} items={GlobalStore.CL["genders"]} disabled={disabled} />
				</Grid>
			</Grid>
			<Box mt={2}>
				<Grid container spacing={1} justify="flex-end">
					{disabled ? (
						<Grid item>
							<XsButton
								icon={<i className="fal fa-pencil-alt"></i>}
								text={<FormattedMessage id="Common.btn.edit" />}
								onClick={() => setDisabled(false)}
							/>
						</Grid>
					) : (
						<React.Fragment>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-times"></i>}
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										RmeeCompanyEmployeesStore.loadEmployee(props.person_id, props.company_id, form)
										setDisabled(true)
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-save"></i>}
									text={<FormattedMessage id="Common.btn.save" />}
									onClick={form.onSubmit}
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</Box>
		</Grid>
	)
}

export default injectIntl(observer(RmeeCompanyEmployeeForm))
