import {observable, action} from "mobx"
import {getFilters} from "../../../global/helpers/api"
import {isReportDisabled, getCompanyId} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"
import api from "../../../global/actions/api"

class RozvodyReportStore {
	@observable isOpenReportDialog = false

	@observable reportsData = []
	@observable distributionsForReport = []
	@observable dropedFiles = []
	@observable attachmentTemplates = []
	@observable isDisabled = false
	@observable distributionType = ""
	@observable distributionTypeName = ""

	@observable existYears = []

	formRef = null

	@action resetValues() {
		this.isOpenReportDialog = false
		this.reportsData = []
		this.distributionsForReport = []
		this.dropedFiles = []
		this.attachmentTemplates = []
		this.distributionType = ""
		this.distributionTypeName = ""
		this.existYears = []
	}

	@action openReportDialog() {
		this.isOpenReportDialog = true
	}

	@action closeReportDialog(form) {
		form.reset()
		this.isOpenReportDialog = false
	}

	@action getRozvodyReports() {
		const req = getFilters([`company_id=1`])

		api
			.loadDistributionReports(req)
			.call()
			.then((reports) => {
				this.reportsData = reports.rows
				// if (isSafe(reports) && isSafe(reports.rows) && reports.rows.length) {
				// 	let tempExist = []

				// 	reports.rows.forEach((x) => {
				// 		tempExist.push(x.year)
				// 	})

				// 	this.existYears = tempExist

				// 	this.reportsData = reports.rows
				// } else {
				// 	this.reportsData = []
				// 	this.existYers = []
				// }
			})
	}

	// @action getHistoricalData(form) {
	// 	const values = form.values()

	// 	const req = {
	// 		company_id: "1",
	// 		year: values.year,
	// 		region_id: values.region_id,
	// 		medium_id: values.medium_id,
	// 		consumer_group_id: values.consumer_group_id
	// 	}

	// 	api
	// 		.getOESRecordHistory(req)
	// 		.call()
	// 		.then((res) => {
	// 			if (isSafe(res)) {
	// 				form.$("quantity_sold_history").value = res.quantity_sold
	// 				form.$("consumer_count_history").value = res.consumer_count
	// 			}
	// 		})
	// }

	@action saveReport(form, report_id) {
		const values = form.values()
		const req = {
			distribution_type_id: values.distribution_type,
			year: values.year,
			company_id: getCompanyId()
			// "distributor_id": values.name,
			// "report_id": "72BBA550-E3BF-11EB-B789-1831BFB5DCAD",
		}

		if (isNotEmpty(values._id)) {
			req["_id"] = values._id
		}

		if (isNotEmpty(report_id)) {
			req["report_id"] = report_id
		}

		if (isNotEmpty(values.name)) {
			req["distributor_id"] = values.name
		} else {
			req["distributor_name"] = values.custom_name
		}

		if (isNotEmpty(values.note)) {
			req["note"] = values.note
		}

		if (values.distribution_type == "EDS" || values.distribution_type == "ETN") {
			req["transmission_losses"] = values.transmission_losses
			req["transmitted_electricity_amount"] = values.transmitted_electricity_amount
			req["energy_consumption"] = values.energy_consumption
		}

		if (values.distribution_type == "FTS") {
			req["fuel_transmitted_amount"] = values.fuel_transmitted_amount
		}

		if (values.distribution_type == "GDS") {
			req["transmitted_gas_amount"] = values.transmitted_gas_amount
			req["other_electricity_consumption"] = values.other_electricity_consumption
			req["consumption"] = values.consumption
			req["gas_consumption"] = values.gas_consumption
		}

		if (values.distribution_type == "GTN") {
			req["transmitted_gas_amount"] = values.transmitted_gas_amount
			req["consumption"] = values.consumption
			req["gas_consumption"] = values.gas_consumption
		}

		if (values.distribution_type == "HTD") {
			req["heating_medium_id"] = values.heating_medium_id
			req["name"] = values.name
			req["input_heat_amount"] = values.input_heat_amount
			req["output_heat_amount"] = values.output_heat_amount
		}

		if (values.distribution_type == "OTS") {
			req["oil_transmitted_amount"] = values.oil_transmitted_amount
		}

		if (values.distribution_type == "PPS") {
			req["sold_water_amount"] = values.sold_water_amount
			req["consumption"] = values.consumptionPPS
		}

		if (values.distribution_type == "SEW") {
			req["sewerage_amount"] = values.sewerage_amount
			req["energy_consumption"] = values.energy_consumed
		}

		api
			.saveDistributionReport(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Report úspešne pridaný", "success")
					this.closeReportDialog(form)
					//Po save pri update refresh zoznamu v detaile in zoznamu hlavneho
					if (isNotEmpty(report_id)) {
						this.getRecords(report_id)
					} else {
						this.getRozvodyReports()
					}
				} else {
					GlobalStore.setNotificationMessage("Pri pridávaní reportu došlo ku chybe", "error")
				}
			})
	}

	@action getRecords(report_id) {
		const req = getFilters([`report_id=${report_id}`])
		api
			.getDistributionReports(req)
			.call()
			.then((response) => {
				response.rows.forEach((row, index) => {
					response.rows[index]["custom_index"] = index + 1
				})
				this.distributionsForReport = response.rows
			})
	}

	@action getRecordInfo(report_id, form) {
		api
			.getDistributionReport(report_id)
			.call()
			.then((response) => {
				form.$("note").value = response.note
				this.distributionType = response.distribution_type_id
				this.distributionTypeName = response.distribution_type_name
				this.isDisabled = isReportDisabled(response.status_id)
				this.getAttachments(report_id)
			})
	}

	@action getAttachments(report_id) {
		api
			.getAttachments("ISEE.Data.DistributionReport", report_id)
			.call()
			.then((attachments) => {
				if (isSafe(attachments) && attachments.length) {
					attachments.forEach(async (att) => {
						let res = await api.getAttachmentContent(att.api_url).call()
						res.blob().then((blob) => {
							let tmp = new File([blob], att.file_name, {
								type: att.content_type
							})

							tmp._id = att._id
							tmp.api_url = att.api_url
							this.dropedFiles.push(tmp)
						})
					})
				}
			})
	}

	@action saveAttachmentOnDrop(file, report_id) {
		let data = file
		let reader = new FileReader()

		let attachReq = []
		let binaryDataArr = []

		const setIdAndPush = (id) => {
			file._id = id
			this.dropedFiles.push(file)
		}

		reader.readAsBinaryString(data)
		reader.onloadend = function() {
			binaryDataArr.push(reader.result)

			attachReq.push({
				_id: isNotEmpty(data._id) ? data._id : null,
				_ref: false,
				_type: "SIEA.Data.Attachment",
				active: true,
				rel_class_name: "ISEE.Data.DistributionReport",
				rel_object_id: report_id,
				content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
				type: "General",
				file_name: data.name,
				size: data.size
			})

			api
				.saveAttachments(attachReq)
				.call()
				.then(async (attachs) => {
					if (isSafe(attachs) && attachs.length) {
						let error = false
						let attachId = attachs[0]._id

						await api
							.saveAttachmentContent(attachs[0]._id, binaryDataArr[0])
							.call()
							.then((content) => {
								if (isNotSafe(content) || (isSafe(content) && content._id)) {
									error = true
								}
							})

						if (error) {
							GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
						} else {
							if (isNotEmpty(attachId)) {
								setIdAndPush(attachId)
								GlobalStore.setNotificationMessage("Prílohy úspešne uložené", "success")
							}
						}
					} else {
						GlobalStore.setNotificationMessage("Pri ukladaní príloh nastala chyba", "error")
					}
				})
		}
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachments(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action deleteRecord(record_id, report_id) {
		api
			.deleteRozvodyRecord(record_id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report úspešne odstránený", "success")
				this.getRecords(report_id)
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	saveReportNote(id, form) {
		let req = {
			_id: id,
			note: form.$("note").value
		}
		api
			.updateDistributionNote(req)
			.call()
			.then((response) => {
				logger(response)
			})
	}

	@action deleteReport(id) {
		api
			.deleteRozvodyReport(id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report úspešne odstránený", "success")
				this.getRozvodyReports()
			})
	}

	@action sendReport(reportId) {
		const req = {
			_id: reportId
		}

		api
			.sendRozvodyReport(reportId, req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report odoslany", "success")
				GlobalStore.openConfirmationDialog("saveRozvodReportSuccessful")
			})
	}

	@action loadDistribution(row) {
		//Otovri dialog s formom a ziska ref
		this.openReportDialog()
		const loadForm = () => {
			let form = {}
			form["_id"] = row._id
			form["name"] = row.distributor_id
			form["note"] = row.note

			row.distribution_type = row.distribution_type_id

			if (row.distribution_type == "EDS" || row.distribution_type == "ETN") {
				form["transmission_losses"] = row.transmission_losses
				form["transmitted_electricity_amount"] = row.transmitted_electricity_amount
				form["energy_consumption"] = row.energy_consumption
			}

			if (row.distribution_type == "FTS") {
				form["fuel_transmitted_amount"] = row.fuel_transmitted_amount
			}

			if (row.distribution_type == "GDS") {
				form["transmitted_gas_amount"] = row.transmitted_gas_amount
				form["other_electricity_consumption"] = row.other_electricity_consumption
				form["consumption"] = row.consumption
				form["gas_consumption"] = row.gas_consumption
			}

			if (row.distribution_type == "GTN") {
				form["transmitted_gas_amount"] = row.transmitted_gas_amount
				form["consumption"] = row.consumption
				form["gas_consumption"] = row.gas_consumption
			}

			if (row.distribution_type == "HTD") {
				form["heating_medium_id"] = row.heating_medium_id
				form["name"] = row.name
				form["input_heat_amount"] = row.input_heat_amount
				form["output_heat_amount"] = row.output_heat_amount
			}

			if (row.distribution_type == "OTS") {
				form["oil_transmitted_amount"] = row.oil_transmitted_amount
			}

			if (row.distribution_type == "PPS") {
				form["sold_water_amount"] = row.sold_water_amount
				form["consumptionPPS"] = row.consumption
			}

			if (row.distribution_type == "SEW") {
				form["sewerage_amount"] = row.sewerage_amount
				form["energy_consumed"] = row.energy_consumption
			}

			this.formRef.set(form)
		}

		setTimeout(() => {
			loadForm()
		}, 0)
	}
}

var singleton = new RozvodyReportStore()
export default singleton
