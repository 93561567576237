"use strict"

import React, {useState, useEffect} from "react"
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./reportDialogFields"
import Dropzone from "react-dropzone"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../../global/ui/xsButton/xsButton"

import CvsReportStore from "./cvsReportStore"
import GlobalStore from "../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../global/store/RegistersCacheStore"

function ReportDialog(props) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			CvsReportStore.saveReport(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const [years, setYears] = useState([])
	const [isFullWidth, setFullWidth] = useState(false)

	useEffect(() => {
		const actualYear = moment().year() - 1
		let tempYears = []

		for (let i = 0; i < 5; i++) {
			const year = actualYear - i

			tempYears.push({
				_id: year,
				name: year,
				name_ext: year,
				code: year,
				code_ext: year
			})
		}

		setYears(tempYears)
	}, [])

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (CvsReportStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			CvsReportStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (attachmentIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(CvsReportStore.dropedFiles[attachmentIndex], CvsReportStore.dropedFiles[attachmentIndex].name)
		GlobalStore.setNotificationMessage("Súbor bol úspešne stiahnutý", "success")
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		if (isSafe(CvsReportStore.dropedFiles._id)) {
			CvsReportStore.deleteLoadedAttachemnt(CvsReportStore.dropedFiles._id, attachmentIndex)
		} else {
			CvsReportStore.dropedFiles.splice(attachmentIndex, 1)
		}
	}

	const close = () => {
		CvsReportStore.closeReportDialog(form)
		setFullWidth(false)
	}

	return (
		<Dialog
			open={CvsReportStore.isOpenReportDialog}
			onEscapeKeyDown={() => close()}
			maxWidth={isFullWidth ? "lg" : "sm"}
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="h6">{props.title}</Typography>
					</Grid>
					<i className="fal fa-times fa-lg dialog_close_icon pointer" onClick={() => close()}></i>
					{/* <Grid item>
						<Typography variant="h6">
							<FormattedMessage id="Common.label.warning" />
						</Typography>
					</Grid> */}
				</Grid>
			</DialogTitle>
			<DialogContent style={{paddingTop: "0px", paddingBottom: "10px"}}>
				<Grid container>
					<Grid container item spacing={1} xs={isFullWidth ? 6 : 12} className="pr-4">
						{!isFullWidth ? (
							<Grid
								item
								container
								alignItems="center"
								justify="flex-end"
								className="greyDefault bold"
								spacing={2}
								style={{fontSize: "12px"}}
								onClick={() => setFullWidth(true)}
							>
								<Grid item>zobraziť historické údaje</Grid>
								<Grid item>
									<i className="far fa-chevron-right"></i>
								</Grid>
							</Grid>
						) : (
							<Grid item container style={{fontSize: "12px"}}>
								<Grid item>&nbsp;</Grid>
							</Grid>
						)}
						<Grid item xs={12}>
							<XsSearchSelect
								field={form.$("year")}
								items={isSafe(years) ? years.filter((x) => !CvsReportStore.existYears.includes(x._id)) : []}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsSearchSelect
								field={form.$("medium_id")}
								items={RegistersCacheStore.mediumCVS}
								onChange={(medium_id) => {
									if (isNotEmpty(medium_id)) {
										if (isNotEmpty(form.$("year").value)) {
											CvsReportStore.getHistoricalData(form)
											CvsReportStore.getGraphData(medium_id)
										}

										form.$("unit_id").value = ""

										const clMedium = RegistersCacheStore.mediumCVS.find((x) => x._id == medium_id)

										if (clMedium) {
											const limitFrom = isNotEmpty(clMedium.price_limit.from) ? clMedium.price_limit.from : 0
											const limitTo = isNotEmpty(clMedium.price_limit.to) ? clMedium.price_limit.to : 0

											form.$("limited_price").value = `${limitFrom} - ${limitTo}`
											form.$("limit_from").value = limitFrom
											form.$("limit_to").value = limitTo
										}
									} else {
										form.$("unit_history").value = ""
										form.$("consumption_in_unit_of_measure_history").value = ""
										form.$("costs_history").value = ""

										CvsReportStore.graphData = []
									}
								}}
							/>
						</Grid>
						<Grid item xs={6} style={{position: "relative"}}>
							<XsSearchSelect
								field={form.$("unit_id")}
								disabled={isEmpty(form.$("medium_id").value)}
								items={
									isNotEmpty(form.$("medium_id").value)
										? RegistersCacheStore.mediumCVS.find((x) => x._id == form.$("medium_id").value).units
										: []
								}
								getOptionLabel={(obj) => `${obj.code_ext} - ${obj.name_ext}`}
							/>
							<div style={{position: "absolute", right: "35px", bottom: "15px", color: "rgba(0, 0, 0, 0.54)"}}>
								{isNotEmpty(form.$("unit_history").value) ? `(${form.$("unit_history").value})` : ""}
							</div>
						</Grid>
						<Grid item xs={6}>
							<XsInput
								field={form.$("consumption_in_unit_of_measure")}
								onChange={(val) => {
									if (val.includes(",")) {
										form.$("consumption_in_unit_of_measure").value = val.replace(",", ".")
									}

									if (isNotEmpty(form.$("costs").value) && isNotEmpty(form.$("consumption_in_unit_of_measure").value)) {
										const calcPrice = (form.$("costs").value / form.$("consumption_in_unit_of_measure").value).toFixed(
											5
										)

										if (calcPrice >= form.$("limit_from").value && calcPrice <= form.$("limit_to").value) {
											form.$("calculated_price").value = `${calcPrice} - v rozsahu`
											form.$("inLimit").value = true
										} else {
											if (calcPrice >= form.$("limit_from").value) {
												form.$("calculated_price").value = `${calcPrice} - nad rozsahom`
											} else {
												form.$("calculated_price").value = `${calcPrice} - pod rozsahom`
											}
											form.$("inLimit").value = false
										}
									} else {
										form.$("calculated_price").value = 0
									}
								}}
								endText={
									isNotEmpty(form.$("consumption_in_unit_of_measure_history").value)
										? `(${form.$("consumption_in_unit_of_measure_history").value})`
										: ""
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput
								field={form.$("costs")}
								onChange={(val) => {
									if (val.includes(",")) {
										form.$("costs").value = val.replace(",", ".")
									}

									if (isNotEmpty(form.$("consumption_in_unit_of_measure").value) && isNotEmpty(form.$("costs").value)) {
										const calcPrice = (form.$("costs").value / form.$("consumption_in_unit_of_measure").value).toFixed(
											5
										)

										if (calcPrice >= form.$("limit_from").value && calcPrice <= form.$("limit_to").value) {
											form.$("calculated_price").value = `${calcPrice} - v rozsahu`
											form.$("inLimit").value = true
										} else {
											if (calcPrice >= form.$("limit_from").value) {
												form.$("calculated_price").value = `${calcPrice} - nad rozsahom`
											} else {
												form.$("calculated_price").value = `${calcPrice} - pod rozsahom`
											}
											form.$("inLimit").value = false
										}
									} else {
										form.$("calculated_price").value = 0
									}
								}}
								endText={isNotEmpty(form.$("costs_history").value) ? `(${form.$("costs_history").value})` : ""}
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput
								field={form.$("calculated_price")}
								rootClassName={form.$("inLimit").value ? "greenSuccess" : "redDanger"}
								disabled
							/>
						</Grid>
						<Grid item xs={6}>
							<XsInput field={form.$("limited_price")} disabled />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={form.$("note")} />
						</Grid>
						<Grid item xs={12}>
							<Dropzone
								onDrop={(files) => {
									handleDrop(files, 5)
								}}
								id="dropzone"
								// disabled={isFormDisabled()}
								accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
							>
								{isSafe(CvsReportStore.dropedFiles) && CvsReportStore.dropedFiles.length ? (
									CvsReportStore.dropedFiles.map((file, index) => (
										<div key={index} className={"dropedFile"}>
											<i
												className="fal fa-file icon"
												onClick={(e) => {
													e.stopPropagation()
													handleDropzonePreview(index)
												}}
											/>
											<i
												className="far fa-trash-alt deleteIcon"
												onClick={(e) => {
													e.stopPropagation()
													// if (!isFormDisabled()) {
													GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
														fileIndex: index
													})
													// }
												}}
											/>
											<br />
											<div
												className={"mac_ellipsis"}
												data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
												data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
											/>
										</div>
									))
								) : (
									<div
										className={"dropzonePlaceholder"}
									>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
								)}
							</Dropzone>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
							</Typography>
						</Grid>
						<Grid item xs={12} container justify="flex-end" spacing={1}>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									variant="text"
									icon={<i className="fal fa-times fa-lg"></i>}
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => close()}
								/>
							</Grid>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									variant="text"
									icon={<i className="fal fa-save fa-lg"></i>}
									text={<FormattedMessage id="Common.label.save" />}
									onClick={form.onSubmit}
								/>
							</Grid>
						</Grid>
					</Grid>
					{isFullWidth && (
						<Grid container item xs={6} direction="column" className="pl-4">
							<Grid
								item
								container
								alignItems="center"
								className="greyDefault bold"
								spacing={2}
								style={{fontSize: "12px"}}
								onClick={() => setFullWidth(false)}
							>
								<Grid item>
									<i className="far fa-chevron-left"></i>
								</Grid>
								<Grid item>zavrieť historické údaje</Grid>
							</Grid>
							{CvsReportStore.graphData && CvsReportStore.graphData.length > 0 && (
								<React.Fragment>
									<Grid item container className="pb-4">
										<Grid item xs>
											<Grid item className="pr-2 pt-1 pb-1 " style={{borderBottom: "1px solid #d3d3d3"}}>
												&nbsp;
											</Grid>
											<Grid
												item
												className="pr-2 pt-1 pb-1"
												style={{borderBottom: "1px solid #d3d3d3", whiteSpace: "nowrap"}}
											>
												Spotreba v m.j. [kWh]
											</Grid>
											<Grid item className="pr-2 pt-1 pb-1" style={{whiteSpace: "nowrap"}}>
												Vaše náklady [EUR]
											</Grid>
										</Grid>
										{CvsReportStore.graphData.map((item, idx) => {
											return (
												<Grid item key={idx} xs>
													<Grid
														item
														className="pr-2 pt-1 pb-1 textRight greyDefault"
														style={{borderBottom: "1px solid #d3d3d3"}}
													>
														{item.Year}
													</Grid>
													<Grid item className="pr-2 pt-1 pb-1 textRight" style={{borderBottom: "1px solid #d3d3d3"}}>
														{isEmpty(item.Consumption) ? <span>&nbsp;</span> : item.Consumption}
													</Grid>
													<Grid item className="pr-2 pt-1 pb-1 textRight">
														{isEmpty(item.Costs) ? <span>&nbsp;</span> : item.Costs}
													</Grid>
												</Grid>
											)
										})}
									</Grid>
									<Grid item style={{flex: 1, width: "100%"}}>
										<ResponsiveContainer width="100%" height="100%">
											<BarChart
												width={500}
												height={300}
												data={CvsReportStore.graphData}
												margin={{
													top: 5,
													right: 30,
													left: 20,
													bottom: 5
												}}
											>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis dataKey="Year" />
												<YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
												<YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
												<YAxis />
												<Tooltip />
												<Legend />
												<Bar yAxisId="left" dataKey="Consumption" fill="#8884d8" />
												<Bar yAxisId="right" dataKey="Costs" fill="#82ca9d" />
											</BarChart>
										</ResponsiveContainer>
									</Grid>
								</React.Fragment>
							)}
						</Grid>
					)}
					{/* <Grid container item spacing={2} xs={6}>
						<ResponsiveContainer width="100%" height="100%">
							<BarChart
								width={500}
								height={300}
								data={CvsReportStore.graphData}
								margin={{
									top: 5,
									right: 30,
									left: 20,
									bottom: 5
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="Year" />
								<YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
								<YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
								<YAxis />
								<Tooltip />
								<Legend />
								<Bar yAxisId="left" dataKey="Consumption" fill="#8884d8" />
								<Bar yAxisId="right" dataKey="Costs" fill="#82ca9d" />
							</BarChart>
						</ResponsiveContainer>
					</Grid> */}
				</Grid>
			</DialogContent>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
		</Dialog>
	)
}

export default injectIntl(observer(ReportDialog))
