"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container, Typography, Grid, Link, Box} from "@material-ui/core"

function WelcomePage() {
	return (
		<Container maxWidth="md">
			<Grid container direction="column" spacing={2}>
				<Grid item container justify="center">
					<Grid item>
						<Box pt={8}>
							<Typography variant="h3" className="header_gradient">
								Welcome to ISEE
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid item container justify="center">
					<Grid item>
						<Box pb={6}>
							<Typography variant="body2">Informačný systém energetickej efektívnosti</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						&ldquo;ISEE Registration forms (applications)&rdquo; link in the top menu redirects to a list of your
						applications. By pressing the &ldquo;New ISEE Registration form (application)&rdquo; button in this list you
						can create a new application.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						If you want to exit the portal, please log out by clicking on &ldquo;Log out&rdquo; link in the top menu.
						{/* Ak chcete ukončiť prácu v portáli, odhláste sa prosím klikom na &ldquo;Odhlásiť sa&rdquo; v pravej hornej
						časti obrazovky. */}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						{/* Ak máte akékoľvek otázky, kontaktujte SIEA infolinku na telefónnom čísle 02/58 248 111, alebo nám napíšte na */}
						If you have any questions, contact the SIEA infoline on the phone number 02/58 248 111, or write to us at
						<Link href="mailto:rmee@siea.gov.sk" className="bold">
							{" "}
							rmee@siea.gov.sk
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(WelcomePage))
