// import {FormattedMessage} from "react-intl"
// import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				name: {
					label: "Názov objektu",
					rules: "required"
				},
				relation: {
					label: "Vzťah k objektu",
					rules: "required"
				},
				category: {
					label: "Kategória objektu",
					rules: "required"
				},
				owner: {
					label: "Vlastníctvo objektu",
					rules: "required"
				},
				city: {
					label: "Obec",
					rules: "required"
				},
				cadastral_domain: {
					label: "Katastrálne územie",
					rules: "required"
				},
				cadastral_city_code: {},
				cadastral_domain_code: {},
				registration_number: {
					label: "Súpisné číslo",
					rules: "required"
				},
				parcel_number: {
					label: "Číslo parcely",
					rules: "required"
				},
				street: {
					label: "Ulice",
					rules: "required"
				},
				house_number: {
					label: "Orientačné číslo",
					rules: "required"
				},
				system_id: {
					label: "ID vo vašom systéme"
				},
				tag: {
					label: "Značka"
				},
				note: {
					label: "Poznámka"
				}
			}
		}
	}
}
