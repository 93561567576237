"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"

import {Grid, Paper, Container, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

import {isPasswordValid} from "../../global/helpers/functions"

import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"
import LoginStore from "./loginStore"

function RegisterContainer() {
	const hooks = {
		onSubmit(form) {
			const {password, conf_psw} = form.values()

			if (isNotEmpty(password) && password != conf_psw) {
				GlobalStore.setNotificationMessage("Heslo a potvrdenie hesla sa musia zhodovať!", "warning")
				form.$("password").invalidate()
			} else if (!isPasswordValid(password) || !isPasswordValid(conf_psw)) {
				GlobalStore.setNotificationMessage(
					"The password must contain at least one uppercase letter, one lowercase letter and one number. Minimum length of password is 8 characters.",
					"warning"
				)
				form.$("password").invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.registrationFinish(form)
		},
		onError() {
			// GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					password: {
						label: <FormattedMessage id="Common.label.newPsw" />
					},
					conf_psw: {
						label: <FormattedMessage id="Common.label.confPsw" />
					}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	useEffect(() => {
		LoginStore.token = new URLSearchParams(RouterStore.location.search).get("token")
		LoginStore.email = new URLSearchParams(RouterStore.location.search).get("email")
	}, [])

	return (
		<React.Fragment>
			<div className="base_header">
				<Container maxWidth="lg">
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<img src="/public/images/logo_rmee.png" className="pointer" onClick={() => RouterStore.push("/login")} />
						</Grid>
						<Grid item className="header_gradient">
							<Typography variant="h3">
								<FormattedMessage id="Common.label.register" />
							</Typography>
						</Grid>
						<Grid item style={{width: 100}}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid item style={{maxWidth: "400px", width: "400px"}}>
						<Grid container direction="column" justify="center" style={{height: "65vh"}}>
							<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
								<Box p={2}>
									<Grid item container direction="column" spacing={2}>
										<Grid item>
											<Typography variant="body2">
												To successfully complete the registration, fill in the New password and Confirm password fields.
												You will then use this password to log in to the portal. You will use your email address{" "}
												{LoginStore.email} that you entered during registration as your login name. Password must
												contain at least one uppercase letter, one lowercase letter and one number. Password must be at
												least 8 characters long.
											</Typography>
										</Grid>
										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("password")}
												autoFocus={true}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("conf_psw")}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsButton text={<FormattedMessage id="Common.label.register" />} onClick={form.onSubmit} />
										</Grid>
										<Grid item>
											<Link
												variant="body2"
												className="pointer"
												onClick={() => {
													RouterStore.push("./login")
													LoginStore.screen = LoginStore.screens.LOGIN
												}}
											>
												<FormattedMessage id="Common.label.sigin" />
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(RegisterContainer))
