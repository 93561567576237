"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeEmployeeContractFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsNumberInput from "../../../../../global/ui/xsInput/xsNumberInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import RmeeEmployeeContractsStore from "./rmeeEmployeeContractsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeEmployeeContractDialog(props) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RmeeEmployeeContractsStore.saveEmployeeContract(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		RmeeEmployeeContractsStore.loadCompanyOrgunits(props.company_id)
		RmeeEmployeeContractsStore.employeeContractFormRef = form
	}, [])

	return (
		<Dialog
			open={RmeeEmployeeContractsStore.isOpenEmployeeContractDialog}
			onEscapeKeyDown={() => RmeeEmployeeContractsStore.closeEmployeeContractDialog()}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<i className="fal fa-address-card fa-2x"></i>
					</Grid>
					<Grid item>
						<Typography variant="h6">Pridať úväzok</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => RmeeEmployeeContractsStore.closeEmployeeContractDialog()}
					></i>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("orgunit_id")}
							items={RmeeEmployeeContractsStore.companyOrgunits
								.filter((x) => x.org_unit_id != form.$("_id").value)
								.map((i) => {
									return {
										code_ext: i.code_ext,
										_id: i.org_unit_id,
										name_ext: i.name_ext
									}
								})}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsNumberInput min="2" step="1" field={form.$("contract_percentage")} endText="%" />
					</Grid>
					<Grid item xs={6}>
						<XsDateTimePicker field={form.$("contract_from")} />
					</Grid>
					<Grid item xs={6}>
						<XsDateTimePicker field={form.$("contract_to")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={6}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => RmeeEmployeeContractsStore.closeEmployeeContractDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(RmeeEmployeeContractDialog))
