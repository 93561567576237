"use strict"

import React, {useEffect} from "react"
import classnames from "classnames"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"

import BudovyBuildingStore from "./budovyBuildingStore"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"

const columns = [
	{
		Header: "Adresa",
		width: 100,
		accessor: "street_full"
	},
	{
		Header: "Obec/Mesto",
		width: 100,
		accessor: "city_plain"
	},
	{
		Header: "Okres",
		width: 100,
		accessor: "county_plain"
	},
	{
		Header: "Názov objektu",
		width: 100,
		accessor: "name"
	},
	{
		Header: "Kategória",
		width: 100,
		accessor: "category_name_ext"
	},
	{
		Header: () => <div className="textRight">Rok posl. reportu</div>,
		width: 70,
		accessor: "last_report_year",
		Cell: (row) => <div className="textRight">{row.value ? row.value : ""}</div>
	},
	{
		Header: () => <div className="textRight">Počet príloh</div>,
		width: 70,
		accessor: "attachments_count",
		Cell: (row) => (
			<Grid container alignItems="center" justify="flex-end">
				<Grid item style={{paddingRight: "3px"}}>
					<i className="far fa-paperclip"></i>
				</Grid>
				<Grid item>{row.value}</Grid>
			</Grid>
		)
	},
	{
		Header: "Poznámka",
		width: 100,
		accessor: "note"
	},
	{
		clickable: false,
		width: 10,
		accessor: "_id",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className={classnames("fas fa-trash-alt fa-lg redDanger pointer")}
							onClick={() => GlobalStore.openConfirmationDialog("removeObject", {object_id: obj.value})}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

function ListOfBuildings() {
	useEffect(() => {
		BudovyBuildingStore.getBuildings()
	}, [])

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={BudovyBuildingStore.buildingsData}
				colorful
				size="small"
				onRowClick={(row) => RouterStore.push(`/building?id=${row._id}`)}
			/>
			<XsConfirmationDialog
				type="danger"
				name="removeObject"
				text="Naozaj chcete odstrániť objekt?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					BudovyBuildingStore.removeBuilding(GlobalStore.confParams.object_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(ListOfBuildings))
