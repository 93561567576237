"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Paper, Grid, Tab, Tabs} from "@material-ui/core"

import RmeeCompanyOrgunitsContainer from "./orgunits/rmeeCompanyOrgunitsContainer"
import RmeeCompanyEmployeesContainer from "./employees/rmeeCompanyEmployeesContainer"

import RmeeStore from "../rmeeStore"

function RmeeCompanyTabs(props) {
	const handleChange = (event, value) => {
		RmeeStore.companyTabValue = value
	}

	return (
		<Box pt={4}>
			<Paper>
				<Tabs
					value={RmeeStore.companyTabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						value="orgunits"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-friends fa-lg"></i>
								</Grid>
								<Grid item>Organizačné jednotky</Grid>
							</Grid>
						}
					/>
					<Tab
						value="employees"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Zamestnanci</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{RmeeStore.companyTabValue == "orgunits" && <RmeeCompanyOrgunitsContainer company_id={props.company_id} />}
				{RmeeStore.companyTabValue == "employees" && <RmeeCompanyEmployeesContainer company_id={props.company_id} />}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(RmeeCompanyTabs))
