import {ApiCall} from "../../global/helpers/api"
import {getUser} from "../helpers/actions"
import config from "../../global/config/settings"

export default {
	login(login, password, recaptchaToken) {
		return new ApiCall({
			path: `-public/sec/login?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: {
				login,
				password
			},
			includeToken: false
		})
	},

	registration(req, recaptchaToken) {
		return new ApiCall({
			path: `-public/client/register?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	registrationFinish(req, token, recaptchaToken) {
		return new ApiCall({
			path: `-public/client/action-consume/${token}?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	resetPassword(req, recaptchaToken) {
		return new ApiCall({
			path: `-public/client/reset-password?g-recaptcha-response=${recaptchaToken}`,
			method: "POST",
			body: JSON.stringify(req),
			includeToken: false
		})
	},

	logout() {
		return new ApiCall({
			path: "/sec/logout",
			method: "POST"
		})
	},

	lunchImportData() {
		return new ApiCall(`/etl/enqueue`, "POST")
	},

	checkImportData() {
		return new ApiCall(`/etl/queuestatus`, "GET")
	},

	loadCompanies(req) {
		return new ApiCall(`/entity/companies?level_flag=CDL2`, "POST", req)
	},

	loadEntity(id) {
		return new ApiCall(`/entity/entities/${id}?level_flag=CDL3`, "GET")
	},

	saveEntity(req) {
		return new ApiCall(`/entity/entities`, "PUT", req)
	},

	loadCompanyEmployees(company_id) {
		return new ApiCall(`/entity/entities/${company_id}/employees`, "GET")
	},

	loadOrgUnits(req) {
		return new ApiCall(`/entity/orgunits?level_flag=CD`, "POST", req)
	},

	loadOrgunit(id) {
		return new ApiCall(`/entity/orgunits/${id}?level_flag=CDL3`, "GET")
	},

	saveOrgunit(req) {
		return new ApiCall(`/entity/orgunits`, "PUT", req)
	},

	loadAimActivity(req) {
		return new ApiCall(`/etl/report/reqaimactivity`, "POST", req)
	},

	loadProjects(req) {
		return new ApiCall(`/etl/report/projects`, "POST", req)
	},

	loadOrgunitAddresses(orgunit_id) {
		return new ApiCall(`/entity/specificrels/${orgunit_id}/addresses?level_flag=CDL2`, "GET")
	},

	loadContacts(orgunit_id) {
		return new ApiCall(`/entity/specificrels/${orgunit_id}/contacts?level_flag=CD`, "GET")
	},

	loadOrgunitEmployees(orgunit_id) {
		return new ApiCall(`/entity/orgunits/${orgunit_id}/employees`, "GET")
	},

	loadOrgunitBankAccounts(orgunit_id) {
		return new ApiCall(`/entity/specificrels/${orgunit_id}/bankaccounts?level_flag=CDL2`, "GET")
	},

	loadExpenditures(req) {
		return new ApiCall(`/etl/report/expenditures`, "POST", req)
	},

	saveContact(req) {
		return new ApiCall(`/entity/contacts`, "PUT", req)
	},

	loadCity(req) {
		return new ApiCall(`/cl/Entity.CL.Address.City`, "POST", req)
	},

	loadZip(req) {
		return new ApiCall(`/cl/Entity.CL.Address.Zip`, "POST", req)
	},

	saveOrgunitAddress(req) {
		return new ApiCall(`/entity/addresses`, "PUT", req)
	},

	loadMeasurableIndicators(req) {
		return new ApiCall(`/etl/report/measurableindicatorsreport`, "POST", req)
	},

	loadOrgunitParams(req) {
		return new ApiCall(`/entity/orgunit-params`, "POST", req)
	},

	saveOrgunitParam(req) {
		return new ApiCall(`/entity/orgunit-params`, "PUT", req)
	},

	loadBanks() {
		return new ApiCall("/fin/banks", "GET")
	},

	saveOrgunitBankAccout(orgunit_id, req) {
		return new ApiCall(`/entity/specificrels/${orgunit_id}/bankaccounts`, "PUT", req)
	},

	saveEmployeeCard(req) {
		return new ApiCall(`/entity/specificrels`, "PUT", req)
	},

	loadContracts(req) {
		return new ApiCall(`/entity/contracts`, "POST", req)
	},

	saveContract(req) {
		return new ApiCall(`/entity/contracts`, "PUT", req)
	},

	removeContract(contract_id) {
		return new ApiCall(`/entity/contracts/${contract_id}`, "DELETE")
	},

	loadEntityUsers(req) {
		return new ApiCall(`/entity/entityusers?level_flag=CDL2`, "POST", req)
	},

	saveEntityUser(req) {
		return new ApiCall(`/entity/entityusers`, "PUT", req)
	},

	downloadFile(path, fileName) {
		const user = getUser()
		fetch(`${config.API_BASE_URL}${path}`, {
			method: "GET",
			mode: "cors",
			headers: {
				Authorization: `SIEA ${user.sieatoken || ""}`
			}
		})
			.then((response) => {
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName || "file")
				})
			})
			.catch(() => {})
		// return new ApiCall(`${path}`, "GET");
	},

	autocompleteFakeData(req) {
		return new ApiCall({
			customURL: "https://rmee.bigbee.sk/csp/rmee-int-public/test/autocompletedata",
			method: "POST",
			body: {
				req
			},
			includeToken: false
		})
	},

	saveCVSReport(req) {
		return new ApiCall(`/isee/cvs/report`, "PUT", req)
	},

	getCvsReports(req) {
		return new ApiCall(`/isee/cvs/reports`, "POST", req)
	},

	getCvsRecords(req) {
		return new ApiCall(`/isee/cvs/records`, "POST", req)
	},

	getCvsRecordById(recordId) {
		return new ApiCall(`/isee/cvs/record/${recordId}`, "POST")
	},

	saveCVSRecord(req) {
		return new ApiCall(`/isee/cvs/record`, "PUT", req)
	},

	deleteCVSReport(reportId) {
		return new ApiCall(`/isee/cvs/report/${reportId}`, "DELETE")
	},

	deleteCVSRecord(recordId) {
		return new ApiCall(`/isee/cvs/record/${recordId}`, "DELETE")
	},

	saveAttachments(req) {
		return new ApiCall(`/object/attachment`, "PUT", req)
	},

	saveAttachmentContent(id, content) {
		return new ApiCall({
			path: `/object/attachment/${id}/content`,
			method: "POST",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			body: content,
			transform: false
		})
	},

	getAttachments(type, id) {
		return new ApiCall(`/object/attachments/${type}/${id}`, "GET")
	},

	getAttachmentContent(uri) {
		return new ApiCall(`${uri}`, "GET")
	},

	getCVSIdentification(companyId) {
		return new ApiCall(`/entity/companydetail/${companyId}`, "GET")
	},

	getRecordHistory(req) {
		return new ApiCall(`/isee/cvs/energyconsumptionhistory`, "POST", req)
	},

	getOESRecordHistory(req) {
		return new ApiCall(`/isee/oes/quantitysoldhistory`, "POST", req)
	},

	getOESReports(req) {
		return new ApiCall(`/isee/oes/reports`, "POST", req)
	},

	saveOESReport(req) {
		return new ApiCall(`/isee/oes/report`, "PUT", req)
	},

	deleteOESReport(reportId) {
		return new ApiCall(`/isee/oes/report/${reportId}`, "DELETE")
	},

	getOESRecordsRegion(req) {
		return new ApiCall(`/isee/oes/records`, "POST", req)
	},

	saveOESRecord(req) {
		return new ApiCall(`/isee/oes/record`, "PUT", req)
	},

	getEOSReportById(reportId) {
		return new ApiCall(`/isee/oes/report/${reportId}`, "POST")
	},

	saveEOSReportNote(req) {
		return new ApiCall(`/isee/oes/reportnote`, "PUT", req)
	},

	deleteOESRegionRecords(req) {
		return new ApiCall(`/isee/oes/records`, "DELETE", req)
	},

	deleteOESRecord(recordId) {
		return new ApiCall(`/isee/oes/record/${recordId}`, "DELETE")
	},

	getOESRecordById(recordId) {
		return new ApiCall(`/isee/oes/record/${recordId}`, "POST")
	},

	getMedium(req) {
		return new ApiCall(`/isee/cl/energy/medium`, "POST", req)
	},

	getDistributionType(req) {
		return new ApiCall(`/cl/ISEE.CL.Distribution.Type`, "POST", req)
	},

	loadDistributionReports(req) {
		return new ApiCall(`/isee/distribution/reports`, "POST", req)
	},

	getDistributionReports(req) {
		return new ApiCall(`/isee/distribution/records`, "POST", req)
	},

	saveDistributionReport(req) {
		return new ApiCall(`/isee/distribution/record`, "PUT", req)
	},

	distributionReportName(req) {
		return new ApiCall(`/isee/cl/distribution/distributor`, "POST", req)
	},

	getDistributionReport(reportId) {
		return new ApiCall(`/isee/distribution/report/${reportId}`, "GET")
	},

	sendCvsReport(report_id, req) {
		return new ApiCall(`/isee/cvs/report/${report_id}/status`, "PUT", req)
	},

	sendOesReport(report_id, req) {
		return new ApiCall(`/isee/oes/report/${report_id}/status`, "PUT", req)
	},

	energyConsumptionGraph(req) {
		return new ApiCall(`/isee/cvs/energyconsumptiongraphdata`, "POST", req)
	},

	updateDistributionNote(req) {
		return new ApiCall(`/isee/distribution/reportnote`, "PUT", req)
	},

	getCVSReport(reportId) {
		return new ApiCall(`/isee/cvs/report/${reportId}`, "POST")
	},

	deleteRozvodyRecord(reportId) {
		return new ApiCall(`/isee/distribution/record/${reportId}`, "DELETE")
	},

	saveDistributionReportName(req) {
		return new ApiCall(`/isee/cl/distribution/distributor`, "PUT", req)
	},

	deleteDistributionReportName(id) {
		return new ApiCall(`/isee/cl/distribution/distributor/${id}`, "DELETE")
	},

	deleteRozvodyReport(reportId) {
		return new ApiCall(`/isee/distribution/report/${reportId}`, "DELETE")
	},

	getBuildings(req) {
		return new ApiCall(`/isee/eo/energetic-object`, "POST", req)
	},

	getCadastralCity(cityCode) {
		return new ApiCall(`/isee/cadastre/cadastralunitsbycity/${cityCode}`, "GET")
	},

	saveBuilding(req) {
		return new ApiCall(`/isee/eo/energetic-object`, "PUT", req)
	},

	getBuilding(objectId) {
		return new ApiCall(`/isee/eo/energetic-object/${objectId}`, "GET")
	},

	deleteBuilding(objectId) {
		return new ApiCall(`/isee/eo/energetic-object/${objectId}`, "DELETE")
	},

	getExtendCLCity(req) {
		return new ApiCall(`/isee/cl/city`, "POST", req)
	},

	sendRozvodyReport(report_id, req) {
		return new ApiCall(`/isee/distribution/report/${report_id}/status`, "PUT", req)
	},

	loadTitles() {
		//-public/client/register?g-recaptcha-response=
		return new ApiCall({
			path: `-public/cl/Entity.CL.Title`,
			method: "POST",
			// body: JSON.stringify(req),
			includeToken: false
		})
		// return new ApiCall({
		// 	customURL: `${config.API_PUBLIC_URL}/cl/Entity.CL.Title`,
		// 	method: "POST",
		// 	// body: {
		// 	// 	login,
		// 	// 	password
		// 	// },
		// 	includeToken: false
		// })
	},

	getParamTypes(req) {
		return new ApiCall(`/isee/eo/param-types`, "POST", req)
	},

	saveEnergeticObject(req) {
		return new ApiCall(`/isee/eo/energetic-object-review`, "PUT", req)
	},

	getEnergeticObjects(req) {
		return new ApiCall(`/isee/eo/energetic-object-review`, "POST", req)
	},

	getEnergeticObject(objId) {
		return new ApiCall(`/isee/eo/energetic-object-review/${objId}`, "GET")
	},

	deleteEnergeticObject(objectId) {
		return new ApiCall(`/isee/eo/energetic-object-review/${objectId}`, "DELETE")
	}
}
