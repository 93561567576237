import {observable, action} from "mobx"

import {getFilters} from "../../../../../global/helpers/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../../../global/actions/api"

class RmeeOrgunitParamsStore {
	@observable orgunitParamsData = []

	@observable isOpenOrgunitParamDialog = false

	orgunitParamFormRef = null

	@action loadParams(orgunit_id) {
		const req = getFilters([`org_unit=${orgunit_id}`])

		api
			.loadOrgunitParams(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows) && res.rows.length) {
					this.orgunitParamsData = res.rows
				} else {
					this.orgunitParamsData = []
				}
			})
	}

	@action openOrgunitParamDialog(orgunit_id, row) {
		if (isSafe(row) && isSafe(this.orgunitParamFormRef)) {
			this.orgunitParamFormRef.$("_id").value = row._id
			this.orgunitParamFormRef.$("param_type").value = isSafe(row.param_type) ? row.param_type : null
			this.orgunitParamFormRef.$("value").value = isSafe(row.value) ? row.value : null
		}

		this.orgunitParamFormRef.$("orgunit_id").value = orgunit_id

		this.isOpenOrgunitParamDialog = true
	}

	@action closeOrgunitParamDialog() {
		this.isOpenOrgunitParamDialog = false

		this.orgunitParamFormRef.reset()
	}

	@action saveOrgunitParam(form) {
		const values = form.values()

		const req = {
			_ref: false,
			_type: "Entity.Data.OrgUnitParam",
			_id: isNotEmpty(values._id) ? values._id : null,
			org_unit: values.orgunit_id,
			param_type: values.param_type,
			value: values.value
		}

		GlobalStore.loading(true)

		api
			.saveOrgunitParam(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Parameter úspešne uložený", "success")
					this.loadParams(values.orgunit_id)
					this.closeOrgunitParamDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní parametra došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeOrgunitParam(param_id, orgunit_id) {
		const req = {active: false, _id: param_id, _ref: false, _type: "Entity.Data.OrgUnitParam"}

		GlobalStore.loading(true)

		api
			.saveOrgunitParam(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Parameter úspešne odstránený", "success")
					this.loadParams(orgunit_id)
				} else {
					GlobalStore.setNotificationMessage("Pri odstráňovaní parametra došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeOrgunitParamsStore()
export default singleton
