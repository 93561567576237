"use strict"

import React, {useEffect} from "react"
import {inject, observer} from "mobx-react"
import {injectIntl} from "react-intl"
import classnames from "classnames"

import {Box, Grid, Paper, Tab, Tabs} from "@material-ui/core"

import XsBreadcrumbs from "../../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"
import IdentificationContainer from "./identificationData/identificationContainer"
import ConstructionContainer from "./constTechData/constructionContainer"
import CertificatesContainer from "./certificates/certificatesContainer"

import RouterStore from "../../../../global/store/RouterStore"
import BudovyBuildingDetailStore from "./budovyBuildingDetailStore"
import BuildingIdentificationStore from "./identificationData/buildingIdentificationStore"

import GlobalStore from "../../../../global/store/GlobalStore"

function BuildingContainer(props) {
	const building_id = new URLSearchParams(props.routing.location.search).get("id")

	useEffect(() => {
		GlobalStore.main_menu_selected = "budovy"
		BudovyBuildingDetailStore.buildingTabValue = "identification"
	}, [])

	const handleChange = (event, value) => {
		BudovyBuildingDetailStore.buildingTabValue = value
	}

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="budovy_building_container">
			<Box pb={2}>
				<XsBreadcrumbs
					links={[
						{
							label: "ISEE",
							action: () => {
								RouterStore.push(`uvod`)
							}
						},
						{
							label: "Budovy",
							action: () => {
								RouterStore.push(`budovy`)
							}
						},
						{
							label: "Energetické objekty",
							action: () => {
								RouterStore.push(`budovy`)
							}
						}
						// {label: "Report za rok 2020", action: () => RouterStore.push(`company?id=${props.company_id}`)}
					]}
					text={`Identifikačné údaje`}
				/>
			</Box>
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>Energetický objekt ID: {BuildingIdentificationStore.objName}</Box>
				</Grid>
			</Grid>
			<Paper className="budovy_paper">
				<Tabs
					value={BudovyBuildingDetailStore.buildingTabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						className={classnames({"xs-active": BudovyBuildingDetailStore.buildingTabValue == "identification"})}
						value="identification"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-building fa-lg"></i>
								</Grid>
								<Grid item>Identifikačné údaje</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": BudovyBuildingDetailStore.buildingTabValue == "construction"})}
						value="construction"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-wrench fa-lg"></i>
								</Grid>
								<Grid item>Stavebno-technické údaje</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": BudovyBuildingDetailStore.buildingTabValue == "certificates"})}
						value="certificates"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-wrench fa-lg"></i>
								</Grid>
								<Grid item>Posudky / certifikáty</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{BudovyBuildingDetailStore.buildingTabValue == "identification" && (
					<IdentificationContainer building_id={building_id} />
				)}
				{BudovyBuildingDetailStore.buildingTabValue == "construction" && (
					<ConstructionContainer building_id={building_id} />
				)}
				{BudovyBuildingDetailStore.buildingTabValue == "certificates" && (
					<CertificatesContainer building_id={building_id} />
				)}
			</Paper>
		</Box>
	)
}

export default inject("routing")(injectIntl(observer(BuildingContainer)))
