import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				user_id: {},
				person_id: {},
				login: {
					label: <FormattedMessage id="Common.label.login" />,
					rules: "required"
				},
				password: {
					label: <FormattedMessage id="Common.label.psw" />,
					rules: "required"
				},
				repeat_password: {
					label: <FormattedMessage id="Common.label.repeatPsw" />,
					rules: "required"
				},
				email: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "required"
				},
				enabled: {
					label: <FormattedMessage id="Common.label.enabled" />,
					type: "checkbox",
					value: false
				},
				confirmed: {
					label: <FormattedMessage id="Common.label.confirmed" />,
					type: "checkbox",
					value: false
				}
			}
		}
	}
}
