import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				company_id: {},
				employee_id: {},
				employee_category: {
					label: <FormattedMessage id="Common.label.category" />,
					rules: "required"
				},
				personal_number: {
					label: <FormattedMessage id="Common.label.personalNumber" />
				},
				card_number: {
					label: <FormattedMessage id="Common.label.cardNumber" />
				},
				arrival_date: {
					label: <FormattedMessage id="Common.label.arrivalDate" />,
					type: "date"
				},
				departure_date: {
					label: <FormattedMessage id="Common.label.departureDate" />,
					type: "date"
				}
			}
		}
	}
}
