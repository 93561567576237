import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeOrgunitContactsStore {
	@observable orgunitContactsData = []

	@observable isOpenOrgunitContactDialog = false

	orgunitContactFormRef = null

	@action loadContacts(orgunit_id) {
		api
			.loadContacts(orgunit_id)
			.call()
			.then((res) => {
				if (isSafe(res) && res.length) {
					this.orgunitContactsData = res
				} else {
					this.orgunitContactsData = []
				}
			})
	}

	@action openOrgunitContactDialog(orgunit_id, row) {
		if (isSafe(row) && isSafe(this.orgunitContactFormRef)) {
			this.orgunitContactFormRef.$("_id").value = row._id
			this.orgunitContactFormRef.$("type").value = isSafe(row.contact_type) ? row.contact_type._id : null
			this.orgunitContactFormRef.$("mobile").value = row.mobile
			this.orgunitContactFormRef.$("phone").value = row.phone
			this.orgunitContactFormRef.$("email").value = row.email
			this.orgunitContactFormRef.$("web_page").value = isSafe(row.web_page) ? row.web_page : ""
			this.orgunitContactFormRef.$("description").value = row.description
			this.orgunitContactFormRef.$("primary").value = row.primary
		}

		this.orgunitContactFormRef.$("orgunit_id").value = orgunit_id

		this.isOpenOrgunitContactDialog = true
	}

	@action closeOrgunitContactDialog() {
		this.isOpenOrgunitContactDialog = false

		this.orgunitContactFormRef.reset()
	}

	@action saveOrgunitContact(form) {
		const values = form.values()

		const req = {
			_id: isNotEmpty(values._id) ? values._id : "",
			_type: "Entity.Data.Contact",
			contact_type: {
				_id: values.type
			},
			email: values.email,
			mobile: values.mobile,
			phone: values.phone,
			web_page: values.web,
			description: values.description,
			primary: values.primary,
			specific_rel: {
				_type: "Entity.Data.OrgUnit",
				_id: values.orgunit_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveContact(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Kontakt úspešne uložený", "success")
					this.loadContacts(values.orgunit_id)
					this.closeOrgunitContactDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní kontaktu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeOrgunitContact(contact_id, orgunit_id) {
		const req = {active: false, _id: contact_id, _ref: false, _type: "Entity.Data.Contact"}

		GlobalStore.loading(true)

		api
			.saveContact(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Kontakt úspešne odstránený", "success")
					this.loadContacts(orgunit_id)
				} else {
					GlobalStore.setNotificationMessage("Pri odstráňovaní kontaktu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeOrgunitContactsStore()
export default singleton
