"use strict"

import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import classnames from "classnames"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {List, Divider, Drawer, ListItem, ListItemText, ListItemIcon, Tooltip} from "@material-ui/core"

import EtlStore from "./etlStore"

const drawerWidth = 240

const styles = makeStyles((theme) => ({
	root: {
		display: "flex"
	},
	drawerPaper: {
		position: "relative",
		height: "100%",
		overflowX: "hidden"
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	hide: {
		display: "none"
	},
	drawer: {
		height: "100%",
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
		borderRight: "1px solid rgba(0,0,0,0.12)"
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1
		}
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		minHeight: "48px !important"
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}))

function etlDrawer() {
	const classes = styles()
	const [open, setOpen] = useState(false)
	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const drawerData = [
		{
			id: "state",
			label: "Stav importu dát",
			icon: <i className={classnames("far fa-file-import fa-lg", classes.drawerIcon)} />
		},
		{
			id: "aim",
			label: "Výzva cieľ aktivita",
			icon: <i className={classnames("far fa-crosshairs fa-lg", classes.drawerIcon)} />
		},
		{
			id: "projects",
			label: "Projekty",
			icon: <i className={classnames("far fa-file-powerpoint fa-lg", classes.drawerIcon)} />
		},
		{
			id: "expenditures",
			label: "Výdavok ŽOP",
			icon: <i className={classnames("far fa-file-invoice-dollar fa-lg", classes.drawerIcon)} />
		},
		{
			id: "indicators",
			label: "Merateľné ukazovatele",
			icon: <i className={classnames("far fa-ruler-horizontal fa-lg", classes.drawerIcon)} />
		}
	]

	return (
		<Drawer
			variant="permanent"
			className={classnames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open
			})}
			classes={{
				paper: classnames(classes.drawerPaper, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open
				})
			}}
		>
			<div className={classes.toolbar}>
				{open ? (
					<i className="far fa-chevron-left fa-lg pointer" onClick={() => handleDrawerClose()}></i>
				) : (
					<i className="far fa-chevron-right fa-lg pointer" onClick={() => handleDrawerOpen()}></i>
				)}
			</div>
			<Divider />
			<List>
				{drawerData.map((data, key) => {
					return (
						<ListItem
							key={key}
							button
							onClick={() => {
								EtlStore.etlTabId = data.id
							}}
							className={classnames({bgGreyLight: EtlStore.etlTabId == data.id})}
						>
							<Tooltip title={data.label} placement="right">
								<ListItemIcon>{data.icon}</ListItemIcon>
							</Tooltip>
							<ListItemText>{data.label}</ListItemText>
						</ListItem>
					)
				})}
			</List>
		</Drawer>
	)
}

export default injectIntl(observer(etlDrawer))
