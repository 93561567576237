"use strict"

import {observable, action} from "mobx"
import api from "../config/api"

class GlobalStore {
	@observable isLoading = false
	@observable loadingMessage = ""

	@observable main_menu_selected = ""

	@observable CL = {}
	@observable confirmationDialogOpened = undefined
	@observable authentificationFailed = false

	confParams = {}

	@observable notification = {message: "", variant: "default"}

	BLConfiguration = undefined
	@observable BLConfigurationLoaded = false

	defaultFocusFields = {}

	region = {
		SK010: "BA",
		SK021: "TT",
		SK022: "TN",
		SK023: "NR",
		SK031: "ZA",
		SK032: "BB",
		SK041: "PO",
		SK042: "KE"
	}

	@action loading(isLoading, message = "") {
		this.isLoading = isLoading
		this.loadingMessage = message
	}

	@action handleClickFocus(formName, isTextArea = false) {
		if (isTextArea) {
			if (isSafe(this.defaultFocusFields[formName]) && isSafe(this.defaultFocusFields[formName].reactQuillRef)) {
				this.defaultFocusFields[formName].reactQuillRef.focus()
			}
		} else {
			if (isSafe(this.defaultFocusFields[formName])) {
				this.defaultFocusFields[formName].focus()
			}
		}
	}

	@action GetBLConfiguration() {
		if (isNotSafe(this.BLConfiguration)) {
			api.loadTypeRoutes().then((c) => ((this.BLConfiguration = c), (this.BLConfigurationLoaded = true)))
		}
	}

	@action openConfirmationDialog(dialogName, params) {
		this.confParams = params
		this.confirmationDialogOpened = dialogName
	}

	@action closeConfirmationDialog() {
		this.confParams = {}
		this.confirmationDialogOpened = undefined
	}

	setNotificationMessage(message, variant) {
		if (isNotEmpty(message)) {
			this.notification = {
				message: message,
				variant: variant
			}
		}
	}
}

var singleton = new GlobalStore()
export default singleton
