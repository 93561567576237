"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Typography} from "@material-ui/core"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"

function xsBreadcrumbs(props) {
	return (
		<Breadcrumbs aria-label="breadcrumb">
			{isSafe(props.links) &&
				props.links.map((link, key) => {
					return (
						<Link key={key} color="inherit" onClick={() => link.action()} style={{"cursor": "pointer"}}>
							{link.label}
						</Link>
					)
				})}
			<Typography color="textPrimary">{props.text}</Typography>
		</Breadcrumbs>
	)
}

export default injectIntl(observer(xsBreadcrumbs))
