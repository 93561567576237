"use strict"

import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeCompanyFields"

import { injectIntl, FormattedMessage } from "react-intl"
import { Grid, Typography, Box } from "@material-ui/core"
import XsButton from "../../../global/ui/xsButton/xsButton"
import XsInput from "../../../global/ui/xsInput/xsInput"
import XsBreadcrumbs from "../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"

import RmeeStore from "../rmeeStore"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"

function RmeeCompanyForm(props) {
	const hooks = {
		onSubmit() { },
		onSuccess(form) {
			RmeeStore.saveCompany(form, setDisabled)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), { plugins: { dvr: dvr(validatorjs) }, hooks }))
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		RmeeStore.loadCompany(props.company_id, form)

		return () => {
			form.reset()
		}
	}, [])

	return (
		<Grid container direction="column">
			<Grid item>
				<Box pt={2} pb={1}>
					<XsBreadcrumbs
						links={[
							{
								label: "ISEE",
								action: () => {
									RouterStore.push(`uvod`)
								}
							},
							{ label: "Spoločnosti", action: () => RouterStore.push(`rmee`) }]}
						text={form.$("title").value}
					/>
				</Box>
			</Grid>
			<Grid item>
				<Box pb={2}>
					<Typography variant="h5">Spoločnosť - {form.$("title").value}</Typography>
				</Box>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<XsInput field={form.$("ico")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("name")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("short_name")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("dic")} disabled={disabled} />
				</Grid>
				<Grid item xs={3}>
					<XsInput field={form.$("icdph")} disabled={disabled} />
				</Grid>
				<Grid item xs={9}>
					<XsInput field={form.$("description")} disabled={disabled} />
				</Grid>
			</Grid>
			<Box mt={2}>
				<Grid container spacing={1} justify="flex-end">
					{disabled ? (
						<Grid item>
							<XsButton
								icon={<i className="fal fa-pencil-alt"></i>}
								text={<FormattedMessage id="Common.btn.edit" />}
								onClick={() => setDisabled(false)}
							/>
						</Grid>
					) : (
						<React.Fragment>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-times"></i>}
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										setDisabled(true)
										RmeeStore.loadCompany(form.$("_id").value, form)
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-save"></i>}
									text={<FormattedMessage id="Common.btn.save" />}
									onClick={form.onSubmit}
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</Box>
		</Grid>
	)
}

export default injectIntl(observer(RmeeCompanyForm))
