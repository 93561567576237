"use strict"

import settings from "../config/settings"

window.isSafe = (value) => {
	return value !== undefined && value !== null
}

window.isNotSafe = (value) => {
	return value === undefined || value === null
}

window.isEmpty = (value) => {
	let type = typeof value
	if (value === undefined || value === null) return true
	else if (type === "string") {
		return value === "" && value.trim() === ""
	} else {
		return isNotSafe(value)
	}
}

window.isNotEmpty = (value) => {
	let type = typeof value
	if (value === undefined || value === null) return false
	else if (type === "string") {
		return value !== "" && value.trim() !== ""
	} else {
		return isSafe(value)
	}
}

window.isNumber = (value) => {
	if (isNotEmpty(value) && Number.isInteger(+value)) {
		return true
	}
	return false
}

window.isPrice = (value) => {
	if (isNotEmpty(value) && /^(\d+(\.|\,)?\d{0,6})$/.test(value)) {
		return true
	}
	return false
}

String.prototype.shortTo = function(length) {
	if (this.length > length) {
		return this.substring(0, length) + "..."
	}

	return this
}

window.getPriceFormat = (price) => {
	return price.toLocaleString("sk-SK", {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

window.equalsArr = (arr1, arr2) => {
	// if the other arr1 or arr2 is a falsy value, return
	if (!arr1) return false
	if (!arr2) return false

	// compare lengths - can save a lot of time
	if (arr1.length != arr2.length) return false

	for (var i = 0, l = arr1.length; i < l; i++) {
		// Check if we have nested arrays
		if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
			// recurse into the nested arrays
			if (!arr1[i].equals(arr2[i])) return false
		} else if (arr1[i] != arr2[i]) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false
		}
	}
	return true
}

window.htmlToPlain = (text) => {
	if (text) {
		return String(text)
			.replace(/&nbsp;/gm, " ")
			.replace(/<p/gm, "\n<p")
			.replace(/<\/p/gm, "\n</p")
			.replace(/<div/gm, "\n<div")
			.replace(/<\/div/gm, "\n</div")
			.replace(/<br/gm, "\n<br")
			.replace(/<[^>]+>/gm, "")
			.replace(/\n\n/gm, "\n")
			.trim()
	} else {
		return ""
	}
}

window.b64DecodeUnicode = (str) => {
	const decodedStr = atob(str).replace(/>\s+</g, "><")
	const startIdx = decodedStr.toUpperCase().indexOf("<HTML")
	const content = decodedStr.substr(startIdx)
	const startIdxImg = content.toUpperCase().indexOf("<IMG ")
	let endIdxImg = -1
	if (startIdxImg !== -1) {
		endIdxImg = content.toUpperCase().indexOf("/>", startIdxImg)
	}
	return decodeURIComponent(
		content
			.split("")
			.map(function(c, i) {
				return i >= startIdxImg && i <= endIdxImg ? c : "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)
}

window.delayedCallback = (() => {
	var timer = 0
	return (delay, callback) => {
		clearTimeout(timer)
		timer = setTimeout(() => callback(timer + 1), delay)
	}
})()

window.logger = settings.DEBUG ? console.log.bind(window.console) : () => {} //eslint-disable-line

window.warn = settings.DEBUG ? console.warn.bind(window.console) : () => {} //eslint-disable-line

window.deepCopy = (obj) => {
	return JSON.parse(
		JSON.stringify(obj, (_, value) => (typeof value === "function" ? value.toString() : value)),
		(_, value) => (typeof value !== "string" ? value : value.startsWith("function") ? eval(`(${value})`) : value)
	)
}

window.base64EncodeUnicode = (str) => {
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
			return String.fromCharCode("0x" + p1)
		})
	)
}

window.base64DecodeUnicode = (str) => {
	return decodeURIComponent(
		atob(str)
			.split("")
			.map(function(c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)
}

window.removePunctuation = (str) => {
	return str.replace(/[.,\/#!\?$%\^&\*;:{}=\-_`~()"„“‚‚‘«»\[\]’'〈〉@|§]/gi, "")
}

window.objectChanges = (oldObj, newObj) => {
	return Object.keys(oldObj)
		.map((prop) => (oldObj[prop] !== newObj[prop] ? {prop, oldVal: oldObj[prop], newVal: newObj[prop]} : null))
		.filter(isSafe)
}

window.htmlStyleToPlain = (text) => {
	if (isNotEmpty(text)) {
		return text.replace('style=\\"', 'style="').replace(';\\"', ';"') // eslint-disable-line
	} else {
		return ""
	}
}

window.b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
	const byteCharacters = atob(b64Data)
	const byteArrays = []

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize)

		const byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}

		const byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}

	const blob = new Blob(byteArrays, {type: contentType})
	return blob
}

export default undefined
