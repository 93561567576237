import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Box, Grid, Container, Typography} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"

import RouterStore from "../../store/RouterStore"
import GlobalStore from "../../store/GlobalStore"

import {logout} from "../../helpers/actions"
import XsMenu from "../xsMenu/xsMenu"

function XsHeader() {
	return (
		<div className="main_header">
			<Container maxWidth={false}>
				<Grid container alignItems="center" className="pt-1">
					<Grid item container style={{width: 200}}>
						<Grid item>
							<Box
								mr={6}
								onClick={() => {
									GlobalStore.main_menu_selected = ""
									RouterStore.push("/uvod")
								}}
								className="pointer white"
							>
								<Typography variant="h6">ISEE</Typography>
								{/* <img src="/public/images/logo_rmee_50.png" style={{width: 50}} /> */}
							</Box>
						</Grid>
					</Grid>
					<Grid item container xs alignItems="center" justify="space-between">
						<Grid item container xs alignItems="center">
							<Grid item>
								<XsButton
									icon={<i className="fal fa-analytics fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "cvs" ? "white" : "menuOpacity"}
									text="CVS" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "cvs"
										RouterStore.push("/cvs")
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-euro-sign fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "oes" ? "white" : "menuOpacity"}
									text="OES" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "oes"
										RouterStore.push("/oes")
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-analytics fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "rmee" ? "white" : "menuOpacity"}
									text="ISEE" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "rmee"
										RouterStore.push("/rmee")
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fas fa-file-signature fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "etl" ? "white" : "menuOpacity"}
									text="ETL" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "etl"
										RouterStore.push("/etl")
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fas fa-timeline fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "rozvody" ? "white" : "menuOpacity"}
									text="Rozvody" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "rozvody"
										RouterStore.push("/rozvody")
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									icon={<i className="fal fa-building fa-lg"></i>}
									variant="text"
									className={GlobalStore.main_menu_selected == "budovy" ? "white" : "menuOpacity"}
									text="Budovy" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										GlobalStore.main_menu_selected = "budovy"
										RouterStore.push("/budovy")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item container xs justify="flex-end">
							<XsMenu
								default={GlobalStore.userName}
								items={[
									{
										name: " Úprava profilu",
										icon: <i className="fal fa-cog"></i>,
										onClick: () => {
											// RouterStore.push("/settings")
											// UIStore.mainMenuTab = ""
										}
									},
									{
										name: " Log out",
										className: "menuitem-logout",
										onClick: async () => {
											logout()
										},
										icon: <i className="fas fa-power-off"></i>
									}
								]}
							/>
							{/* <Grid item>
								<XsButton
									icon={<i className="fas fa-sign-out-alt fa-lg"></i>}
									variant="text"
									className="white"
									text="Log out"
									onClick={() => logout()}
								/>
							</Grid> */}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default injectIntl(observer(XsHeader))
