"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import classnames from "classnames"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import OesReportRecordStore from "./oesReportRecordStore"
import GlobalStore from "../../../../global/store/GlobalStore"

function ListOfRecords({data, year, report_id, isDisabled}) {
	const columns = [
		{
			Header: "Energonosič",
			width: 100,
			accessor: "medium_name_ext",
			sortType: "basic"
		},
		{
			Header: "Skupina odberateľov",
			width: 150,
			accessor: "consumer_group_name_ext",
			sortType: "basic"
		},
		{
			Header: () => <div className="textRight">Predané množstvo [MWh]</div>,
			width: 100,
			accessor: "quantity_sold",
			sortType: "basic",
			aggregate: "sum",
			Cell: (row) => <div className="textRight">{row.value.toLocaleString("sk-sk", {minimumFractionDigits: 3})}</div>,
			Aggregated: ({value}) => (
				<div className="textRight">{value.toLocaleString("sk-sk", {minimumFractionDigits: 3})}</div>
			)
		},
		{
			Header: () => <div className="textRight">Odberateľov</div>,
			width: 75,
			accessor: "consumer_count",
			sortType: "basic",
			aggregate: "sum",
			Cell: (row) => <div className="textRight">{row.value.toLocaleString("sk-sk")}</div>,
			Aggregated: ({value}) => <div className="textRight">{value.toLocaleString("sk-sk")}</div>
		},
		{
			Header: "Poznámky",
			width: 150,
			accessor: "note",
			sortType: "basic"
		},
		{
			clickable: false,
			width: 10,
			accessor: "record_id",
			Cell: (obj) => {
				return (
					<React.Fragment>
						{isNotEmpty(obj.value) && (
							<Grid container justify="flex-end">
								<Grid item>
									<i
										className={classnames("fas fa-trash-alt fa-lg", {
											"redDanger pointer": !isDisabled,
											greyLight: isDisabled
										})}
										onClick={() =>
											isDisabled
												? null
												: GlobalStore.openConfirmationDialog("removeRecord", {
														record_id: obj.value,
														region_id: obj.row.original.region_id
												  })
										}
									></i>
								</Grid>
							</Grid>
						)}
					</React.Fragment>
				)
			}
		}
	]

	return (
		<React.Fragment>
			<XsTable
				noPagination
				columns={columns}
				data={data}
				groupBy="medium_name_ext"
				colorful
				size="small"
				onRowClick={(row) => {
					if (isSafe(row) && isNotEmpty(row.record_id)) {
						OesReportRecordStore.getRecordById(row.record_id, year)
					}
				}}
			/>
			<XsConfirmationDialog
				type="danger"
				name="removeReport"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				title="Skutočne chcete odstrániť záznam?"
				text={`Chystáte sa odstrániť záznam. Skutočne to chcete urobiť?`}
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.delete"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.back"
				}}
				onConfirmation={() => {
					OesReportRecordStore.deleteRecord(
						report_id,
						GlobalStore.confParams.record_id,
						data.length > 1 ? GlobalStore.confParams.region_id : null
					)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(ListOfRecords))
