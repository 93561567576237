"use strict"

import codeListApi from "../../global/config/api"
import { observable, action } from "mobx"
import { get, set } from "idb-keyval"
import { codeListsConfig } from "../../global/config/codeLists"
import GlobaStore from "../../global/store/GlobalStore"
import { getFilters } from "../../global/helpers/api"
import { toJS } from "mobx"

import api from "../actions/api"

class RegistersCacheStore {
	@observable registersLoaded = true
	registersLoading = false

	mediumCVS = []
	mediumOES = []

	@action async loadCodelists() {
		const codelistsFromStorage = await get("codeLists")
		let versions = await get("versions")

		if (isSafe(codelistsFromStorage) && isSafe(versions) && isSafe(versions.codelist)) {
			let versionsObj = {}
			const codelistVersions = versions.codelist
			GlobaStore.CL = codelistsFromStorage
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let codelistResponse = await codeListApi.loadCodeList(cl.uri, "POST", { version: codelistVersions[cl.name] })
				if (isSafe(codelistResponse) && isSafe(codelistResponse.rows)) {
					GlobaStore.CL[cl.name] = codelistResponse.rows
					versionsObj[cl.name] = codelistResponse.version
				} else {
					versionsObj[cl.name] = codelistVersions[cl.name]
				}
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = { codelist: versionsObj }
			}

			await set("codeLists", toJS(GlobaStore.CL))
			await set("versions", versions)
		} else {
			let versionsObj = {}
			for (let clIndex in codeListsConfig) {
				let cl = codeListsConfig[clIndex]
				let val = await codeListApi.loadCodeList(cl.uri)

				GlobaStore.CL[cl.name] = val.rows
				versionsObj[cl.name] = val.version
			}

			if (isSafe(versions)) {
				versions.codelist = versionsObj
			} else {
				versions = { codelist: versionsObj }
			}

			await set("codeLists", toJS(GlobaStore.CL))
			await set("versions", versions)
		}
	}

	@action async checkRegistersTest() {
		let promise = new Promise((resolve) => {
			setTimeout(() => resolve(true), 2000)
		})
		let result = await promise
		return result
	}

	@action async loadMediums() {
		const reqOES = getFilters([`medium_id=OES`])
		const reqCVS = getFilters([`medium_id=CVS`])

		const mediumOES = await api.getMedium(reqOES).call()
		const mediumCVS = await api.getMedium(reqCVS).call()

		this.mediumOES = mediumOES.rows
		this.mediumCVS = mediumCVS.rows
	}

	//Kontrola ci su loadnute v store data, v pripade ze je uzivatel na login obrazovke vymaze sa obsah IndexedDB a po logine zbehne kontrola a loadnu sa data z BE
	@action async checkRegisters(route) {
		if (route != "/" && route != "/login" && route != "/register" && route != "/registerFinish" && route != "/reset") {
			if (!this.registersLoading && Object.keys(GlobaStore.CL).length == 0) {
				this.registersLoading = true
				this.registersLoaded = false

				if (Object.keys(GlobaStore.CL).length == 0) {
					//Load vsetkych CL do IndexedDB
					await this.loadCodelists()
				}

				if (this.mediumCVS.length === 0) {
					await this.loadMediums()
				}

				this.registersLoading = false
				this.registersLoaded = true
			}
		}
	}
}
var singleton = new RegistersCacheStore()
export default singleton
