import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"
import InfoStore from "../../store/InfoStore"

import "./xsDialog.less"

export default injectIntl(
	observer(() => {
		return (
			<Dialog open={InfoStore.isOpen} onEscapeKeyDown={() => InfoStore.close()} className="xs-info-dialog">
				<DialogTitle className="xs-title">
					<Grid container>
						<Grid item>
							<Typography variant="h6">
								{isSafe(InfoStore.title) ? InfoStore.title : <FormattedMessage id="Common.label.information" />}
							</Typography>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container>
						<Grid item>
							<Typography variant="body2">{isSafe(InfoStore.content) ? InfoStore.content : ""}</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					{isSafe(InfoStore.actions) ? (
						InfoStore.actions
					) : (
						<XsButton
							icon={<i className="fal fa-check fa-lg" />}
							text={<FormattedMessage id="Common.label.ok" />}
							onClick={() => InfoStore.close()}
						/>
					)}
				</DialogActions>
			</Dialog>
		)
	})
)
