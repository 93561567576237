import {observable, action} from "mobx"

import {getFilters} from "../../../../../global/helpers/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../../../global/actions/api"

class BuildingCertificatesStore {
	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@observable energeticObjectData = []

	@observable isOpenCertificateDialog = false

	@observable objId = undefined

	@action openCertificateDialog(objId = undefined) {
		this.objId = objId

		this.isOpenCertificateDialog = true
	}

	@action closeCertificateDialog() {
		this.isOpenCertificateDialog = false
		this.dropedFiles = []
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachments(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action getEnergeticObjects(buildingId) {
		const req = getFilters([`energetic_object_id=${buildingId}`])

		api
			.getEnergeticObjects(req)
			.call()
			.then((res) => {
				if (res && res.rows) {
					this.energeticObjectData = res.rows
				} else {
					this.energeticObjectData = []
				}
			})
	}

	generateDataAndSave(form, fn) {
		if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
			let req = []
			let files = this.dropedFiles
			var saveFunction = this.saveEnergeticObject.bind(this)
			let binaryDataArr = []

			let getdata = function(index) {
				let data = files[index]
				if (isSafe(data) && isSafe(data._id)) {
					req.push({
						_id: data._id
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, fn)
					}
				} else {
					let binaryData
					var reader = new FileReader()
					reader.readAsBinaryString(data)
					reader.onloadend = function() {
						binaryData = reader.result
						binaryDataArr.push(binaryData)
						req.push({
							content: btoa(binaryData),
							file_name: data.name
						})
						if (files.length > index + 1) {
							getdata(index + 1)
						} else {
							saveFunction(form, req, fn)
						}
					}
				}
			}
			getdata(0)
		} else {
			this.saveEnergeticObject(form, [], fn)
		}
	}

	@action saveEnergeticObject(form, dropzoneData, fn) {
		const values = form.values()

		const req = {
			_id: this.objId ? this.objId : null /* Pri update uviest */,
			energetic_object_id: values.energeticObjectId /* ID budovy */,
			floor_area: values.floor_area /* Podlahova plocha */,
			identifier: values.number_of_cert /* Cislo/kod */,
			issue_date: values.date_of_issue /* Datum vystavenia */,
			name: values.name /* Nazov */,
			note: values.note /* Poznamka */,
			type_id: values.type_of_cert /* Typ - z ciselnika /cl/ISEE.CL.EnergeticObject.ReviewType */,
			attachments: dropzoneData /* Prilohy */
		}

		api
			.saveEnergeticObject(req)
			.call()
			.then((res) => {
				if (res && res._id) {
					this.getEnergeticObjects(values.energeticObjectId)
					fn()
					if (this.objId) {
						GlobalStore.setNotificationMessage("Energetický objekt úspešne upravený", "success")
					} else {
						GlobalStore.setNotificationMessage("Energetický objekt úspešne uložený", "success")
					}
				}
			})
	}

	@action removeEnergeticObject(objectId, buildingId) {
		api
			.deleteEnergeticObject(objectId)
			.call()
			.then(() => {
				this.getEnergeticObjects(buildingId)
				GlobalStore.setNotificationMessage("Energetický objekt úspešne odstránený", "success")
			})
	}
}

var singleton = new BuildingCertificatesStore()
export default singleton
