"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container} from "@material-ui/core"

import RmeeCompaniesTable from "./rmeeCompaniesTable"
import RmeeCompanyDetail from "./rmeeCompanyDetail"
import RmeeCompanyDialog from "./rmeeCompanyDialog"

import RmeeStore from "../rmeeStore"

function RmeeCompaniesContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y">
			{RmeeStore.isOpenCompanyDetail ? <RmeeCompanyDetail /> : <RmeeCompaniesTable />}
			{RmeeStore.isOpenCompanyDialog && <RmeeCompanyDialog />}
		</Container>
	)
}

export default injectIntl(observer(RmeeCompaniesContainer))
