"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./recordDialogFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import OesReportRecordStore from "./oesReportRecordStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"

function RecordDialog({isDisabled}) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			let ok = true

			// presun energonosica na ine oddelenie
			if (form.$("region_id").value !== form.$("curr_region_id").value) {
				const otherRegion = OesReportRecordStore.existMediumInRegion[form.$("region_id").value]

				//ak existuje energonosic na inom oddeleni
				if (otherRegion && otherRegion[form.$("medium_id").value]) {
					const mediumGroup = otherRegion[form.$("medium_id").value]
					//ak existuje aj skupina odberatelov, vypiseme chybu
					if (mediumGroup.includes(form.$("consumer_group_id").value)) {
						ok = false
					}
				}
			}

			ok
				? OesReportRecordStore.saveRecord(form)
				: GlobalStore.setNotificationMessage(
						`V regióne sa už energonosič s danou skupinou odberateľov už nachádza`,
						"warning"
				  )
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		OesReportRecordStore.formRef = form
	}, [])

	return (
		<Dialog
			open={OesReportRecordStore.isOpenRecordDialog}
			onEscapeKeyDown={() => OesReportRecordStore.closeRecordDialog(form)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="h6">
							<FormattedMessage id="Common.label.addEnergonosic" />
						</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => OesReportRecordStore.closeRecordDialog(form)}
					></i>
					{/* <Grid item>
						<Typography variant="h6">
							<FormattedMessage id="Common.label.warning" />
						</Typography>
					</Grid> */}
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<XsSearchSelect disabled={isDisabled} field={form.$("region_id")} items={GlobalStore.CL["addressRegion"]} />
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							disabled={isDisabled}
							field={form.$("medium_id")}
							items={
								OesReportRecordStore.existMediumInRegion[form.$("region_id").value]
									? RegistersCacheStore.mediumOES.filter(
											(x) =>
												!Object.keys(OesReportRecordStore.existMediumInRegion[form.$("region_id").value]).includes(
													x._id
												) || form.$("curr_medium_id").value == x._id
									  )
									: RegistersCacheStore.mediumOES
							}
							onChange={() => {
								if (isNotEmpty(form.$("consumer_group_id").value)) {
									OesReportRecordStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							disabled={isDisabled}
							field={form.$("consumer_group_id")}
							items={GlobalStore.CL["consumerGroup"]}
							onChange={() => {
								if (isNotEmpty(form.$("medium_id").value)) {
									OesReportRecordStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput
							disabled={isDisabled}
							field={form.$("quantity_sold")}
							onChange={(val) => {
								if (val.includes(",")) {
									form.$("quantity_sold").value = val.replace(",", ".")
								}
							}}
							endText={
								isNotEmpty(form.$("quantity_sold_history").value) ? `(${form.$("quantity_sold_history").value})` : ""
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput
							disabled={isDisabled}
							field={form.$("consumer_count")}
							onChange={(val) => {
								if (val.includes(",")) {
									form.$("consumer_count").value = val.replace(",", ".")
								}
							}}
							endText={
								isNotEmpty(form.$("consumer_count_history").value) ? `(${form.$("consumer_count_history").value})` : ""
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={form.$("note")} disabled={isDisabled} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={1}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => OesReportRecordStore.closeRecordDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							disabled={isDisabled}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(RecordDialog))
