import {action} from "mobx"

import {getCompanyId} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"

import api from "../../../global/actions/api"

class CvsIdentificationStore {
	@action getCompanyInfo(form) {
		api
			.getCVSIdentification(getCompanyId())
			.call()
			.then((company) => {
				if (isSafe(company)) {
					form.$("name").value = company.company_name
					form.$("ico").value = company.company_identifier
					form.$("note").value = company.company_note
					form.$("city").value = company.company_addr_city
					form.$("county").value = company.company_addr_county
					form.$("number").value = company.company_addr_number
					form.$("region").value = company.company_addr_region
					form.$("street").value = company.company_addr_street
					form.$("psc").value = company.company_addr_zip
					form.$("email").value = company.company_contact_email
					form.$("firstname").value = company.company_contact_first_name
					form.$("lastname").value = company.company_contact_last_name
					form.$("mobile").value = company.company_contact_mobile
					form.$("phone").value = company.company_contact_phone
				} else {
					GlobalStore.setNotificationMessage("Pri načítavaní detailu spoločnosti nastala chyba", "error")
				}
			})
	}
}

var singleton = new CvsIdentificationStore()
export default singleton
