import {observable, action} from "mobx"

import {getFilters} from "../../../global/helpers/api"
import {exportToExcel, getCompanyId} from "../../../global/helpers/actions"

import GlobalStore from "../../../global/store/GlobalStore"
import RouterStore from "../../../global/store/RouterStore"

import api from "../../../global/actions/api"

class OesReportStore {
	@observable isOpenReportDialog = false

	@observable reportsData = []

	@observable dropedFiles = []
	@observable attachmentTemplates = []

	@observable existYears = []

	@action openReportDialog() {
		this.isOpenReportDialog = true
	}

	@action closeReportDialog(form) {
		form.reset()
		this.isOpenReportDialog = false
	}

	@action getOesReports() {
		const req = getFilters([`company_id=${getCompanyId()}`])

		api
			.getOESReports(req)
			.call()
			.then((reports) => {
				if (isSafe(reports) && isSafe(reports.rows) && reports.rows.length) {
					let tempExist = []

					reports.rows.forEach((x) => {
						tempExist.push(x.year)
					})

					this.existYears = tempExist

					this.reportsData = reports.rows
				} else {
					this.reportsData = []
					this.existYers = []
				}
			})
	}

	@action getHistoricalData(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			region_id: values.region_id,
			medium_id: values.medium_id,
			consumer_group_id: values.consumer_group_id
		}

		api
			.getOESRecordHistory(req)
			.call()
			.then((res) => {
				if (isSafe(res)) {
					form.$("quantity_sold_history").value = res.quantity_sold
					form.$("consumer_count_history").value = res.consumer_count
				}
			})
	}

	@action saveReport(form) {
		const values = form.values()

		const req = {
			company_id: getCompanyId(),
			year: values.year,
			medium_id: values.medium_id,
			consumer_group_id: values.consumer_group_id,
			region_id: values.region_id,
			quantity_sold: values.quantity_sold,
			consumer_count: values.consumer_count,
			note: values.note
		}

		api
			.saveOESReport(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Report úspešne pridaný", "success")

					this.closeReportDialog(form)

					RouterStore.push(`/oes_report?year=${values.year}&id=${res._id}`)
				} else {
					GlobalStore.setNotificationMessage("Pri pridávaní reportu došlo ku chybe", "error")
				}
			})
	}

	@action deleteReport(report_id) {
		api
			.deleteOESReport(report_id)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Report úspešne odstránený", "success")
				this.getOesReports()
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	@action deleteLoadedAttachemnt(id, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(attachmentIndex, 1)
			})
	}

	@action exportReports() {
		let filters = getFilters([`company_id=${getCompanyId()}`])

		let req = {
			uri: "/isee/oes/reports",
			filters: filters.filters,
			columns: [
				{
					Header: "Rok",
					accessor: "year"
				},
				{
					Header: "Predané množstvo [MWh]",
					accessor: "sum_quantity_sold"
				},
				{
					Header: "Počet odberateľov",
					accessor: "sum_customer_count"
				},
				{
					Header: "Stav reportu",
					accessor: "status_name_ext"
				},
				{
					Header: "Počet príloh",
					accessor: "attachment_count"
				}
			]
		}
		exportToExcel(req)
	}
}

var singleton = new OesReportStore()
export default singleton
