"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./reportDialogFields"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsButton from "../../../global/ui/xsButton/xsButton"

import OesReportStore from "./oesReportStore"
import GlobalStore from "../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../global/store/RegistersCacheStore"

function ReportDialog(props) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			OesReportStore.saveReport(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const [years, setYears] = useState([])

	useEffect(() => {
		const actualYear = moment().year() - 1
		let tempYears = []

		for (let i = 0; i < 5; i++) {
			const year = actualYear - i

			tempYears.push({
				_id: year,
				name: year,
				name_ext: year,
				code: year,
				code_ext: year
			})
		}

		setYears(tempYears)
	}, [])

	return (
		<Dialog
			open={OesReportStore.isOpenReportDialog}
			onEscapeKeyDown={() => OesReportStore.closeReportDialog(form)}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<Typography variant="h6">{props.title}</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => OesReportStore.closeReportDialog(form)}
					></i>
					{/* <Grid item>
						<Typography variant="h6">
							<FormattedMessage id="Common.label.warning" />
						</Typography>
					</Grid> */}
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("year")}
							items={isSafe(years) ? years.filter((x) => !OesReportStore.existYears.includes(x._id)) : []}
							onChange={() => {
								if (
									isNotEmpty(form.$("region_id").value) &&
									isNotEmpty(form.$("medium_id").value) &&
									isNotEmpty(form.$("consumer_group_id").value)
								) {
									OesReportStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("region_id")}
							items={GlobalStore.CL["addressRegion"]}
							onChange={() => {
								if (
									isNotEmpty(form.$("region_id").value) &&
									isNotEmpty(form.$("year").value) &&
									isNotEmpty(form.$("consumer_group_id").value)
								) {
									OesReportStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("medium_id")}
							items={RegistersCacheStore.mediumOES}
							onChange={() => {
								if (
									isNotEmpty(form.$("region_id").value) &&
									isNotEmpty(form.$("year").value) &&
									isNotEmpty(form.$("consumer_group_id").value)
								) {
									OesReportStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsSearchSelect
							field={form.$("consumer_group_id")}
							items={GlobalStore.CL["consumerGroup"]}
							onChange={() => {
								if (
									isNotEmpty(form.$("region_id").value) &&
									isNotEmpty(form.$("year").value) &&
									isNotEmpty(form.$("medium_id").value)
								) {
									OesReportStore.getHistoricalData(form)
								}
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput
							field={form.$("quantity_sold")}
							onChange={(val) => {
								if (val.includes(",")) {
									form.$("quantity_sold").value = val.replace(",", ".")
								}
							}}
							endText={
								isNotEmpty(form.$("quantity_sold_history").value) ? `(${form.$("quantity_sold_history").value})` : ""
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<XsInput
							field={form.$("consumer_count")}
							onChange={(val) => {
								if (val.includes(",")) {
									form.$("consumer_count").value = val.replace(",", ".")
								}
							}}
							endText={
								isNotEmpty(form.$("consumer_count_history").value) ? `(${form.$("consumer_count_history").value})` : ""
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<XsInput field={form.$("note")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={1}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => OesReportStore.closeReportDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(ReportDialog))
