"use strict"

import React, {useEffect} from "react"
// import classnames from "classnames"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Grid} from "@material-ui/core"

import RmeeDrawer from "./rmeeDrawer"
import RmeeCompaniesContainer from "./companies/rmeeCompaniesContainer"

import RmeeStore from "./rmeeStore"
import GlobalStore from "../../global/store/GlobalStore"

function RmeeContainer() {
	useEffect(() => {
		GlobalStore.main_menu_selected = "rmee"
	}, [])

	return (
		<Grid container className="max_height">
			<Grid item>
				<RmeeDrawer />
			</Grid>
			<Grid item xs className="max_height">
				{RmeeStore.rmeeTabId && <RmeeCompaniesContainer />}
			</Grid>
		</Grid>
	)
}

export default injectIntl(observer(RmeeContainer))
