import {observable, action} from "mobx"

import BuildingIdentificationStore from "./identificationData/buildingIdentificationStore"

import api from "../../../../global/actions/api"

class BudovyBuildingDetailStore {
	@observable buildingTabValue = "identification"

	buildingDTO = undefined

	@action getBuildingById(buildingId) {
		api
			.getBuilding(buildingId)
			.call()
			.then((res) => {
				this.buildingDTO = res

				BuildingIdentificationStore.initForm(res)
			})
	}
}

var singleton = new BudovyBuildingDetailStore()
export default singleton
