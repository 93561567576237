"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box} from "@material-ui/core"
import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../global/ui/xsButton/xsButton"

import RmeeCompanyEmployeetDialog from "./rmeeCompanyEmployeetDialog"

import RmeeCompanyEmployeesStore from "./rmeeCompanyEmployeesStore"
import RouterStore from "../../../../global/store/RouterStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.personalNumber" />,
		width: 100,
		accessor: "personal_number",
		sortType: "basic"
	},
	{
		Header: "Meno a priezvisko",
		width: 150,
		accessor: "full_name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.mobile" />,
		width: 100,
		accessor: "business_contact.mobile",
		sortType: "basic"
	},
	{
		Header: "E-mail",
		width: 100,
		accessor: "business_contact.email",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.cardNumber" />,
		width: 100,
		accessor: "card_id",
		sortType: "basic"
	}
]

function RmeeCompanyEmployeesContainer(props) {
	useEffect(() => {
		RmeeCompanyEmployeesStore.loadCompanyEmployees(props.company_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item xs={12} container justify="flex-end">
						<Grid item>
							<XsButton
								text="Nový zamestnanec"
								onClick={() => RmeeCompanyEmployeesStore.openCompanyEmployeeDialog()}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<XsTable
							columns={columns}
							data={RmeeCompanyEmployeesStore.companyEmployeesData}
							onRowClick={(row) =>
								RouterStore.push(`/companyEmployee?companyId=${props.company_id}&personId=${row.person_id}`)
							}
						/>
					</Grid>
				</Grid>
			</Box>
			{RmeeCompanyEmployeesStore.isOpenCompanyEmployeeDialog && (
				<RmeeCompanyEmployeetDialog company_id={props.company_id} />
			)}
		</Container>
	)
}

export default injectIntl(observer(RmeeCompanyEmployeesContainer))
