"use strict"

import React, {useEffect} from "react"
import classnames from "classnames"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"

import CvsReportRecordStore from "./cvsReportRecordStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import RegistersCacheStore from "../../../../global/store/RegistersCacheStore"

const columns = [
	{
		Header: "Energonosič",
		width: 100,
		accessor: "medium_name_ext",
		sortType: "basic"
	},
	{
		Header: () => <div className="textRight">Spotreba</div>,
		width: 75,
		accessor: "consumption",
		sortType: "basic",
		Cell: (obj) => <div className="textRight">{obj && obj.value ? obj.value.toLocaleString("sk-sk") : ""}</div>
	},
	{
		Header: "Merná jednotka",
		width: 75,
		accessor: "unit_name_ext",
		sortType: "basic",
		Cell: (obj) => (obj && obj.value ? `${obj.row.original.unit_id} - ${obj.value}` : "")
	},
	{
		Header: () => <div className="textRight">Náklady [EUR]</div>,
		width: 75,
		accessor: "costs",
		sortType: "basic",
		Cell: (obj) => <div className="textRight">{obj.value ? getPriceFormat(obj.value) : ""}</div>
	},
	{
		Header: () => <div className="textRight">Merná cena [EUR/m.j.]</div>,
		width: 75,
		accessor: "neviem",
		sortType: "basic",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>{calculateSpecificPrice(obj.row.original)}</Grid>
				</Grid>
			)
		}
	},
	{
		Header: () => <div className="textCenter">Rozsah mernej ceny</div>,
		width: 100,
		accessor: "medium_id",
		sortType: "basic",
		Cell: (obj) => {
			return (
				<Grid container justify="center">
					<Grid item>{getLimitSpecificPrice(obj.value)}</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhodnotenie",
		width: 75,
		accessor: "nevieme",
		sortType: "basic",
		Cell: (obj) => {
			return (
				<Grid container>
					<Grid item>{evaluation(obj.row.original)}</Grid>
				</Grid>
			)
		}
	},
	{
		Header: () => <div className="textRight">Spotreba [kWh]</div>,
		width: 75,
		accessor: "consumptionKWH",
		sortType: "basic",
		Cell: (obj) => <div className="textRight">{obj && obj.value ? obj.value.toLocaleString("sk-sk") : ""}</div>
	},
	{
		Header: "Poznámka",
		width: 150,
		accessor: "note",
		sortType: "basic"
	},
	{
		Header: "Počet príloh",
		width: 50,
		accessor: "attachment_count",
		Cell: (obj) => {
			return (
				<Grid container alignItems="center">
					<Grid item style={{paddingRight: "3px"}}>
						<i className="far fa-paperclip"></i>
					</Grid>
					<Grid item>{obj.value}</Grid>
				</Grid>
			)
		}
	},
	{
		clickable: false,
		width: 10,
		accessor: "record_id",
		Cell: (obj) => {
			const isDisabled = CvsReportRecordStore.isDisabled

			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className={classnames("fas fa-trash-alt fa-lg", {
								"redDanger pointer": !isDisabled,
								greyLight: isDisabled
							})}
							onClick={() =>
								isDisabled ? null : GlobalStore.openConfirmationDialog("removeFuel", {fuel_id: obj.value})
							}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

const getLimitSpecificPrice = (medium_id) => {
	const medium = RegistersCacheStore.mediumCVS.find((x) => x._id == medium_id)

	if (medium) {
		return `${getPriceFormat(medium.price_limit.from)} - ${getPriceFormat(medium.price_limit.to)}`
	}

	return ""
}

const calculateSpecificPrice = (row) => {
	if (isNotEmpty(row.costs) && isNotEmpty(row.consumption)) {
		return getPriceFormat(row.costs / row.consumption)
	}

	return ""
}

const evaluation = (row) => {
	const medium = RegistersCacheStore.mediumCVS.find((x) => x._id == row.medium_id)

	if (medium) {
		if (isNotEmpty(row.costs) && isNotEmpty(row.consumption)) {
			const specificPrice = +(row.costs / row.consumption).toFixed(2)

			if (specificPrice >= medium.price_limit.from && specificPrice <= medium.price_limit.to) {
				return (
					<Grid container alignItems="center">
						<i className="fas fa-circle greenSuccess"></i>
						<span className="ml-1">v rozsahu</span>
					</Grid>
				)
			} else {
				if (specificPrice > medium.price_limit.from) {
					return (
						<Grid container alignItems="center">
							<i className="fas fa-circle redDanger"></i>
							<span className="ml-1">nad rozsahom</span>
						</Grid>
					)
				} else {
					return (
						<Grid container alignItems="center">
							<i className="fas fa-circle redDanger"></i>
							<span className="ml-1">pod rozsahom</span>
						</Grid>
					)
				}
			}
		}
	}

	return ""
}

function ListOfFuel(props) {
	useEffect(() => {
		CvsReportRecordStore.getCvsRecords(props.report_id)
	}, [])

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={CvsReportRecordStore.recordsData}
				colorful
				summaryRow={[
					{cellPosition: 4, value: getPriceFormat(CvsReportRecordStore.totalCost)},
					{cellPosition: 8, value: CvsReportRecordStore.consumptionInKWH.toLocaleString("sk-sk")}
				]}
				size="small"
				onRowClick={(row) => CvsReportRecordStore.getRecordById(row.record_id, props.year)}
			/>
			<XsConfirmationDialog
				type="danger"
				name="removeFuel"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				title="Skutočne chcete odstrániť záznam?"
				text="Chystáte sa odstrániť záznam. Skutočne to chcete urobiť?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.delete"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.back"
				}}
				onConfirmation={() => {
					CvsReportRecordStore.deleteRecord(GlobalStore.confParams.fuel_id, props.report_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(ListOfFuel))
