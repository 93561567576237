"use strict"

import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { injectIntl } from "react-intl"
import classnames from "classnames"

import { Grid, Box } from "@material-ui/core"

import XsTable from "../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../global/ui/xsDialog/xsConfirmationDialog"
import { isReportDisabled } from "../../../global/helpers/actions"

import OesReportStore from "./oesReportStore"
import RouterStore from "../../../global/store/RouterStore"
import GlobalStore from "../../../global/store/GlobalStore"

const columns = [
	{
		Header: "Rok",
		width: 50,
		accessor: "year"
	},
	{
		Header: () => <div className="textRight">Predané množstvo [MWh]</div>,
		width: 100,
		accessor: "sum_quantity_sold",
		sortType: "basic",
		Cell: (row) => <div className="textRight">{row.value.toLocaleString("sk-sk", { minimumFractionDigits: 3 })}</div>
	},
	{
		Header: () => <div className="textRight">Počet odberateľov</div>,
		width: 100,
		accessor: "sum_customer_count",
		sortType: "basic",
		Cell: (row) => <div className="textRight">{row.value.toLocaleString("sk-sk")}</div>
	},
	{
		Header: "Stav reportu",
		width: 125,
		accessor: "status_id",
		Cell: (obj) => {
			return (
				<Grid container alignItems="center">
					<Grid item>
						<Box pr={1}>{getStatusCircle(obj.value)}</Box>
					</Grid>
					<Grid item>{obj.row.original.status_name_ext}</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Počet príloh",
		width: 50,
		accessor: "attachment_count",
		Cell: (obj) => {
			return (
				<Grid container alignItems="center">
					<Grid item style={{ paddingRight: "3px" }}>
						<i className="far fa-paperclip"></i>
					</Grid>
					<Grid item>{obj.value}</Grid>
				</Grid>
			)
		}
	},
	{
		clickable: false,
		width: 10,
		accessor: "report_id",
		Cell: (obj) => {
			const isDisabled = isReportDisabled(obj.row.original.status_id)

			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className={classnames("fas fa-trash-alt fa-lg", {
								"redDanger pointer": !isDisabled,
								greyLight: isDisabled
							})}
							onClick={() =>
								isDisabled ? null : GlobalStore.openConfirmationDialog("removeReport", { report_id: obj.value, report_year: obj.row.original.year })
							}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

const getStatusCircle = (status_id) => {
	switch (status_id) {
		case "Closed":
			return <i className="fas fa-circle greenSuccess"></i>
		case "InProgress":
			return <i className="fas fa-circle amberWarning"></i>
		case "Rejected":
			return <i className="fas fa-circle redDanger"></i>
		case "Sent":
			return <i className="fas fa-circle blueInfo"></i>
		case "Undelivered":
			return <i className="fas fa-circle"></i>
	}
}

function ListOfReports() {
	useEffect(() => {
		OesReportStore.getOesReports()
	}, [])

	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				defaultSort={{ id: "year", desc: true }}
				data={OesReportStore.reportsData}
				colorful
				size="small"
				onRowClick={(row) => RouterStore.push(`/oes_report?year=${row.year}&id=${row.report_id}`)}
			/>
			{GlobalStore.confirmationDialogOpened == "removeReport" &&
				<XsConfirmationDialog
					type="danger"
					name="removeReport"
					titleIcon={<i className="fas fa-exclamation-triangle"></i>}
					title="Skutočne chcete odstrániť záznam?"
					text={`Chystáte sa odstrániť report za rok ${GlobalStore.confParams.report_year}. Skutočne to chcete urobiť?`}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.delete"
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.back"
					}}
					onConfirmation={() => {
						OesReportStore.deleteReport(GlobalStore.confParams.report_id)
					}}
				/>
			}
		</React.Fragment>
	)
}

export default injectIntl(observer(ListOfReports))
