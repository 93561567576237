import React from "react"
import ReactDOM from "react-dom"

import {SnackbarProvider} from "notistack"
import {IntlProvider} from "react-intl"
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles"
import {addLocaleData} from "react-intl"
import enLocaleData from "react-intl/locale-data/en"
import skLocaleData from "react-intl/locale-data/sk"
import localeDataEN from "./global/i18n/dictionary.en.json"
import localeDataSK from "./global/i18n/dictionary.sk.json"
import config from "./global/config/settings"
import muiTheme from "./global/theme/muiTheme"
import createBrowserHistory from "history/createBrowserHistory"
import {Provider} from "mobx-react"
import {syncHistoryWithStore} from "mobx-react-router"
import RouterStore from "./global/store/RouterStore"
import {Router} from "react-router-dom"
import App from "./App"
import moment from "moment"
import "moment/locale/sk"
import {configure} from "mobx"

moment.locale("sk")
configure({isolateGlobalState: true})

import WindowsExtensions from "./global/helpers/window" // eslint-disable-line no-unused-vars

addLocaleData([...enLocaleData, ...skLocaleData])

const localeData = {en: localeDataEN, sk: localeDataSK}
const selectedLanguage = "sk"
const languageWithoutRegionCode = selectedLanguage || "sk"
const messages = localeData[languageWithoutRegionCode] || localeData.en

const browserHistory = createBrowserHistory()
const stores = {
	routing: RouterStore
}

const history = syncHistoryWithStore(browserHistory, RouterStore)
ReactDOM.render(
	<SnackbarProvider maxSnack={5}>
		<MuiThemeProvider theme={muiTheme}>
			<IntlProvider locale={config.BROWSER_LOCALE} messages={messages}>
				<Provider {...stores}>
					<Router history={history}>
						<App />
					</Router>
				</Provider>
			</IntlProvider>
		</MuiThemeProvider>
	</SnackbarProvider>,
	document.getElementById("root")
)
