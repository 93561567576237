"use strict"

import React, {useEffect} from "react"
import classnames from "classnames"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Paper, Grid, Tab, Tabs} from "@material-ui/core"

import ReportsContainer from "./reports/reportsContainer"
import ActivityContainer from "./activity/activityContainer"
import GraphsContainer from "./graphs/graphsContainer"
import IdentificationContainer from "./identification/identificationContainer"

import CvsStore from "./cvsStore"
import GlobalStore from "../../global/store/GlobalStore"

import "./cvs.less"

function CvsContainer() {
	useEffect(() => {
		GlobalStore.main_menu_selected = "cvs"
	}, [])

	const handleChange = (event, value) => {
		CvsStore.cvsTabValue = value
	}

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="cvs_container">
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>
						Výkaz o <span className="bold">celkovej vlastnej spotrebe</span> (CVS) podľa jednotlivých energonosičov
						(palív)
					</Box>
				</Grid>
			</Grid>
			<Paper className="cvs_tabs">
				<Tabs
					value={CvsStore.cvsTabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						className={classnames({"xs-active": CvsStore.cvsTabValue == "baseInfo"})}
						value="baseInfo"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-user-friends fa-lg"></i>
								</Grid>
								<Grid item>Identifikačné údaje</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": CvsStore.cvsTabValue == "reports"})}
						value="reports"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Reporty</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": CvsStore.cvsTabValue == "activity"})}
						value="activity"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Aktivity</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": CvsStore.cvsTabValue == "graphs"})}
						value="graphs"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-address-card fa-lg"></i>
								</Grid>
								<Grid item>Dashboard / Grafy</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{CvsStore.cvsTabValue == "baseInfo" && <IdentificationContainer />}
				{CvsStore.cvsTabValue == "reports" && <ReportsContainer />}
				{CvsStore.cvsTabValue == "activity" && <ActivityContainer />}
				{CvsStore.cvsTabValue == "graphs" && <GraphsContainer />}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(CvsContainer))
