import React, {useEffect} from "react"
import {observer} from "mobx-react"
import Grid from "@material-ui/core/Grid"
import MomentUtils from "@date-io/moment"
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers"
import {FormattedMessage} from "react-intl"
import classnames from "classnames"

import "./xsDateTimePicker.less"

function XsDateTimePicker({field, onChange, disabled}) {
	// V rules fieldu musi byt date inak ulozi aj nevalidny date
	useEffect(() => {
		const rules = field.get("rules")
		if (isSafe(rules) && !rules.includes("date")) {
			field.set("rules", rules + (rules.length > 0 ? "|date" : "date"))
		}
	}, [])

	const rules = field.get("rules")

	const req = isSafe(rules) && rules.includes("required") && !field.get("value")

	const label =
		isSafe(rules) && rules.includes("required") ? (
			<span className={classnames("bolder", {red: req})}>{field.get("label")} *</span>
		) : (
			<span>{field.get("label")}</span>
		)

	const handleDateChange = (date, value) => {
		field.set("value", date)
		if (isSafe(onChange) && typeof onChange === "function") {
			onChange(date, value)
		}
	}

	const inputClassName = req ? "datepickerInput error" : "datepickerInput"

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Grid container justify="space-around">
				<KeyboardDatePicker
					autoOk
					fullWidth
					disabled={disabled}
					// inputVariant="outlined"
					variant="inline"
					size="small"
					orientation="landscape"
					format="DD.MM.yyyy"
					// margin="normal"
					label={label}
					value={field.get("value")}
					onChange={handleDateChange}
					InputProps={{className: inputClassName}}
					InputAdornmentProps={{position: "start"}}
					KeyboardButtonProps={{
						"aria-label": "change date"
					}}
					invalidDateMessage={<FormattedMessage id="Common.invalidDate" />}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	)
}
export default observer(XsDateTimePicker)
