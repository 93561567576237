"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./certificateFields"
import Dropzone from "react-dropzone"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import {downloadAttachment} from "../../../../../global/helpers/actions"

import BuildingCertificatesStore from "./buildingCertificatesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import api from "../../../../../global/actions/api"

function CertificateDialog({building_id}) {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			BuildingCertificatesStore.generateDataAndSave(form, close)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		form.$("energeticObjectId").value = building_id

		if (BuildingCertificatesStore.objId) {
			api
				.getEnergeticObject(BuildingCertificatesStore.objId)
				.call()
				.then((cert) => {
					form.$("_id").value = cert._id
					form.$("type_of_cert").value = cert.type_id
					form.$("number_of_cert").value = cert.identifier
					form.$("date_of_issue").value = cert.issue_date ? moment(cert.issue_date) : null
					form.$("name").value = cert.name
					form.$("floor_area").value = cert.floor_area
					form.$("note").value = cert.note

					if (cert.attachments && cert.attachments.length) {
						let tmp = []

						cert.attachments.forEach((attach) => {
							tmp.push({name: attach.file_name, _id: attach._id})
						})

						BuildingCertificatesStore.dropedFiles = tmp
					}
				})
		}
	}, [])

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (BuildingCertificatesStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			BuildingCertificatesStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (file) => {
		downloadAttachment("ISEE.Data.EnergeticObject.Review", form.$("_id").value, file._id)
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		if (isSafe(BuildingCertificatesStore.dropedFiles[attachmentIndex]._id)) {
			BuildingCertificatesStore.deleteLoadedAttachemnt(
				BuildingCertificatesStore.dropedFiles[attachmentIndex]._id,
				attachmentIndex
			)
		} else {
			BuildingCertificatesStore.dropedFiles.splice(attachmentIndex, 1)
		}
	}

	const close = () => {
		BuildingCertificatesStore.closeCertificateDialog()
	}

	return (
		<Dialog
			open={BuildingCertificatesStore.isOpenCertificateDialog}
			onEscapeKeyDown={() => close()}
			scroll="body"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>Pridať posudok / certifikát</Grid>
					<i className="fal fa-times fa-lg dialog_close_icon pointer" onClick={() => close()}></i>
				</Grid>
			</DialogTitle>
			<DialogContent style={{paddingTop: "0px", paddingBottom: "10px"}}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("type_of_cert")} items={GlobalStore.CL["EnergeticObject.Review"]} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("number_of_cert")} />
					</Grid>
					<Grid item xs={6}>
						<XsDateTimePicker field={form.$("date_of_issue")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("name")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("floor_area")} />
					</Grid>
					<Grid item xs={12}>
						<XsInput field={form.$("note")} />
					</Grid>
					<Grid item xs={12} className="pointer">
						<Dropzone
							onDrop={(files) => {
								handleDrop(files, 5)
							}}
							id="dropzone"
							// disabled={isFormDisabled()}
							accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
						>
							{isSafe(BuildingCertificatesStore.dropedFiles) && BuildingCertificatesStore.dropedFiles.length ? (
								BuildingCertificatesStore.dropedFiles.map((file, index) => (
									<div key={index} className={"dropedFile"}>
										<i
											className="fal fa-file icon"
											onClick={(e) => {
												e.stopPropagation()
												handleDropzonePreview(file)
											}}
										/>
										<i
											className="far fa-trash-alt deleteIcon"
											onClick={(e) => {
												e.stopPropagation()
												// if (!isFormDisabled()) {
												GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
													fileIndex: index
												})
												// }
											}}
										/>
										<br />
										<div
											className={"mac_ellipsis"}
											data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
											data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
										/>
									</div>
								))
							) : (
								<div
									className={"dropzonePlaceholder"}
								>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
							)}
						</Dropzone>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2">
							Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
						</Typography>
					</Grid>
					<Grid item xs={12} container justify="flex-end" spacing={1}>
						<Grid item>
							<XsButton
								// className={cancelBtnClassName}
								variant="text"
								icon={<i className="fal fa-times fa-lg"></i>}
								text={<FormattedMessage id="Common.label.cancel" />}
								onClick={() => close()}
							/>
						</Grid>
						<Grid item>
							<XsButton
								// className={cancelBtnClassName}
								variant="text"
								icon={<i className="fal fa-save fa-lg"></i>}
								text={<FormattedMessage id="Common.label.save" />}
								onClick={form.onSubmit}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
		</Dialog>
	)
}

export default injectIntl(observer(CertificateDialog))
