"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeEmployeeCardFields"

import {injectIntl, FormattedMessage} from "react-intl"
import {Grid, Box} from "@material-ui/core"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import RmeeEmployeeCardStore from "./rmeeEmployeeCardStore"
import RmeeCompanyEmployeesStore from "../rmeeCompanyEmployeesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeEmployeeCardForm(props) {
	const hooks = {
		onSubmit() {},
		onSuccess() {
			RmeeEmployeeCardStore.saveCard(form, setDisabled)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		RmeeEmployeeCardStore.employeeCardFormRef = form

		RmeeCompanyEmployeesStore.loadEmployee(props.person_id, props.company_id)

		return () => {
			form.reset()
		}
	}, [])

	return (
		<Grid container direction="column">
			<Box mt={4}>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<XsSearchSelect
							field={form.$("employee_category")}
							items={GlobalStore.CL["employeeCategories"]}
							disabled={disabled}
						/>
					</Grid>
					<Grid item xs={3}>
						<XsInput field={form.$("personal_number")} disabled={disabled} />
					</Grid>
					<Grid item xs={3}>
						<XsInput field={form.$("card_number")} disabled={disabled} />
					</Grid>
					<Grid item xs={2}>
						<XsDateTimePicker field={form.$("arrival_date")} disabled={disabled} />
					</Grid>
					<Grid item xs={2}>
						<XsDateTimePicker field={form.$("departure_date")} disabled={disabled} />
					</Grid>
				</Grid>
			</Box>
			<Box mt={2}>
				<Grid container spacing={1} justify="flex-end">
					{disabled ? (
						<Grid item>
							<XsButton
								icon={<i className="fal fa-pencil-alt"></i>}
								text={<FormattedMessage id="Common.btn.edit" />}
								onClick={() => setDisabled(false)}
							/>
						</Grid>
					) : (
						<React.Fragment>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-times"></i>}
									text={<FormattedMessage id="Common.label.cancel" />}
									onClick={() => {
										setDisabled(true)
										RmeeCompanyEmployeesStore.loadEmployee(form.$("_id").value, form.$("company_id").value)
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									// className={cancelBtnClassName}
									icon={<i className="fal fa-save"></i>}
									text={<FormattedMessage id="Common.btn.save" />}
									onClick={form.onSubmit}
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
			</Box>
		</Grid>
	)
}

export default injectIntl(observer(RmeeEmployeeCardForm))
