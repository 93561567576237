import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeEmployeeContactsStore {
	@observable employeeContactsData = []

	@observable isOpenEmployeeContactDialog = false

	employeeContactFormRef = null

	@action loadContacts(person_id) {
		api
			.loadContacts(person_id)
			.call()
			.then((res) => {
				if (isSafe(res) && res.length) {
					this.employeeContactsData = res
				} else {
					this.employeeContactsData = []
				}
			})
	}

	@action openEmployeeContactDialog(person_id, row) {
		if (isSafe(row) && isSafe(this.employeeContactFormRef)) {
			this.employeeContactFormRef.$("_id").value = row._id
			this.employeeContactFormRef.$("type").value = isSafe(row.contact_type) ? row.contact_type._id : null
			this.employeeContactFormRef.$("mobile").value = row.mobile
			this.employeeContactFormRef.$("phone").value = row.phone
			this.employeeContactFormRef.$("email").value = row.email
			this.employeeContactFormRef.$("web_page").value = isSafe(row.web_page) ? row.web_page : ""
			this.employeeContactFormRef.$("description").value = row.description
			this.employeeContactFormRef.$("primary").value = row.primary
		}

		this.employeeContactFormRef.$("person_id").value = person_id

		this.isOpenEmployeeContactDialog = true
	}

	@action closeEmployeeContactDialog() {
		this.isOpenEmployeeContactDialog = false

		this.employeeContactFormRef.reset()
	}

	@action saveEmployeeContact(form) {
		const values = form.values()

		const req = {
			_id: isNotEmpty(values._id) ? values._id : "",
			_type: "Entity.Data.Contact",
			contact_type: {
				_id: values.type
			},
			email: values.email,
			mobile: values.mobile,
			phone: values.phone,
			web_page: values.web,
			description: values.description,
			primary: values.primary,
			specific_rel: {
				_type: "Entity.Data.Employee",
				_id: values.person_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveContact(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Kontakt úspešne uložený", "success")
					this.loadContacts(values.person_id)
					this.closeEmployeeContactDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní kontaktu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeEmployeeContact(contact_id, person_id) {
		const req = {active: false, _id: contact_id, _ref: false, _type: "Entity.Data.Contact"}

		GlobalStore.loading(true)

		api
			.saveContact(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Kontakt úspešne odstránený", "success")
					this.loadContacts(person_id)
				} else {
					GlobalStore.setNotificationMessage("Pri odstráňovaní kontaktu došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeEmployeeContactsStore()
export default singleton
