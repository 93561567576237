"use strict"

import React, {useState, useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./rmeeEmployeeContactFields"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions, Typography} from "@material-ui/core"

import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../../../../global/ui/xsCheckbox/xsCheckbox"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"

import RmeeEmployeeContactsStore from "./rmeeEmployeeContactsStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

function RmeeEmployeeContactDialog() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RmeeEmployeeContactsStore.saveEmployeeContact(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Common.notification.checkRequiredFields", "warning")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		RmeeEmployeeContactsStore.employeeContactFormRef = form
	}, [])

	return (
		<Dialog
			open={RmeeEmployeeContactsStore.isOpenEmployeeContactDialog}
			onEscapeKeyDown={() => RmeeEmployeeContactsStore.closeEmployeeContactDialog()}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle className="dialog_header">
				<Grid container direction="column" alignItems="center">
					<Grid item>
						<i className="fal fa-address-card fa-2x"></i>
					</Grid>
					<Grid item>
						<Typography variant="h6">Pridať kontakt</Typography>
					</Grid>
					<i
						className="fal fa-times fa-lg dialog_close_icon pointer"
						onClick={() => RmeeEmployeeContactsStore.closeEmployeeContactDialog()}
					></i>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<XsSearchSelect field={form.$("type")} items={GlobalStore.CL["contactTypes"]} required />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("mobile")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("email")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("phone")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("web_page")} />
					</Grid>
					<Grid item xs={6}>
						<XsInput field={form.$("description")} />
					</Grid>
					<Grid item xs={6}>
						<XsCheckbox field={form.$("primary")} />
					</Grid>
					{/* <Grid item xs={12}>
						<XsInput field={form.$("code")} />
					</Grid> */}
				</Grid>
			</DialogContent>
			<DialogActions className="dialog_footer">
				<Grid container justify="flex-end" spacing={6}>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-times fa-lg"></i>}
							text={<FormattedMessage id="Common.label.cancel" />}
							onClick={() => RmeeEmployeeContactsStore.closeEmployeeContactDialog(form)}
						/>
					</Grid>
					<Grid item>
						<XsButton
							// className={cancelBtnClassName}
							variant="text"
							icon={<i className="fal fa-save fa-lg"></i>}
							text={<FormattedMessage id="Common.label.save" />}
							onClick={form.onSubmit}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(RmeeEmployeeContactDialog))
