import {action} from "mobx"

import api from "../../../../../global/actions/api"

import {getFilters} from "../../../../../global/helpers/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeEmployeeApplicationUserStore {
	employeeAppUserFormRef = null

	@action loadEntityUser(person_id) {
		const req = getFilters([`entity=${person_id}`])
		api
			.loadEntityUsers(req)
			.call()
			.then((res) => {
				if (isSafe(this.employeeAppUserFormRef) && isSafe(res) && isSafe(res.rows) && res.rows.length) {
					const entityUser = res.rows[0]

					this.employeeAppUserFormRef.$("_id").value = entityUser._id

					if (isSafe(entityUser.user)) {
						const user = entityUser.user

						this.employeeAppUserFormRef.$("user_id").value = user._id
						this.employeeAppUserFormRef.$("login").value = isEmpty(user.login) ? "" : user.login
						this.employeeAppUserFormRef.$("email").value = isEmpty(user.email) ? "" : user.email
						this.employeeAppUserFormRef.$("enabled").value = user.enabled
						this.employeeAppUserFormRef.$("confirmed").value = user.confirmed
					}
				}

				this.employeeAppUserFormRef.$("person_id").value = person_id
			})
	}

	@action saveApplicationUser(form, setDisabled) {
		const values = form.values()

		const req = {
			_id: values._id,
			_type: "Entity.Data.EntityUser",
			entity: {
				_id: values.person_id
			},
			user: {
				_ref: false,
				active: true,
				confirmed: values.confirmed,
				email: isEmpty(values.email) ? null : values.email,
				enabled: values.enabled,
				login: values.login,
				password_plain: values.password,
				_id: values.user_id
			}
		}

		GlobalStore.loading(true)

		api
			.saveEmployeeCard(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					setDisabled(true)
					GlobalStore.setNotificationMessage("Používateľ aplikácie úspešne uložený", "success")
					this.loadEntityUser(values.person_id)
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní používateľa aplikácie došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeEmployeeApplicationUserStore()
export default singleton
