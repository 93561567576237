"use strict"

import React, {useEffect} from "react"
import classnames from "classnames"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Paper, Grid, Tab, Tabs} from "@material-ui/core"

import BuildingsContainer from "./buildings/buildingsContainer"
import MapContainer from "./map/mapContainer"

import BudovyStore from "./budovyStore"
import GlobalStore from "../../global/store/GlobalStore"

import "./budovy.less"

function budovyContainer() {
	useEffect(() => {
		GlobalStore.main_menu_selected = "budovy"
	}, [])

	const handleChange = (event, value) => {
		BudovyStore.budovyTabValue = value
	}

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="budovy_container">
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>Energetické objekty</Box>
				</Grid>
			</Grid>
			<Paper className="budovy_tabs">
				<Tabs
					value={BudovyStore.budovyTabValue}
					onChange={handleChange}
					// variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="icon tabs example"
				>
					<Tab
						className={classnames({"xs-active": BudovyStore.budovyTabValue == "building"})}
						value="building"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-building fa-lg"></i>
								</Grid>
								<Grid item>Zoznam objektov</Grid>
							</Grid>
						}
					/>
					<Tab
						className={classnames({"xs-active": BudovyStore.budovyTabValue == "map"})}
						value="map"
						label={
							<Grid container spacing={1} justify="center">
								<Grid item>
									<i className="fal fa-map-marked-alt fa-lg"></i>
								</Grid>
								<Grid item>Mapa</Grid>
							</Grid>
						}
					/>
				</Tabs>
				{BudovyStore.budovyTabValue == "building" && <BuildingsContainer />}
				{BudovyStore.budovyTabValue == "map" && <MapContainer />}
			</Paper>
		</Box>
	)
}

export default injectIntl(observer(budovyContainer))
