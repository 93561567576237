"use strict"

import React from "react"
import {observer} from "mobx-react"
import moment from "moment"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box, Typography} from "@material-ui/core"
import XsServerTable from "../../../global/ui/xsTable/xsServerTable"

import api from "../../../global/actions/api"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.project" />,
		width: 100,
		accessor: "projekt_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">Zameranie:</span>{" "}
						{isNotEmpty(row.projekt_zameranieProjektu) ? row.projekt_zameranieProjektu : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.projekt_kod) ? `${row.projekt_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.projekt_nazov) ? row.projekt_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.projekt_stav) ? row.projekt_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Zazmluvnená suma:</span>{" "}
						{isNotEmpty(row.projekt_sumaZazmluvnena) ? `${row.projekt_sumaZazmluvnena} €` : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: <FormattedMessage id="Common.label.challengeDates" />,
		width: 100,
		accessor: "projekt_createdAt",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.projekt_createdAt) ? rowA.original.projekt_createdAt : ""
			const send_date_time_B = isNotEmpty(rowB.original.projekt_createdAt) ? rowB.original.projekt_createdAt : ""
			if (send_date_time_A > send_date_time_B) return -1
			if (send_date_time_B > send_date_time_A) return 1
			return 0
		},
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.creations" />:
						</span>{" "}
						{isNotEmpty(row.projekt_createdAt) ? moment(row.projekt_createdAt).format("DD.MM.YYYY") : ""}
					</Grid>
					<Grid item>
						<span className="bold">Začatie hlav. aktivít:</span>{" "}
						{isNotEmpty(row.projekt_datumZaciatkuHlavnychAktivit)
							? moment(row.projekt_datumZaciatkuHlavnychAktivit).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">Koniec hlav. aktivít:</span>{" "}
						{isNotEmpty(row.projekt_datumKoncaHlavnychAktivit)
							? moment(row.projekt_datumKoncaHlavnychAktivit).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">Začiatok realizácie:</span>{" "}
						{isNotEmpty(row.projekt_datumZaciatkuRealizacie)
							? moment(row.projekt_datumZaciatkuRealizacie).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">Koniec realizácie:</span>{" "}
						{isNotEmpty(row.projekt_datumKoncaRealizacie)
							? moment(row.projekt_datumKoncaRealizacie).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.statements" />:
						</span>{" "}
						{isNotEmpty(row.vyzva_datumVyhlasenia) ? moment(row.vyzva_datumVyhlasenia).format("DD.MM.YYYY") : ""}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Zmluva",
		width: 100,
		accessor: "projekt_cisloZmluvy",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.number" />:
						</span>{" "}
						{isNotEmpty(row.projekt_cisloZmluvy) ? row.projekt_cisloZmluvy : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Platnosť:</span>{" "}
						{isNotEmpty(row.projekt_datumPlatnostiZmluvy)
							? moment(row.projekt_datumPlatnostiZmluvy).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">Účinnosť:</span>{" "}
						{isNotEmpty(row.projekt_datumUcinnostiZmluvy)
							? moment(row.projekt_datumUcinnostiZmluvy).format("DD.MM.YYYY")
							: ""}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.url" />:
						</span>{" "}
						{isNotEmpty(row.projekt_urlAdresaZmluva) ? row.projekt_urlAdresaZmluva : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Verejné obstarávanie",
		width: 100,
		accessor: "verObst_kod",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.verObst_kod) ? row.verObst_kod : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.value" />:
						</span>{" "}
						{isNotEmpty(row.verObst_predpoklHodnotaZakazky) ? `${row.verObst_predpoklHodnotaZakazky} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.verObst_stav) ? row.verObst_stav : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "ZOP",
		width: 100,
		accessor: "zop_kod",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.zop_kod) ? row.zop_kod : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Nárokovaná suma:</span>{" "}
						{isNotEmpty(row.zop_narokovanaSuma) ? `${row.zop_narokovanaSuma} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schválená suma:</span>{" "}
						{isNotEmpty(row.zop_schvalenaSuma) ? `${row.zop_schvalenaSuma} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.zop_typ) ? row.zop_typ : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhl. výzva",
		width: 100,
		accessor: "vyhlVyzva_Nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_kod) ? `${row.vyhlVyzva_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_Nazov) ? htmlToPlain(row.vyhlVyzva_Nazov) : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhlasovateľ",
		width: 100,
		accessor: "vyhlasovatel_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.ico" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_ico) ? `${row.vyhlasovatel_ico}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_nazov) ? row.vyhlasovatel_nazov : "-"}
					</Grid>
				</Grid>
			)
		}
	}
]

function EtlProjectsContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y">
			<Grid container>
				<Grid item xs={12}>
					<Box pt={2} pb={2}>
						<Typography variant="h5">Projekty</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<XsServerTable
						columns={columns}
						api={api.loadProjects}
						// onRowClick={(row) => RmeeStore.openCompanyDetail(row)}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(EtlProjectsContainer))
