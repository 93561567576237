import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				title: {},
				company_id: {},
				company_name: {},
				code: {
					label: <FormattedMessage id="Common.label.code" />,
					rules: "required|string"
				},
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "string"
				},
				parent_OJ: {
					label: <FormattedMessage id="Common.label.superiorOJ" />,
					rules: "string"
				}
			}
		}
	}
}
