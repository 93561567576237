"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"

import {Grid} from "@material-ui/core"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

import RmeeOrgunitBanksStore from "./rmeeOrgunitBanksStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.bank" />,
		width: 100,
		accessor: "treasurer.name_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.type" />,
		width: 100,
		accessor: "type.name_ext",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.prefix" />,
		width: 100,
		accessor: "prefix",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.number" />,
		width: 100,
		accessor: "number",
		sortType: "basic"
	},
	{
		clickable: false,
		width: 10,
		accessor: "_id",
		Cell: (obj) => {
			return (
				<Grid container justify="flex-end">
					<Grid item>
						<i
							className="fal fa-trash-alt fa-lg pointer"
							onClick={() =>
								GlobalStore.openConfirmationDialog("removeOrgunitBankaccount", {bankaccount_id: obj.value})
							}
						></i>
					</Grid>
				</Grid>
			)
		}
	}
]

function RmeeOrgunitBanksTable(props) {
	return (
		<React.Fragment>
			<XsTable
				columns={columns}
				data={RmeeOrgunitBanksStore.orgunitBanksData}
				onRowClick={(row) => RmeeOrgunitBanksStore.openOrgunitBankDialog(props.orgunit_id, row)}
			/>
			<XsConfirmationDialog
				name="removeOrgunitBankaccount"
				text="Naozaj chcete odstrániť bankový účet?"
				confirmBtn={{
					icon: <i className="fal fa-trash-alt fa-lg" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					RmeeOrgunitBanksStore.removeOrgunitBankaccount(GlobalStore.confParams.bankaccount_id, props.orgunit_id)
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RmeeOrgunitBanksTable))
