import {action} from "mobx"

import api from "../../../../../global/actions/api"

import RmeeCompanyEmployeesStore from "../rmeeCompanyEmployeesStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeEmployeeCardStore {
	employeeCardFormRef = null

	@action saveCard(form, setDisabled) {
		const values = form.values()

		const req = {
			_type: "Entity.Data.Employee",
			_id: values.employee_id,
			personal_id: values.personal_number,
			validity: {
				_ref: false,
				from: values.arrival_date,
				to: values.departure_date
			},
			category: {
				_type: "Entity.CL.EmployeeCategory",
				_ref: false,
				_id: values.employee_category
			},
			identifiers: [
				{
					_type: "SIEA.Data.Object.Identifier",
					type: "PRIMARY",
					system: "SIEA",
					_ref: false,
					active: true,
					value: values.card_number
				}
			]
		}

		GlobalStore.loading(true)

		api
			.saveEmployeeCard(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res._id)) {
					setDisabled(true)
					GlobalStore.setNotificationMessage("Karta zamestnanca úspešne uložená", "success")
					RmeeCompanyEmployeesStore.loadEmployee(values._id, values.company_id)
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní karty zamestnanca došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeEmployeeCardStore()
export default singleton
