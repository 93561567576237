"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import RmeeOrgunitEmployeesTable from "./rmeeOrgunitEmployeesTable"

import RmeeOrgunitEmployeesStore from "./rmeeOrgunitEmployeesStore"

function RmeeOrgunitEmployeesContainer(props) {
	useEffect(() => {
		RmeeOrgunitEmployeesStore.loadEmployees(props.orgunit_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2} pt={2}>
				<Grid container>
					<Grid item xs={12}>
						<RmeeOrgunitEmployeesTable />
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(RmeeOrgunitEmployeesContainer))
