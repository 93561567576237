import {observable, action} from "mobx"

import api from "../../../../../global/actions/api"

import GlobalStore from "../../../../../global/store/GlobalStore"

class RmeeOrgunitAddressesStore {
	@observable orgunitAddressesData = []

	@observable isOpenOrgunitAddressDialog = false

	orgunitAddressFormRef = null

	@action loadAddresses(orgunit_id) {
		api
			.loadOrgunitAddresses(orgunit_id)
			.call()
			.then((res) => {
				if (isSafe(res) && res.length) {
					this.orgunitAddressesData = res
				} else {
					this.orgunitAddressesData = []
				}
			})
	}

	@action openOrgunitAddressDialog(orgunit_id, row) {
		if (isSafe(row) && isSafe(this.orgunitAddressFormRef)) {
			this.orgunitAddressFormRef.$("_id").value = row._id
			this.orgunitAddressFormRef.$("type").value = isSafe(row.type) ? row.type._id : null
			this.orgunitAddressFormRef.$("county").value = isSafe(row.county) ? row.county._id : null
			this.orgunitAddressFormRef.$("street").value = isSafe(row.street) ? row.street : ""
			this.orgunitAddressFormRef.$("region").value = isSafe(row.region) ? row.region._id : null
			this.orgunitAddressFormRef.$("house_number").value = isSafe(row.house_number) ? row.house_number : ""
			this.orgunitAddressFormRef.$("description").value = isSafe(row.description) ? row.description : ""

			if (isSafe(row.obj_city)) {
				this.orgunitAddressFormRef.$("city").value = row.obj_city._id
			} else if (isNotEmpty(row.city_plain)) {
				this.orgunitAddressFormRef.$("city_text").value = row.city_plain
			}

			if (isSafe(row.obj_zip)) {
				this.orgunitAddressFormRef.$("zip").value = row.obj_zip._id
			} else if (isNotEmpty(row.zip_plain)) {
				this.orgunitAddressFormRef.$("zip_text").value = row.zip_plain
			}
		}

		this.orgunitAddressFormRef.$("orgunit_id").value = orgunit_id

		this.isOpenOrgunitAddressDialog = true
	}

	@action closeOrgunitAddressDialog() {
		this.isOpenOrgunitAddressDialog = false

		this.orgunitAddressFormRef.reset()
	}

	@action saveOrgunitAddress(form) {
		const values = form.values()

		const req = {
			_type: "Entity.Data.Address",
			_id: isNotEmpty(values._id) ? values._id : null,
			region: isNotEmpty(values.region)
				? {
						_id: values.region
				  }
				: null,
			county: isNotEmpty(values.county)
				? {
						_id: values.county
				  }
				: null,
			city_plain: isNotEmpty(values.city_text) ? values.city_text : null,
			obj_city: isNotEmpty(values.city)
				? {
						_id: values.city
				  }
				: null,
			street_plain: isNotEmpty(values.street) ? values.street : null,
			house_number: isNotEmpty(values.house_number) ? values.house_number : null,
			obj_zip: isNotEmpty(values.zip)
				? {
						_id: values.zip
				  }
				: null,
			zip_plain: isNotEmpty(values.zip_text) ? values.zip_text : null,
			specific_rel: {
				_type: "Entity.Data.OrgUnit",
				_id: values.orgunit_id
			},
			type: {
				_id: isNotEmpty(values.type) ? values.type : null
			},
			description: isNotEmpty(values.description) ? values.description : null
		}

		GlobalStore.loading(true)

		api
			.saveOrgunitAddress(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Adresa úspešne uložená", "success")
					this.loadAddresses(values.orgunit_id)
					this.closeOrgunitAddressDialog()
				} else {
					GlobalStore.setNotificationMessage("Pri ukladaní adresy došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}

	@action removeOrgunitAddress(address_id, orgunit_id) {
		const req = {active: false, _id: address_id, _ref: false, _type: "Entity.Data.Address"}

		GlobalStore.loading(true)

		api
			.saveOrgunitAddress(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Adresa úspešne odstránený", "success")
					this.loadAddresses(orgunit_id)
				} else {
					GlobalStore.setNotificationMessage("Pri odstráňovaní adresy došlo ku chybe", "error")
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RmeeOrgunitAddressesStore()
export default singleton
