import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				ico: {
					label: <FormattedMessage id="Common.label.ico" />,
					rules: "required|string"
				},
				name: {
					label: <FormattedMessage id="Common.label.companyName" />,
					rules: "string"
				},
				description: {
					label: <FormattedMessage id="Common.label.description" />,
					rules: "string"
				},
				short_name: {
					label: <FormattedMessage id="Common.label.shortName" />,
					rules: "string"
				},
				dic: {
					label: <FormattedMessage id="Common.label.dic" />,
					rules: "string"
				},
				icdph: {
					label: <FormattedMessage id="Common.label.icdph" />,
					rules: "string"
				},
				title: {}
			}
		}
	}
}
