// import {FormattedMessage} from "react-intl"
// import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				energeticObjectId: {},
				type_of_cert: {
					label: "Typ posudku / certifikátu",
					rules: "required"
				},
				number_of_cert: {
					label: "Číslo / kód / ID posudku",
					rules: "required"
				},
				date_of_issue: {
					label: "Dátum vystavenia",
					rules: "required",
					type: "date"
				},
				name: {
					label: "Názov posudku / certifikátu"
				},
				floor_area: {
					label: "Celková podlahová plocha [m²]"
				},
				note: {
					label: "Poznámka"
				}
			}
		}
	}
}
