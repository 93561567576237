"use strict"

import React from "react"
import {observer} from "mobx-react"
import moment from "moment"

import {injectIntl, FormattedMessage} from "react-intl"
import {Container, Grid, Box, Typography} from "@material-ui/core"
import XsServerTable from "../../../global/ui/xsTable/xsServerTable"

import api from "../../../global/actions/api"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.project" />,
		width: 100,
		accessor: "projekt_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.projekt_kod) ? `${row.projekt_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.projekt_nazov) ? row.projekt_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.projekt_stav) ? row.projekt_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Číslo zmluvy:</span>{" "}
						{isNotEmpty(row.projekt_cisloZmluvy) ? row.projekt_cisloZmluvy : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Výdavkov",
		width: 100,
		accessor: "schvDekVyd_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.schvDekVyd_nazov) ? row.schvDekVyd_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.kindOf" />:
						</span>{" "}
						{isNotEmpty(row.schvDekVyd_druhVydavku) ? row.schvDekVyd_druhVydavku : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.schvDekVyd_typVydavku) ? row.schvDekVyd_typVydavku : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Žiadaná suma:</span>{" "}
						{isNotEmpty(row.schvDekVyd_sumaZiadanaNaPreplatenie) ? `${row.schvDekVyd_sumaZiadanaNaPreplatenie} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schválená suma:</span>{" "}
						{isNotEmpty(row.schvDekVyd_sumaSchvalenaNaPreplatenie)
							? `${row.schvDekVyd_sumaSchvalenaNaPreplatenie} €`
							: "-"}
					</Grid>
					<Grid item>
						<span className="bold">Suma bez DPH:</span>{" "}
						{isNotEmpty(row.schvDekVyd_vyskaBezDph) ? `${row.schvDekVyd_vyskaBezDph} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">DPH:</span> {isNotEmpty(row.schvDekVyd_dph) ? `${row.schvDekVyd_dph} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dát. úhrady:</span>{" "}
						{isNotEmpty(row.schvDekVyd_datumUhrady) ? moment(row.schvDekVyd_datumUhrady).format("DD.MM.YYYY") : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Skupina výdavkov",
		width: 100,
		accessor: "skupinaVydavkov_kod",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.skupinaVydavkov_kod) ? row.skupinaVydavkov_kod : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.skupinaVydavkov_nazov) ? row.skupinaVydavkov_nazov : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Kód zdroja:</span>{" "}
						{isNotEmpty(row.skupinaVydavkov_kodZdroj) ? row.skupinaVydavkov_kodZdroj : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Verejné obstarávanie",
		width: 100,
		accessor: "vo_kod",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vo_kod) ? row.vo_kod : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Predpokl. hodnota:</span>{" "}
						{isNotEmpty(row.vo_predpokladanaHodnotaZakazky) ? `${row.vo_predpokladanaHodnotaZakazky} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.vo_stav) ? row.vo_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dát. vyt.:</span>{" "}
						{isNotEmpty(row.vo_createdAt) ? moment(row.vo_createdAt).format("DD.MM.YYYY") : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "ZOP",
		width: 100,
		accessor: "zop_kod",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.zop_kod) ? row.zop_kod : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Nárokovaná suma:</span>{" "}
						{isNotEmpty(row.zop_narokovanaSuma) ? `${row.zop_narokovanaSuma} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Schválená suma:</span>{" "}
						{isNotEmpty(row.zop_schvalenaSuma) ? `${row.zop_schvalenaSuma} €` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dátum úhrady:</span>{" "}
						{isNotEmpty(row.zop_datumUhrady) ? moment(row.zop_datumUhrady).format("DD.MM.YYYY") : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.zop_typ) ? row.zop_typ : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Účt. doklad",
		width: 100,
		accessor: "uctDoklad_cisloDokladu",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">Číslo:</span>{" "}
						{isNotEmpty(row.uctDoklad_cisloDokladu) ? `${row.uctDoklad_cisloDokladu}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.type" />:
						</span>{" "}
						{isNotEmpty(row.uctDoklad_typ) ? row.uctDoklad_typ : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Celková výška:</span>{" "}
						{isNotEmpty(row.uctDoklad_celkovaVyskaDokladu) ? `${row.uctDoklad_celkovaVyskaDokladu} €` : "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhl. výzva",
		width: 100,
		accessor: "vyhlVyzva_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_kod) ? `${row.vyhlVyzva_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_nazov) ? htmlToPlain(row.vyhlVyzva_nazov) : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.state" />:
						</span>{" "}
						{isNotEmpty(row.vyhlVyzva_stav) ? row.vyhlVyzva_stav : "-"}
					</Grid>
					<Grid item>
						<span className="bold">Dátum vyhl.:</span>{" "}
						{isNotEmpty(row.vyhlVyzva_datumVyhlasenia)
							? moment(row.vyhlVyzva_datumVyhlasenia).format("DD.MM.YYYY")
							: "-"}
					</Grid>
				</Grid>
			)
		}
	},
	{
		Header: "Vyhlasovateľ",
		width: 100,
		accessor: "vyhlasovatel_nazov",
		sortType: "basic",
		Cell: (props) => {
			const row = props.row.original

			return (
				<Grid container direction="column">
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.code" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_kod) ? `${row.vyhlasovatel_kod}` : "-"}
					</Grid>
					<Grid item>
						<span className="bold">
							<FormattedMessage id="Common.label.name" />:
						</span>{" "}
						{isNotEmpty(row.vyhlasovatel_nazov) ? row.vyhlasovatel_nazov : "-"}
					</Grid>
				</Grid>
			)
		}
	}
]

function EtlExpendituresContainer() {
	return (
		<Container maxWidth={false} className="max_height overflow_y">
			<Grid container>
				<Grid item xs={12}>
					<Box pt={2} pb={2}>
						<Typography variant="h5">Výdavok ŽOP</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<XsServerTable
						columns={columns}
						api={api.loadExpenditures}
						// onRowClick={(row) => RmeeStore.openCompanyDetail(row)}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(EtlExpendituresContainer))
