"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Box, Container, Grid} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"

import RmeeOrgunitContactsTable from "./rmeeOrgunitContactsTable"
import RmeeOrgunitContactDialog from "./rmeeOrgunitContactDialog"

import RmeeOrgunitContactsStore from "./rmeeOrgunitContactsStore"

function RmeeOrgunitContactsContainer(props) {
	useEffect(() => {
		RmeeOrgunitContactsStore.loadContacts(props.orgunit_id)
	}, [])

	return (
		<Container maxWidth={false}>
			<Box pb={2}>
				<Grid container>
					<Grid item container xs={12} justify="flex-end">
						<Grid>
							<XsButton
								text="Pridať kontakt"
								onClick={() => RmeeOrgunitContactsStore.openOrgunitContactDialog(props.orgunit_id)}
								icon={<i className="fal fa-plus"></i>}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<RmeeOrgunitContactsTable orgunit_id={props.orgunit_id} />
					</Grid>
				</Grid>
			</Box>
			<RmeeOrgunitContactDialog />
		</Container>
	)
}

export default injectIntl(observer(RmeeOrgunitContactsContainer))
