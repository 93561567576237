import {FormattedMessage} from "react-intl"
import React from "react"

export default {
	load() {
		return {
			fields: {
				_id: {},
				orgunit_id: {},
				param_type: {
					label: <FormattedMessage id="Common.label.typeOfParam" />,
					rules: "string|required"
				},
				value: {
					label: <FormattedMessage id="Common.label.value" />,
					rules: "string|required"
				}
			}
		}
	}
}
