"use strict"

import React, {useState, useEffect} from "react"
import classnames from "classnames"
import {inject, observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import Dropzone from "react-dropzone"

import {Box, Grid, Paper, Typography, Checkbox, Tab, Tabs} from "@material-ui/core"

import XsInput from "../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../../../global/ui/xsDialog/xsConfirmationDialog"
import XsBreadcrumbs from "../../../../global/ui/xsBreadcrumbs/xsBreadcrumbs"

import ListOfRecords from "./listOfRecords"
import RecordDialog from "./recordDialog"

import RouterStore from "../../../../global/store/RouterStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import OesReportRecordStore from "./oesReportRecordStore"

function ReportContainer(props) {
	const report_id = new URLSearchParams(props.routing.location.search).get("id")
	const year = new URLSearchParams(props.routing.location.search).get("year")

	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			OesReportRecordStore.saveEOSReportNote(form)
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{fields: {note: {label: "Poznámka"}, year: {label: "Rok", value: year}, note_id: {}}},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	// const [delRegion, setDelRegion] = useState("")

	useEffect(() => {
		OesReportRecordStore.getAttachments(report_id)
		OesReportRecordStore.getRecords(report_id)
		OesReportRecordStore.getReportById(report_id, form)

		return () => {
			OesReportRecordStore.dropedFiles = []
		}
	}, [])

	const handleDrop = (files, length) => {
		//length je max. pocet filov, ktore mozu byt vlozene...
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", "error")
				continue
			}
			if (OesReportRecordStore.dropedFiles.length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", "error")
				continue
			}

			OesReportRecordStore.saveAttachmentOnDrop(file, report_id)
			// OesReportRecordStore.dropedFiles.push(file)
		}
	}

	const handleDropzonePreview = (attachmentIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			OesReportRecordStore.dropedFiles[attachmentIndex],
			OesReportRecordStore.dropedFiles[attachmentIndex].name
		)
		GlobalStore.setNotificationMessage("Súbor bol úspešne stiahnutý", "success")
	}

	const handleDropzoneDelete = (attachmentIndex) => {
		if (isSafe(OesReportRecordStore.dropedFiles[attachmentIndex]._id)) {
			OesReportRecordStore.deleteLoadedAttachemnt(
				OesReportRecordStore.dropedFiles[attachmentIndex]._id,
				attachmentIndex
			)
		} else {
			OesReportRecordStore.dropedFiles.splice(attachmentIndex, 1)
		}
	}

	const handleChange = (event, value) => {
		OesReportRecordStore.regionTabValue = value
	}

	const isDisabled = OesReportRecordStore.isDisabled

	return (
		<Box pr={4} pl={4} pt={3} pb={3} className="oes_report_container">
			<Box pb={2}>
				<XsBreadcrumbs
					links={[
						{
							label: "ISEE",
							action: () => {
								RouterStore.push(`uvod`)
							}
						},
						{
							label: "OES",
							action: () => {
								RouterStore.push(`oes`)
							}
						},
						{
							label: "Reporty",
							action: () => {
								RouterStore.push(`oes`)
							}
						}
						// {label: "Report za rok 2020", action: () => RouterStore.push(`company?id=${props.company_id}`)}
					]}
					text={`Report za rok ${year}`}
				/>
			</Box>
			<Grid container>
				<Grid item className="title">
					<Box pb={2}>
						Výkaz o <span className="bold">celkovom predanom množstve energie</span> podľa jednotlivých energonosičov
						(palív)
					</Box>
				</Grid>
			</Grid>
			<Paper className="cvs_paper">
				<Box p={3}>
					<Grid container spacing={2} alignItems="center">
						<Grid item className="title">
							Report za rok {year}
						</Grid>
					</Grid>
					<Grid container spacing={2} alignItems="center" className="pt-3">
						<Grid item xs={2}>
							<XsInput field={form.$("year")} disabled />
						</Grid>
						<Grid item xs={4}>
							<XsInput field={form.$("note")} onBlur={() => form.submit()} />
						</Grid>
						<Grid item container xs={6} justify="flex-end">
							<Grid item>
								<XsButton
									// variant="text"
									icon={<i className="fal fa-times fa-lg"></i>}
									text="Exportovať údaje"
									onClick={() => OesReportRecordStore.exportCvsRecords(report_id)}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Box pt={3}>Prílohy</Box>
						</Grid>
						<Grid item xs={12}>
							<Dropzone
								onDrop={(files) => {
									handleDrop(files, 5)
								}}
								id="dropzone"
								// disabled={isFormDisabled()}
								accept={".doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png"}
							>
								{isSafe(OesReportRecordStore.dropedFiles) && OesReportRecordStore.dropedFiles.length ? (
									OesReportRecordStore.dropedFiles.map((file, index) => (
										<div key={index} className={"dropedFile"}>
											<i
												className="fal fa-file icon"
												onClick={(e) => {
													e.stopPropagation()
													handleDropzonePreview(index)
												}}
											/>
											<i
												className="far fa-trash-alt deleteIcon"
												onClick={(e) => {
													e.stopPropagation()
													// if (!isFormDisabled()) {
													GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
														fileIndex: index
													})
													// }
												}}
											/>
											<br />
											<div
												className={"mac_ellipsis"}
												data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
												data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
											/>
										</div>
									))
								) : (
									<div
										className={"dropzonePlaceholder"}
									>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z vášho súborového systému`}</div>
								)}
							</Dropzone>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								Povolené sú iba tieto typy súborov: doc, docx, xls, xlsx, pdf, jpeg, jpg, png
							</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Box pt={3}>Kraj</Box>
						</Grid>
						{isSafe(GlobalStore.CL["addressRegion"]) &&
							GlobalStore.CL["addressRegion"].map((region) => {
								if (region._id != "SKZZZ") {
									return (
										<Grid item container xs={3} key={region._id} alignItems="center">
											<Grid item xs={2}>
												<Checkbox
													disabled={isDisabled}
													value={region._id}
													checked={OesReportRecordStore.checkedRegion.includes(region._id)}
													onChange={(val) => {
														if (val.target.checked) {
															GlobalStore.openConfirmationDialog("addNewRegionOES", {
																callback: () => {
																	OesReportRecordStore.openRecordDialog(report_id, year)
																	OesReportRecordStore.formRef.$("region_id").value = region._id
																},
																region_name: region.name_ext
															})
														} else {
															GlobalStore.openConfirmationDialog("xsDeleteRegionRecords", {
																region_id: region._id,
																region_name: region.name_ext
															})
														}
													}}
												/>
											</Grid>
											<Grid item xs={10}>
												{region.name_ext}
											</Grid>
										</Grid>
									)
								}
							})}
					</Grid>
					<Grid container className="pt-4">
						<Grid item xs={12} style={{border: "2px solid #f0f0f0", borderRadius: "5px"}}>
							<Tabs
								value={OesReportRecordStore.regionTabValue}
								onChange={handleChange}
								// variant="fullWidth"
								indicatorColor="primary"
								textColor="primary"
								aria-label="icon tabs example"
							>
								{Object.keys(OesReportRecordStore.recordsData).map((tab) => {
									return (
										<Tab
											className={classnames({"xs-active": OesReportRecordStore.regionTabValue == tab})}
											value={tab}
											key={tab}
											label={
												<Grid container spacing={1} justify="center">
													{/* <Grid item>
														<i className="fal fa-user-friends fa-lg"></i>
													</Grid> */}
													<Grid item>{GlobalStore.region[tab]}</Grid>
												</Grid>
											}
										/>
									)
								})}
							</Tabs>

							<Grid item container justify="flex-end" className="pa-4">
								<Grid item>
									<XsButton
										disabled={isDisabled}
										icon={<i className="fal fa-plus fa-lg"></i>}
										text="Pridať"
										onClick={() => {
											OesReportRecordStore.formRef.$("region_id").value = OesReportRecordStore.regionTabValue
											OesReportRecordStore.openRecordDialog(report_id, year)
										}}
									/>
								</Grid>
								{isSafe(OesReportRecordStore.recordsData[OesReportRecordStore.regionTabValue]) && (
									<Grid container>
										<Grid item xs={12}>
											<ListOfRecords
												data={OesReportRecordStore.recordsData[OesReportRecordStore.regionTabValue]}
												report_id={report_id}
												year={year}
												isDisabled={isDisabled}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid container justify="flex-end" className="mt-2">
						<Grid item>
							<XsButton
								disabled={isDisabled}
								icon={<i className="fal fa-share fa-lg"></i>}
								text={<FormattedMessage id="Common.label.checkAndSentReport" />}
								onClick={() => {
									GlobalStore.openConfirmationDialog("sendOESReport")
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			<XsConfirmationDialog
				type="danger"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text="Naozaj chcete odstrániť prílohu?"
				onConfirmation={() => handleDropzoneDelete(GlobalStore.confParams.fileIndex)}
			/>
			{GlobalStore.confirmationDialogOpened == "xsDeleteRegionRecords" && (
				<XsConfirmationDialog
					type="danger"
					name="xsDeleteRegionRecords"
					titleIcon={<i className="fas fa-exclamation-triangle"></i>}
					title="Skutočne chcete odstrániť záznam?"
					text={`Chystáte sa odstrániť údaje za ${GlobalStore.confParams.region_name}. Skutočne to chcete urobiť?`}
					confirmBtn={{
						icon: <i className="fal fa-trash-alt fa-lg" />,
						text: "Common.btn.delete"
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.back"
					}}
					onConfirmation={() => OesReportRecordStore.removeRegionRecords(report_id, GlobalStore.confParams.region_id)}
				/>
			)}
			<XsConfirmationDialog
				type="info"
				name="sendOESReport"
				title="Upraviť alebo odoslať report"
				text={`Odosielate report za subjekt:<br/>
				<b>Chcete si údaje ešte raz skontrolovať a prípadne upraviť?</b>`}
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				confirmBtn={{
					icon: <i className="fal fa-check fa-lg" />,
					text: "Common.btn.send"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times fa-lg" />,
					text: "Common.btn.check"
				}}
				onConfirmation={() => {
					OesReportRecordStore.sendReport(report_id)
				}}
			/>
			{GlobalStore.confirmationDialogOpened == "addNewRegionOES" && (
				<XsConfirmationDialog
					type="success"
					name="addNewRegionOES"
					titleIcon={<i className="fas fa-exclamation-triangle"></i>}
					title="Pridať kraj do reportu"
					text={`Chystáte sa pridať ${GlobalStore.confParams.region_name} do reportu za rok ${year}. Skutočne to chcete urobiť?`}
					confirmBtn={{
						icon: <i className="fal fa-check fa-lg" />,
						text: "Common.btn.add"
					}}
					cancelBtn={{
						icon: <i className="fal fa-times fa-lg" />,
						text: "Common.btn.back"
					}}
					onConfirmation={() => {
						if (isSafe(GlobalStore.confParams.callback)) {
							GlobalStore.confParams.callback()
						}
					}}
				/>
			)}

			<XsConfirmationDialog
				type="success"
				name="saveOesReportSuccessful"
				titleIcon={<i className="fas fa-exclamation-triangle"></i>}
				title="Ďakujeme za zaslanie reportu"
				text={`K Vami zaslanému reportu sme Vám vygenerovali potvrdenie, ktoré nájdete v schránke správ.`}
				hideCancelButton={true}
				confirmBtn={{
					icon: <i className="fal fa-check fa-lg" />,
					text: "Zavrieť"
				}}
			/>
			<RecordDialog isDisabled={isDisabled} />
		</Box>
	)
}

export default inject("routing")(injectIntl(observer(ReportContainer)))
